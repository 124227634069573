import CustomStore from "devextreme/data/custom_store";
import qs from "qs";
import api from "../../services/api";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";

function handleInsert(values) {
  const { auth, company } = handleGetSessionStorage();
  const record = {
    id: 0,
    ...values,
    dataInclusao: new Date(),
    empresa: company,
    usuario: auth.nomeUsuario,
    excluido: "F",
  };

  return api
    .post("ProcedimentoMedico", record)
    .then((res) => res)
    .catch((err) => handleError("Erro ao incluir", err));
}

function handleLoad(loadOptions) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    descricao: "",
    dataInclusao: "",
  };
  const newParams = handleParams(params, loadOptions);
  const url = `ProcedimentoMedico?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

function handleRemove({ idAcProcedimentoMedico }) {
  return api
    .delete(
      `ProcedimentoMedico?idAcProcedimentoMedico=${idAcProcedimentoMedico}`
    )
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

function handleUpdate(key, values) {
  const { auth, company } = handleGetSessionStorage();
  const record = {
    ...key,
    ...values,
    empresa: company,
    usuario: auth.nomeUsuario,
    excluido: "F",
  };

  return api
    .put("ProcedimentoMedico", record)
    .then((res) => res)
    .catch((err) => handleError("Erro ao editar", err));
}

export const gridDataSource = {
  store: new CustomStore({
    key: ["idAcProcedimentoMedico", "dataInclusao"],
    load: handleLoad,
    insert: handleInsert,
    update: handleUpdate,
    remove: handleRemove,
  }),
};
