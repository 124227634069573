import { Form, TextField } from "@orgystem-ui/components.form";
import { confirm } from "devextreme/ui/dialog"
import { useState } from "react";
import { Button, FlexboxGrid, Schema } from "rsuite";
import { cancelAgendamentoMedico } from "../ConfirmarAgendamentoService";

export function CancelarAgendamentoForm({
  form,
  setForm,
  successMessage,
  errorMessage,
  onClose
}) {
  const [formCancelamento, setFormCancelamento] = useState({ observacao: '' })
  const [errors, setErrors] = useState({})
  const [loadingCancelamento, setLoadingCancelamento] = useState(false)

  function handleSubmitCancel() {
    let result = confirm("Deseja realmente cancelar agendamento?", "Agendamento Médico");
    result.then((dialogResult) => {
      if (dialogResult) {
        setLoadingCancelamento(true)
        cancelAgendamentoMedico(form, formCancelamento.observacao)
          .then(res => {
            setForm(res)
            onClose()
            successMessage('Cancelamento realizado com sucesso!')
          })
          .catch(err => errorMessage(err))
          .finally(() => setLoadingCancelamento(false))
      }
    });
  }

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    observacao: StringType().isRequired("Motivo do cancelamento obrigatório"),
  })

  return (
    <Form
      formValue={formCancelamento}
      formError={errors}
      model={model}
      onCheck={setErrors}
      onSubmit={isValid => {
        if (isValid) {
          handleSubmitCancel()
        }
      }}
    >
      <FlexboxGrid style={{ marginBottom: '1rem' }}>
        <FlexboxGrid.Item style={{ marginLeft: '0.5rem', marginBottom: '1rem' }}>
          <div>
            <p>Usuário: <strong>{form.nome}</strong></p>
            <p>Cartão: <strong>{form.numeroCartao}</strong></p>
            <p>Rede Credenciada: <strong>{form.nomeFantasia}</strong></p>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <TextField
            textarea
            name="observacao"
            label="Motivo do cancelamento:"
            width="100%"
            minHeight={100}
            margin={0}
            value={formCancelamento.observacao}
            error={errors.observacao}
            onChange={e => setFormCancelamento({ observacao: e })}
            style={{ overflow: 'hidden', width: '100%', textOverflow: 'clip', wordWrap: 'break-word', minHeight: 100 }}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item>
          <Button
            appearance="ghost"
            color="orange"
            className="pageButtonSecondary"
            onClick={onClose}
          >
            Fechar
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item>
          <Button
            appearance="primary"
            color="blue"
            className="pageButtonPrimary"
            loading={loadingCancelamento}
            type="submit"
          >
            Cancelar Agendamento
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  )
}