import { ConfirmarAgendamento } from "../pages/confirmarAgendamento/ConfirmarAgendamento";
import Home from "../pages/home/Home";
import { ImportacaoDeArquivo } from "../pages/importacaoDeArquivo/ImportacaoDeArquivo";
import NotFound from "../pages/notFound/NotFound";
import { ProcedimentoMedico } from "../pages/procedimentosMedicos/ProcedimentoMedico";
import { RedeCredenciada } from "../pages/redeCredenciada/RedeCredenciada";
import { Atendimento } from "../pages/atendimento/Atendimento"
import { TerminalWebAdministrativo } from "../pages/terminalWebAdministrativo/TerminalWebAdministrativo";
const Pages = [
  {
    name: "home",
    route: "/",
    component: Home,
  },
  {
    name: "importacao-arquivo",
    route: "/importacao-arquivo",
    component: ImportacaoDeArquivo,
  },
  {
    name: "procedimento-medico",
    route: "/procedimento-medico",
    component: ProcedimentoMedico,
  },
  {
    name: "rede-credenciada",
    route: "/rede-credenciada",
    component: RedeCredenciada,
  },
  {
    name: "atendimento",
    route: "/atendimento",
    component: Atendimento,
  },
  {
    name: "terminal-web-administrativo",
    route: "/terminal-web-administrativo",
    component: TerminalWebAdministrativo,
  },
  {
    name: "agendamento-medico",
    route: "/agendamento-medico",
    component: ConfirmarAgendamento,
  },
  {
    name: "pageNotFound",
    route: "*",
    component: NotFound,
  },
];

export default Pages;
