const initialState = {};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case 'CASE_REDUCER':
      return initialState;
    default:
      return state;
  }
};
