import { FilterModal } from "@orgystem-ui/components.filter-modal";
import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting
} from "devextreme-react/data-grid";
import React, { useRef, useState } from "react";
import { agendamentoMedicoStore } from "./ConfirmarAgendamentoService";

export function ConfirmarAgendamentoFilter({ setForm, setDataSource, setActive }) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [toggle, setToggle] = useState(false);

  const gridRef = useRef(null);
  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately"
    },
    {
      key: "onClick",
      name: "On Button Click"
    }
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  /*  const refreshGrid = () => {
     return gridRef?.current?._instance?.beginCustomLoading();
   }; */

  return (
    <FilterModal
      toggle={toggle}
      setToggle={setToggle}
      full
      style={{ paddingLeft: "30px" }}
    >
      <>
        <DataGrid
          dataSource={agendamentoMedicoStore}
          remoteOperations={true}
          showBorders
          allowColumnReordering
          allowColumnResizing
          columnResizingMode="widget"
          columnAutoWidth
          onOptionChanged={handleOptionChange}
          rowAlternationEnabled
          showRowLines
          ref={gridRef}
        >
          <Editing mode="row" useIcons allowDeleting allowUpdating />
          <Sorting mode="single" />
          <Pager
            allowedPageSizes={[7, 15, 30]}
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
          />
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
          <FilterRow
            visible={filterOptions.showFilterRow}
            applyFilter={filterOptions.currentFilter}
          />
          <HeaderFilter />
          <Column
            dataField="nome"
            caption="Usuário"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="numeroCartao"
            caption="Nr. Cartão"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="nomeFantasia"
            caption="Rede Credenciada"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="dataInclusao"
            caption="Data Inclusão"
            dataType="date"
            format="dd/MM/yyyy"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="dataRecebimento"
            caption="Data Recebimento"
            dataType="date"
            format="dd/MM/yyyy"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="valorRecebimento"
            caption="Valor Recebimento"
            format={{
              type: "currency",
              currency: "BRL",
              minimumSignificantDigits: 2,
              precision: 2,
            }}
            editorOptions={{
              format: {
                type: "currency",
                currency: "BRL",
                precision: 2,
                minimumSignificantDigits: 2,
                text: "R$ #,##0.00",
              },
              showClearButton: true,
            }}
            filterOperations={false}
            allowEditing={false}
            alignment="right"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="acSituacaoConsultaMedicaDescricao"
            caption="Situação"
            filterOperations={true}
            allowEditing={false}
            alignment="center"
          />

          <Column type="buttons" width="auto">
            <Button
              hint="Editar"
              visible
              icon="edit"
              onClick={e => {
                const data = e.row?.data
                data.status = 0
                data.acAgendaMedicaHorario.forEach(item => {
                  item.check = item.agendado === 'T' ? true : false
                })
                setForm(data)
                setDataSource(data.acAgendaMedicaHorario)
                setToggle(!toggle);
                setActive('horario')
              }}
            />
          </Column>
        </DataGrid>
      </>
    </FilterModal>
  );
}
