import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import {
  dataSourceProcedimentosMedicos,
  gridDetailDataSourceProcedimentoMedicoFornecedor,
} from "../RedeCredenciadaService";

const DetalheProcedimentoMedicoFornecedorGrid = ({ data }) => {
  const { successMessage, form } = useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [allowAdding, setAllowAdding] = useState(true);
  const idAcRedeCredenciada = form?.idAcRedeCredenciada;
  let idAcAdminCartao = data.data.data.idAcAdminCartao;

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    for (let i = 0; i < 9; i++) {
      if (event.fullName === `columns[${i}].filterValue`) {
        setAllowAdding(true);
      }
    }

    if (event.fullName === "editing.changes") {
      if (event.value[0]?.data) {
        event.value[0].data.idAcRedeCredenciada = idAcRedeCredenciada;
      }
    }

    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleRemove({ data }) {
    successMessage("Endereço deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  function handleNewRow(event) {
    event.data.idAcRedeCredenciadaProMed = null;
  }

  function handleEditingStart(event) {
    setAllowAdding(false);
  }

  async function handleInsertError(event) {
    const { newData, oldData = false } = event;

    newData.idAcProcedimentoMedico =
      newData?.idAcProcedimentoMedico ?? oldData?.idAcProcedimentoMedico;
    newData.descricao = newData?.descricao ?? oldData?.descricao;
    newData.valorSemDesconto =
      newData?.valorSemDesconto ?? oldData?.valorSemDesconto;
    newData.valor = newData?.valor ?? oldData.valor;
    newData.dataInclusao = oldData.dataInclusao;
    newData.usuario = oldData.usuario ?? "";
  }

  function clearDadosGrid() {
    setAllowAdding(true);
  }

  return (
    <DataGrid
      dataSource={gridDetailDataSourceProcedimentoMedicoFornecedor(
        idAcRedeCredenciada,
        idAcAdminCartao
      )}
      remoteOperations={true}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      onInitNewRow={handleNewRow}
      onEditingStart={handleEditingStart}
      onRowInserted={handleInsert}
      onRowRemoved={handleRemove}
      onRowValidating={handleInsertError}
      onSaved={() => clearDadosGrid()}
      onEditCanceled={() => clearDadosGrid()}
      onRowUpdated={handleUpdate}
      onOptionChanged={handleOptionChange}
      // loadPanel={true}
      style={{ marginTop: "0.5rem", paddingLeft: "0.5rem" }}
    >
      <Pager
        allowedPageSizes={[7, 15, 30]}
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
      />
      <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
      <Editing
        /* mode="row" */ useIcons
        allowAdding
        allowDeleting
        allowUpdating
      />
      <FilterRow
        visible={filterOptions.showFilterRow}
        applyFilter={filterOptions.currentFilter}
      />
      <HeaderFilter />

      <Column
        dataField="idAcProcedimentoMedico"
        caption="Descrição"
        filterOperations={true}
        alignment="left"
        width={"50%"}
        calculateDisplayValue="descricao"
      >
        <Lookup
          dataSource={dataSourceProcedimentosMedicos}
          displayExpr="descricao"
          valueExpr="idAcProcedimentoMedico"
        />
        <RequiredRule message="Procedimento é obrigatório" />
      </Column>

      <Column
        alignment="center"
        dataField="valorSemDesconto"
        caption="Valor"
        allowEditing={true}
        dataType="number"
        format={{
          type: "currency",
          currency: "BRL",
          precision: 2,
        }}
        editorOptions={{
          format: "R$ #,##0.##",
          showClearButton: true,
        }}
        filterOperations={false}
        width="25%"
      >
        <RequiredRule />
      </Column>

      <Column
        alignment="center"
        dataField="valor"
        caption="Valor Associado"
        allowEditing={true}
        dataType="number"
        format={{
          type: "currency",
          currency: "BRL",
          precision: 2,
        }}
        editorOptions={{
          format: "R$ #,##0.##",
          showClearButton: true,
        }}
        filterOperations={false}
        width="25%"
      >
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};

export default DetalheProcedimentoMedicoFornecedorGrid;
