/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  SelectInput,
  TextFieldCurrency,
} from "@orgystem-ui/components.form";
import React, { useCallback, useEffect, useState } from "react";
import { Button, FlexboxGrid, Schema } from "rsuite";
import {
  Encrypt,
  formatDateHours,
  handleErrorForm,
  handleGetSessionStorage,
} from "../../../services/helpers";
import { useTerminalContext } from "../context/TerminalAdministrativoContext";
import { handlePostAndPut } from "../TerminalAdministrativoService";
import { ModalAtendimentoComValor } from "./ModalAtendimentoComValor";
import { ModalAtendimentoSemValor } from "./ModalAtendimentoSemValor";

const TerminalForm = () => {
  const {
    form,
    setForm,
    errors,
    setErrors,
    handleChange,
    setVisible,
    clearFilter,
    warningMessage,
    numeroParcelas,
    setNumeroParcelas,
    setTransaction,
    transaction,
    setLoadingButton,
    loadingButton,
    setData,
    errorMessage,
    disabled,
    aceitaValores,
  } = useTerminalContext();

  const [listParcelas, setListParcelas] = useState([]);
  const [showModalAtendimentoComValor, setShowModalAtendimentoComValor] =
    useState(false);
  const [showModalAtendimentoSemValor, setShowModalAtendimentoSemValor] =
    useState(false);

  const model = Schema.Model({});

  function handleInstallments(valorTotal) {
    let list = [];
    const selectedProc = form?.procedimentoMedico.filter((item) => item.check);
    const temConsulta = selectedProc.some((item) => item?.consulta === "S");
    if (valorTotal > 0) {
      if (!temConsulta) {
        for (let i = 0; i < form.maximoParcelas; i++) {
          let count = i + 1;
          list.push({
            unitaryValue: (valorTotal / count).toFixed(2),
            name:
              count +
              (count === 1 ? " Parcela" : " Parcelas") +
              " - R$ " +
              (valorTotal / count).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL",
              }),
            value: count,
          });
          if (numeroParcelas === count) {
            setNumeroParcelas(count);
          }
        }
      } else {
        list.push({
          unitaryValue: valorTotal.toFixed(2),
          name:
            "1 Parcela - R$ " +
            valorTotal.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            }),
          value: 1,
        });
        setNumeroParcelas(1);
      }
    }

    setListParcelas([...list]);
  }
  function handleAccumulatedValue(outrosValores = 0) {
    let valorProcedimentos = 0;
    if (form?.procedimentoMedico?.length) {
      valorProcedimentos = form.procedimentoMedico
        .map((item) => {
          if (item.check) {
            return item.valor;
          }
          return 0;
        })
        .reduce((prev, next) => prev + next);
    }

    setForm({
      ...form,
      valorTotal: Number((valorProcedimentos + outrosValores).toFixed(2)),
    });
    handleInstallments(outrosValores + valorProcedimentos);
  }

  function handleSubmit() {
    if (!transaction) {
      const { user } = handleGetSessionStorage();

      let consultaMedica = form?.consultaMedica.filter((item) => item.check);
      if (!consultaMedica.length) {
        return warningMessage("Selecione um atendimento");
      }

      const procedimentoMedico = form?.procedimentoMedico.filter(
        (item) => item.check
      );
      if (!procedimentoMedico.length) {
        return warningMessage("Selecione um procedimento");
      }

      if (!numeroParcelas && form?.valorTotal > 0) {
        return warningMessage("Selecione a parcela");
      }

      setLoadingButton(true);

      const record = {
        ...form,
        numeroParcelas,
        consultaMedica: consultaMedica[0],
        procedimentoMedico,
        senha: Encrypt(form.senha),
        usuario: user,
        excluido: "F",
        idAcRedeCredenciadaRepr: form.idAcRedeCredenciada,
      };

      handlePostAndPut(record, "Terminal", "POST")
        .then((res) => {
          setForm({ ...form, ...res });
          setVisible(true);
          handleCloseModalAtendimentoComValor();
          handleCloseModalAtendimentoSemValor();
          setTransaction(true);
          let newDate = formatDateHours(new Date());

          setData({
            ...record,
            date: newDate,
            codAutorizacao: res?.codAutorizacao,
            numeroParcelas,
          });
        })
        .catch((err) => errorMessage(handleErrorForm(err)))
        .finally(() => setLoadingButton(false));
    } else {
      setVisible(true);
    }
  }

  const handleValueChange = useCallback((value, input) => {
    // eslint-disable-next-line
    setForm((prevState) => ({
      ...prevState,
      [input]: Number.parseFloat(value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleAccumulatedValue(form.outrosValores);
  }, [form.outrosValores, form.procedimentoMedico]);

  function handleCloseModalAtendimentoComValor() {
    setShowModalAtendimentoComValor(false);
  }

  function handleCloseModalAtendimentoSemValor() {
    setShowModalAtendimentoSemValor(false);
  }

  const temAtendimento = form.consultaMedica.some((item) => item.check);
  const temProcedimento = form.procedimentoMedico.some((item) => item.check);
  const disabledButton = !temAtendimento || !temProcedimento || loadingButton;

  return form?.idAcAdminCartao ? (
    <>
      <ModalAtendimentoComValor
        showModal={showModalAtendimentoComValor}
        handleCloseModal={handleCloseModalAtendimentoComValor}
        handleSubmit={handleSubmit}
      />
      <ModalAtendimentoSemValor
        showModal={showModalAtendimentoSemValor}
        handleCloseModal={handleCloseModalAtendimentoSemValor}
        handleSubmit={handleSubmit}
      />
      <Form
        formValue={form}
        model={model}
        formError={errors}
        onChange={handleChange}
        onCheck={setErrors}
        onSubmit={(isValid) => {
          if (isValid) {
            form?.valorTotal > 0
              ? setShowModalAtendimentoComValor(true)
              : transaction
              ? setVisible(true)
              : setShowModalAtendimentoSemValor(true);
          }
        }}
        fluid
      >
        <FlexboxGrid>
          {aceitaValores && (
            <FlexboxGrid.Item colspan={6}>
              <TextFieldCurrency
                label="Outros Valores"
                name="outrosValores"
                width={"100%"}
                newValue={form?.outrosValores}
                onValueChange={(value) => {
                  handleValueChange(value, "outrosValores");
                }}
              />
            </FlexboxGrid.Item>
          )}
          <FlexboxGrid.Item colspan={6}>
            <TextFieldCurrency
              label="Valor Total"
              name="valorTotal"
              width={"100%"}
              newValue={form?.valorTotal}
              onValueChange={(value) => {
                handleValueChange(value, "valorTotal");
              }}
              readOnly
              disabled
              style={{ color: "#000" }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6} className="flexGridInput">
            <SelectInput
              label="Parcelas"
              name="numeroParcelas"
              width={"100%"}
              options={listParcelas}
              valueKey="value"
              labelKey="name"
              value={numeroParcelas}
              form={form}
              setForm={setForm}
              onSelect={(e) => setNumeroParcelas(e)}
              disabled={disabled}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="end">
          <Button
            appearance="ghost"
            color="orange"
            className="pageButton"
            onClick={() => {
              clearFilter();
            }}
          >
            LIMPAR
          </Button>
          <Button
            appearance="primary"
            color="blue"
            className="pageButton"
            type="submit"
            disabled={disabledButton}
            loading={loadingButton}
            title={
              !temAtendimento
                ? "Selecione um atendimento"
                : !temProcedimento
                ? "Selecione um procedimento"
                : ""
            }
          >
            {transaction ? "VER COMPROVANTE" : "FINALIZAR ATENDIMENTO"}
          </Button>
        </FlexboxGrid>
      </Form>
    </>
  ) : null;
};

export { TerminalForm };
