// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case "TABS_HEADER":
      return {
        ...state,
        tabs: action.tabs,
      };

    default:
      return state;
  }
};
