import React from "react";
import ReactDOM from "react-dom";

import { CustomProvider } from "rsuite";
import { loadMessages, locale } from "devextreme/localization";

import ptMessages from "devextreme/localization/messages/pt.json";
import pt_BR from "rsuite/locales/pt_BR";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import "rsuite/dist/rsuite.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

loadMessages(ptMessages);
locale("pt");

ReactDOM.render(
  <CustomProvider locale={pt_BR}>
    <App />
  </CustomProvider>,
  document.getElementById("root")
);
