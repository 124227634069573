import { useRef } from "react";
import { createBrowserHistory } from "history";
import { Auth, Client, Company } from "../config/Storage";
import api from "./api";
import qs from "qs";
import { toggleModal } from "../store/modules/company/action";
import { randomBytes, createCipheriv } from "crypto";

let history = createBrowserHistory();

export function useDebounce(fn, delay) {
  const timeoutRef = useRef(null);

  function debounceFn(...args) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return debounceFn;
}

export function handleError(
  error,
  altMessage = "Ocorreu um erro durante o processo"
) {
  if (!error?.response) error = { response: { data: false, status: false } };
  const { data, status } = error?.response;
  let message = "";

  if (data) {
    const errorMessage = Object.keys(data.errors).map(
      (key) => data.errors[key]
    );
    // eslint-disable-next-line no-useless-escape
    message = errorMessage.join(",").replace(/\,/, ", ");
  }

  switch (status) {
    case 400:
      message = message ?? "Verifique os dados enviados";
      break;
    case 401:
      sessionStorage.clear();
      history.replace("/login");
      break;
    case 404:
      message = "Nenhum registro encontrado!";
      break;
    case 500:
      message = message ?? "Erro no servidor";
      break;
    default:
      message = "Erro desconhecido!";
      break;
  }

  if (message) return `${altMessage} - ${message}`;
}

export function handleErrorForm(method, error) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (!error?.response) error = { response: { data: false, status: false } };
  const { data, status } = error?.response;
  let message = "";

  if (data) {
    const errorMessage = Object.keys(data.errors).map(
      (key) => data.errors[key]
    );
    // eslint-disable-next-line no-useless-escape
    message =
      typeof errorMessage[0] === "object"
        ? errorMessage[0][0]
        : // eslint-disable-next-line no-useless-escape
        errorMessage.join(",").replace(/\,/, ", ");
  }

  switch (status) {
    case 400:
      message = message ?? "Verifique os dados enviados";
      break;
    case 401:
      sessionStorage.clear();
      history.replace("/login");
      break;
    case 404:
      message = "Nenhum registro encontrado!";
      break;
    case 500:
      message = message ?? "Erro no servidor";
      break;
    default:
      message = "Erro desconhecido!";
      break;
  }

  if (message) return `${method} (${status}) - ${message}`;
}

export function handleParams(params, options) {
  const { skip = 0, take = 1000, filter, sort } = options;

  if (sort && sort.length) {
    const selected = sort[0];
    params.orderBy = `${selected.selector || "id"} ${!selected.desc ? "ASC" : "DESC"
      }`;
  }
  if (filter) {
    let arrayNomes = [];
    const filterResult = filter.filter((item) => item !== "and");
    const checkFilterObject =
      filterResult.length === 3 && typeof filterResult[0] === "string";

    if (checkFilterObject) {
      let field = filterResult[0];
      let value = filterResult[2];
      params[field] = value;
    } else {
      filterResult.map((item) => {
        if (!arrayNomes.includes(item[0])) {
          if (typeof item[0] === "object") {
            let field = item[0][0];
            let value = item[0][2];

            params[field] = value;
          } else {
            let field = item[0];
            let value = item[2];

            params[field] = value;
          }
          arrayNomes.push(item[0]);
        }
        return item;
      });
    }
  }

  return { ...params, skip, take };
}

export function formatCurrentInFloat(value) {
  if (!value) return 0;
  let formatedValue = "";

  formatedValue = value.split(".").join("").replace(",", ".");

  return Number(formatedValue.replace("R$ ", ""));
}

export function formatDateISO(data) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const date = new Date(data);
  const dateFormated = date.toLocaleDateString("pt-BR", options);
  let dateIso = "";
  dateFormated.split(" ").map((item) => {
    if (/\//gi.test(item)) {
      dateIso = item.split("/").reverse().join("-") + "T";
    }
    if (item.indexOf(":") !== -1) dateIso = dateIso + item + "Z";
    return item;
  });
  return dateIso;
}

export function formatDateLocaleViewer(date) {
  return new Date(
    new Date(date).setMinutes(
      new Date().getTimezoneOffset() + new Date(date).getMinutes()
    )
  );
}

export function formatPercentage(percent) {
  try {
    const srtValue = percent.toFixed(2);
    const arrayStrValue = srtValue.split(".");
    return (
      arrayStrValue[0]?.padStart(2, 0) + "." + arrayStrValue[1]?.padEnd(2, 0)
    );
  } catch {
    return percent;
  }
}

export function handleErrorNew(
  error,
  altMessage = "Ocorreu um erro durante o processo"
) {
  if (!error?.response) error = { response: { data: false, status: false } };
  const { data, status } = error?.response;
  let message = "";

  if (data) {
    const errorMessage = Object.keys(data.errors).map(
      (key) => data.errors[key]
    );
    // eslint-disable-next-line no-useless-escape
    message = errorMessage.join(",").replace(/\,/, ", ");
  }

  switch (status) {
    case 400:
      message = message ?? "Verifique os dados enviados";
      break;
    case 401:
      sessionStorage.clear();
      history.replace("/login");
      break;
    case 404:
      message = "Nenhum registro encontrado!";
      break;
    case 500:
      message = message ?? "Erro no servidor";
      break;
    default:
      message = "Erro desconhecido!";
      break;
  }

  if (message) return `${altMessage} - ${message}`;
}

export const handleGetSessionStorage = () => {
  const buff = value => Buffer.from(value, "base64").toString("ascii")
  return {
    auth: sessionStorage.getItem(Auth)
      ? JSON.parse(sessionStorage.getItem(Auth))
      : {},
    client: sessionStorage.getItem(Client)
      ? JSON.parse(buff(sessionStorage.getItem(Client)))
      : null,
    company: sessionStorage.getItem(Company)
      ? JSON.parse(buff(sessionStorage.getItem(Company))).idEmpresa
      : null,
    companyName: sessionStorage.getItem(Company)
      ? JSON.parse(buff(sessionStorage.getItem(Company))).nomeEmpresa
      : null,
    fantasy: sessionStorage.getItem(Company)
      ? JSON.parse(buff(sessionStorage.getItem(Company))).fantasiaEmpresa
      : null,
    user: sessionStorage.getItem(Auth)
      ? JSON.parse(sessionStorage.getItem(Auth)).nomeUsuario
      : "",
    token: sessionStorage.getItem(Auth)
      ? JSON.parse(sessionStorage.getItem(Auth)).accessToken
      : "",
  };
};

export function formatDate(data) {
  if (data) {
    data = new Date(data);
    let dia = data.getDate().toString();
    // eslint-disable-next-line eqeqeq
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    // eslint-disable-next-line eqeqeq
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }
}

export function formatDateHours(data) {
  if (data) {
    data = new Date(data);
    let dia = data.getDate().toString();
    // eslint-disable-next-line eqeqeq
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    // eslint-disable-next-line eqeqeq
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    const hours = data.getHours();
    let minutes = data.getMinutes();
    const second = data.getSeconds();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${diaF}/${mesF}/${anoF} ${hours}:${minutes}:${second}`;
  }
}
// btoa(value)
export const handleSetSessionStorage = (option, value) => {
  const newValue = Buffer.from(value).toString("base64")
  switch (option) {
    case "auth":
      sessionStorage.setItem(Auth, value);
      break;
    case "client":
      sessionStorage.setItem(Client, newValue);
      break;
    case "company":
      sessionStorage.setItem(Company, newValue);
      break;
    default:
      console.log("Chave não encontrada!");
      break;
  }
};

export function getInfo() {
  const user = JSON.parse(sessionStorage.getItem(Auth));

  if (!user) {
    return {};
  } else {
    return {
      company: user.empresa,
      authToken: `Bearer ${user.accessToken}`,
      token: `Bearer ${user.accessTokenNode}`,
      user: user.nomeUsuario,
    };
  }
}

function subMenuItemFunction(menuSub, menuName) {
  for (let subMenuItem of menuSub) {
    if (subMenuItem?.nome === menuName) {
      return true;
    } else if (subMenuItem?.menuSub) {
      let contemMenu = subMenuItemFunction(subMenuItem?.menuSub, menuName);
      if (contemMenu) {
        return true;
      }
    }
  }
  return false;
}

export function userHasPermission(menuName) {
  const userStore = JSON.parse(sessionStorage.getItem(Auth));
  if (userStore !== null && userStore.module !== null) {
    const menuList = userStore.module?.length ? userStore.module[0].menu : [];
    if (!menuList) return false;
    for (let menuItem of menuList) {
      if (menuItem?.nome === menuName || menuName === "home") {
        return true;
      } else if (menuItem?.menuSub) {
        let contemMenu = subMenuItemFunction(menuItem?.menuSub, menuName);
        if (contemMenu) {
          return true;
        }
      }
    }
    return false;
  }
}

export function getMimeType(type) {
  const newType = type.replace(".", "");
  switch (newType) {
    case "eml":
      return "application/eml";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "pdf":
      return "application/pdf";
    case "png":
      return "image/png";
    case "bmp":
      return "application/x-MS-bmp";
    case "gif":
      return "image/gif";
    case "jpg":
      return "image/jpeg";
    case "jpeg":
      return "image/jpeg";
    case "avi":
      return "video/x-msvideo";
    case "aac":
      return "audio/x-aac";
    case "mp3":
      return "audio/mpeg";
    case "mp4":
      return "video/mp4";
    case "apk":
      return "application/vnd.Android.package-archive";
    case "txt":
    case "log":
    case "h":
    case "cpp":
    case "js":
    case "html":
      return "text/plain";
    default:
      return "*/*";
  }
}

export function getMimeTypeReverse(type) {
  switch (type) {
    case "application/eml":
      return ".eml";
    case "application/msword":
      return ".doc";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return ".docx";
    case "application/vnd.ms-powerpoint":
      return ".ppt";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return ".pptx";
    case "application/vnd.ms-excel":
      return ".xls";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return ".xlsx";
    case "application/pdf":
      return ".pdf";
    case "image/png":
      return ".png";
    case "application/x-MS-bmp":
      return ".bmp";
    case "image/gif":
      return ".gif";
    case "image/jpeg":
      return ".jpg";
    case "video/x-msvideo":
      return ".avi";
    case "audio/x-aac":
      return ".aac";
    case "audio/mpeg":
      return ".mp3";
    case "video/mp4":
      return ".mp4";
    case "application/vnd.Android.package-archive":
      return ".apk";
    default:
      return null;
  }
}

export function loginByDashboard(token, setLoading, dispatch) {
  let params = {
    token,
    idModulo: process.env.REACT_APP_ID_MODULE,
  };
  api
    .get(`Autenticacao/AutenticacaoDashboard?${qs.stringify(params)}`)
    .then((res) => {
      if (res.data.statusCode >= 400 && res.data.statusCode <= 499) {
        return false;
      } else {
        sessionStorage.setItem(Auth, JSON.stringify(res.data));
        dispatch(toggleModal(true));
      }
    })
    .catch((err) => {
      console.error(err);
      return false;
    })
    .finally(() => setLoading(false));
}

export function removerAcentos(newStringComAcento) {
  let string = newStringComAcento;
  let mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (let letra in mapaAcentosHex) {
    let expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }

  return string;
}

export function Encrypt(plainText) {
  const iv = randomBytes(16);
  const cipher = createCipheriv(
    "aes-256-cbc",
    "OsRollingTest256ShaBlakkUhu#$ido",
    iv
  );
  let cipherText = cipher.update(Buffer.from(plainText, "utf8"));
  cipherText = Buffer.concat([cipherText, cipher.final()]);
  const combinedData = Buffer.concat([iv, cipherText]);
  const combinedString = combinedData.toString("base64");
  return combinedString;
}
