import { Footer } from "@orgystem-ui/components.footer";
import { Header } from "@orgystem-ui/components.header";
import { Login } from "@orgystem-ui/components.login";
import { Sidebar } from "@orgystem-ui/components.sidebar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Loader } from "rsuite";
import Pages from "./authorization/pages";
import RouteAuthorization from "./authorization/routeAuthorization";
import ModalCompany from "./components/modalCompany/ModalCompany";
import { Auth } from "./config/Storage";
import { handleGetSessionStorage, handleSetSessionStorage, loginByDashboard } from "./services/helpers";
import { toggleModal } from "./store/modules/company/action";
import { tabsHeader } from "./store/modules/header/action";
import { logout } from "./store/modules/login/action";
import { toggleMenu } from "./store/modules/sidebar/action";

const DashboardAuthorization = async (location, setLoading, dispatch) => {
  let loc = location.search.substring(1, location.search.length);
  let token = null;
  let param_name = false;
  let params = loc.split("&");
  for (let i = 0; i < params.length; i++) {
    param_name = params[i].substring(0, params[i].indexOf("="));
    if (param_name === "dG9rZW5DcmlwdG9ncmFmYWRv") {
      token = params[i].substring(params[i].indexOf("=") + 1);
    }
  }
  token = token?.replaceAll("%3D", "=");

  if (token) {
    let tokenDecrypt = atob(atob(token));
    window.history.replaceState(null, null, window.location.pathname);
    await loginByDashboard(tokenDecrypt, setLoading, dispatch);
  } else {
    setLoading(false);
  }
};

const PrivateRoute = ({ component: Component, theme, setTheme, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.Sidebar.isOpenMenu);
  const tabs = useSelector((state) => state.Header.tabs);
  const { auth, companyName, user, fantasy } = handleGetSessionStorage()

  return (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem(Auth) != null ? (
          <div style={{ display: "flex", flexWrap: "nowrap" }}>
            {auth.multiEmpresa && <ModalCompany />}
            <Sidebar
              {...props}
              dispatch={dispatch}
              isOpenMenu={isOpen}
              toggleModal={toggleModal}
              toggleMenu={toggleMenu}
              logout={logout}
              user={user}
              history={history}
              theme={theme}
              Auth={Auth}
              tabsHeader={tabsHeader}
              idModulo={process.env.REACT_APP_ID_MODULE}
            />

            <div className="routerContainer">
              <Header
                setTheme={setTheme}
                dispatch={dispatch}
                isOpen={isOpen}
                toggleModal={toggleModal}
                toggleMenu={toggleMenu}
                logout={logout}
                user={user}
                history={history}
                textModal="Trocar Empresa"
                tabsHeader={tabsHeader}
                idModulo={process.env.REACT_APP_ID_MODULE}
                tabs={tabs}
              />
              <Component {...props} />
              <Footer
                user={user}
                complement={{ key: "Empresa - ", value: fantasy ?? companyName }}
              />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !sessionStorage.getItem(Auth) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function handleLogin(e) {
  if (!e.multiEmpresa) {
    const { idEmpresa, nomeEmpresa, fantasiaEmpresa } = e
    handleSetSessionStorage("company", JSON.stringify({
      idEmpresa,
      nomeEmpresa,
      fantasiaEmpresa,
    }))
  }
}

const Routes = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    document.referrer.indexOf("https://orgdashboard.azurewebsites.net") !== -1
      ? DashboardAuthorization(window.location, setLoading, dispatch)
      : setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Switch>
            <GuestRoute
              path="/login"
              component={() => (
                <Login
                  idModulo={process.env.REACT_APP_ID_MODULE}
                  url={process.env.REACT_APP_API}
                  rota="Autenticacao"
                  Auth={Auth}
                  history={history}
                  handleSuccess={handleLogin}
                />
              )}
            />
            {Pages.map((page) => {
              return (
                <PrivateRoute
                  exact
                  key={page.route}
                  path={page.route}
                  component={RouteAuthorization(page.component, page.name)}
                  setTheme={props.setTheme}
                  theme={props.theme}
                />
              );
            })}
          </Switch>
        </>
      ) : (
        <div className="overlay">
          <Loader backdrop vertical size="lg" />
        </div>
      )}
    </>
  );
};

export default Routes;
