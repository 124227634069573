import axios from "axios";

import { Auth } from "../config/Storage";

const apiXHR = axios.create({
  headers: {
    accept: "*/*",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  },
  baseURL: process.env.REACT_APP_API //"http://da2e4eb1fc98.ngrok.io/api/v1/"
});

apiXHR.interceptors.request.use(config => {
  const token = sessionStorage.getItem(Auth)
    ? JSON.parse(sessionStorage.getItem(Auth)).accessToken
    : "";

  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { ...config, headers };
});

export default apiXHR;
