export default function Company(state = {}, action) {
  switch (action.type) {
    case "ADD_COMPANY": {
      const { company } = action;

      return {
        ...state,
        ...company,
      };
    }
    case "ADD_COMPANY_ID": {
      return {
        ...state,
        companyId: action.companyId,
      };
    }
    case "TOGGLE_MODAL":
      return {
        ...state,
        isOpenModal: action.isOpenModal,
      };
    default:
      return state;
  }
}
