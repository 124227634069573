export function addCompany(company, id) {
  return {
    type: "ADD_COMPANY",
    company,
    id,
  };
}

export function addCompanyId(companyId) {
  return {
    type: "ADD_COMPANY_ID",
    companyId,
  };
}

export function toggleModal(isOpenModal) {
  return {
    type: "TOGGLE_MODAL",
    isOpenModal,
  };
}
