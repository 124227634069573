import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import {
  dataSourceAdminCartao,
  dataSourceTipoPagamento,
  dataSourceUteisOuCorridos,
  gridDataSourceTipoCartao,
} from "../RedeCredenciadaService";

const TipoCartaoGrid = () => {
  const { form, successMessage, listaSimNao, listaPeriodo } =
    useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [allowAdding, setAllowAdding] = useState(true);
  const idAcRedeCredenciada = form?.idAcRedeCredenciada;
  const [conciliacaoPeriodo, setConciliacaoPeriodo] = useState("");

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    for (let i = 0; i < 9; i++) {
      if (event.fullName === `columns[${i}].filterValue`) {
        setAllowAdding(true);
      }
    }

    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleRemove({ data }) {
    successMessage("Endereço deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  function handleNewRow(event) {
    event.data.idAcAdminCartao = null;
  }

  function handleEditingStart(event) {
    setAllowAdding(false);
  }

  async function handleInsertError(event) {
    const { newData, oldData = false } = event;

    newData.idAcAdminCartao =
      newData?.idAcAdminCartao ?? oldData?.idAcAdminCartao;
    newData.taxaAdminstracao =
      newData?.taxaAdminstracao ?? oldData?.taxaAdminstracao;
    newData.verTaxa = newData?.verTaxa ?? oldData?.verTaxa;
    newData.verTipocartao = newData?.verTipocartao ?? oldData?.verTipocartao;
    newData.conciliacaoPeriodo =
      newData?.conciliacaoPeriodo ?? oldData?.conciliacaoPeriodo;
    newData.conciliacaoDias =
      newData?.conciliacaoDias ?? oldData?.conciliacaoDias;
    newData.conciliacaoDiasCalculo =
      newData?.conciliacaoDiasCalculo ?? oldData?.conciliacaoDiasCalculo;
    newData.conciliacaoDiaFech =
      newData?.conciliacaoDiaFech ?? oldData?.conciliacaoDiaFech;
    newData.conciliacaoMesAcrescimo =
      newData?.conciliacaoMesAcrescimo ?? oldData?.conciliacaoMesAcrescimo;
    newData.idAcTipoPagamento =
      newData?.idAcTipoPagamento ?? oldData?.idAcTipoPagamento;
    newData.pagamentoFornecedor =
      newData?.pagamentoFornecedor ?? oldData?.pagamentoFornecedor;
    newData.percDescFidel = newData?.percDescFidel ?? oldData?.percDescFidel;
    newData.dataInclusao = oldData.dataInclusao;
    newData.usuario = oldData.usuario ?? "";
  }

  function clearDadosGrid() {
    setAllowAdding(true);
  }

  function handleEditorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField === "conciliacaoPeriodo") {
      e.editorOptions.onValueChanged = (args) => {
        e.setValue(args.value);

        e.component.cellValue(e.row.rowIndex, "conciliacaoPeriodo", args.value);
        setConciliacaoPeriodo(args.value);
      };
    }
  }

  return (
    <DataGrid
      dataSource={gridDataSourceTipoCartao(idAcRedeCredenciada)}
      remoteOperations={true}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      onInitNewRow={handleNewRow}
      onEditingStart={handleEditingStart}
      onRowInserted={handleInsert}
      onRowRemoved={handleRemove}
      onRowValidating={handleInsertError}
      onSaved={() => clearDadosGrid()}
      onEditCanceled={() => clearDadosGrid()}
      onRowUpdated={handleUpdate}
      onOptionChanged={handleOptionChange}
      // loadPanel={true}
      style={{ marginTop: "0.5rem", paddingLeft: "0.5rem" }}
      onEditorPreparing={handleEditorPreparing}
    >
      <Pager
        allowedPageSizes={[7, 15, 30]}
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
      />
      <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
      <Editing
        /* mode="row" */ useIcons
        allowAdding
        allowDeleting
        allowUpdating
      />
      <FilterRow
        visible={filterOptions.showFilterRow}
        applyFilter={filterOptions.currentFilter}
      />
      <HeaderFilter />

      <Column
        dataField="idAcAdminCartao"
        caption="Admin Cartão"
        filterOperations={false}
        alignment="left"
        width={"15%"}
      >
        <Lookup
          dataSource={dataSourceAdminCartao}
          displayExpr="descricao"
          valueExpr="idAcAdminCartao"
        />
        <RequiredRule message="Admin cartão é obrigatório" />
      </Column>

      <Column
        width={"10%"}
        dataField="taxaAdminstracao"
        caption="% Taxa Adm."
        filterOperations={false}
        alignment="left"
      >
        <RequiredRule />
      </Column>

      <Column
        dataField="verTaxa"
        caption="Ver taxa?"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={listaSimNao}
          displayExpr="descricao"
          valueExpr="id"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      <Column
        dataField="verTipocartao"
        caption="Ver tipo cartão?"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={listaSimNao}
          displayExpr="descricao"
          valueExpr="id"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      <Column
        dataField="conciliacaoPeriodo"
        caption="Periodo"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={listaPeriodo}
          displayExpr="descricao"
          valueExpr="id"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      <Column
        width={"10%"}
        dataField="conciliacaoDias"
        caption="Conciliação Dias"
        filterOperations={false}
        alignment="left"
      >
        <RequiredRule />
      </Column>

      <Column
        dataField="conciliacaoDiasCalculo"
        caption="Úteis ou Corridos"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={dataSourceUteisOuCorridos}
          displayExpr="descricao"
          valueExpr="inicial"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      {conciliacaoPeriodo ? (
        <Column
          width={"10%"}
          dataField="conciliacaoDiaFech"
          caption="Dia Fechamento"
          filterOperations={false}
          alignment="left"
        >
          <RequiredRule />
        </Column>
      ) : null}

      <Column
        width={"10%"}
        dataField="conciliacaoMesAcrescimo"
        caption="Acrescimo mês Pagamento"
        filterOperations={false}
        alignment="left"
      >
        <RequiredRule />
      </Column>

      <Column
        dataField="idAcTipoPagamento"
        caption="Tipo Pagamento"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={dataSourceTipoPagamento}
          displayExpr="descricao"
          valueExpr="idAcTipoPagamento"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      <Column
        dataField="pagamentoFornecedor"
        caption="Pagamento Fornecedor?"
        filterOperations={false}
        alignment="left"
        width={"10%"}
      >
        <Lookup
          dataSource={listaSimNao}
          displayExpr="descricao"
          valueExpr="id"
        />
        <RequiredRule message="Campo obrigatório" />
      </Column>

      <Column
        width={"10%"}
        dataField="percDescFidel"
        caption="% Desc. Fidelidade"
        filterOperations={false}
        alignment="left"
      >
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};

export default TipoCartaoGrid;
