import { Column, DataGrid, Editing } from "devextreme-react/data-grid";
import React from "react";
import { useAtendimentoContext } from "../context/AtendimentoContext";

const ProcedimentosGrid = () => {
  const { form, setForm, disabled } = useAtendimentoContext();

  function handleChange(e) {
    if (e.parentType === "dataRow" && e.dataField === "check") {
      e.editorOptions.onValueChanged = (args) => {
        let newForm = form.procedimentoMedico.map((item, i) => {
          if (e.row.rowIndex === i) {
            item.check = !item.check;
          }
          return item;
        });
        setForm({ ...form, procedimentoMedico: [...newForm] });
      };
    }
  }

  return (
    <>
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={form.procedimentoMedico}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onEditorPreparing={handleChange}
        disabled={disabled}
      >
        <Editing mode="cell" allowUpdating={true} />

        <Column
          dataField="descricao"
          caption="Procedimentos"
          filterOperations={false}
          allowEditing={false}
          allowSorting={false}
        ></Column>
        <Column
          dataField="valor"
          caption="Valor"
          filterOperations={false}
          allowEditing={false}
          allowSorting={false}
          format={{ style: "currency", currency: "BRL" }}
        ></Column>
        <Column
          alignment="center"
          dataField="check"
          caption={"Selecionar"}
          allowSorting={false}
          width="16%"
        ></Column>
      </DataGrid>
    </>
  );
};

export { ProcedimentosGrid };

