import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import {
  dataSourceAtividade,
  gridDataSourceRamoAtividade,
} from "../RedeCredenciadaService";

const RamoAtividadeGrid = () => {
  const { form, successMessage } = useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [allowAdding, setAllowAdding] = useState(true);
  const idAcRedeCredenciada = form?.idAcRedeCredenciada;

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    for (let i = 0; i < 9; i++) {
      if (event.fullName === `columns[${i}].filterValue`) {
        setAllowAdding(true);
      }
    }

    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleRemove({ data }) {
    successMessage("Endereço deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  function handleNewRow(event) {
    event.data.idAcRamoAtividade = null;
  }

  function handleEditingStart(event) {
    setAllowAdding(false);
  }

  async function handleInsertError(event) {
    const { newData, oldData = false } = event;

    newData.idAcRamoAtividade =
      newData.idAcRamoAtividade ?? oldData.idAcRamoAtividade;
    newData.dataInclusao = oldData.dataInclusao;
    newData.usuario = oldData.usuario ?? "";
  }

  function clearDadosGrid() {
    setAllowAdding(true);
  }

  return (
    <DataGrid
      dataSource={gridDataSourceRamoAtividade(idAcRedeCredenciada)}
      remoteOperations={true}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      onInitNewRow={handleNewRow}
      onEditingStart={handleEditingStart}
      onRowInserted={handleInsert}
      onRowRemoved={handleRemove}
      onRowValidating={handleInsertError}
      onSaved={() => clearDadosGrid()}
      onEditCanceled={() => clearDadosGrid()}
      onRowUpdated={handleUpdate}
      onOptionChanged={handleOptionChange}
      // loadPanel={true}
      style={{ marginTop: "0.5rem", paddingLeft: "0.5rem" }}
    >
      <Pager
        allowedPageSizes={[7, 15, 30]}
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
      />
      <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
      <Editing
        /* mode="row" */ useIcons
        allowAdding
        allowDeleting
        allowUpdating
      />
      <FilterRow
        visible={filterOptions.showFilterRow}
        applyFilter={filterOptions.currentFilter}
      />
      <HeaderFilter />

      <Column
        dataField="idAcRamoAtividade"
        caption="Atividade"
        filterOperations={false}
        alignment="left"
        width={"100%"}
      >
        <Lookup
          dataSource={dataSourceAtividade}
          displayExpr="descricao"
          valueExpr="idAcRamoAtividade"
        />
        <RequiredRule message="Ramo Atividade é obrigatório" />
      </Column>
    </DataGrid>
  );
};

export default RamoAtividadeGrid;
