import api from "../../services/api";
import qs from "qs";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";
import CustomStore from "devextreme/data/custom_store";

//handleLoad

export function handleLoad(
  loadOptions,
  baseUrl,
  otherParams = {},
  nameEmpresaParam = "idEmpresa"
) {
  const { company } = handleGetSessionStorage();
  const parametros = {
    [nameEmpresaParam]: company,
    skip: 0,
    take: 1000,
    ...otherParams,
  };
  const params = handleParams(parametros, loadOptions);
  const url = `${baseUrl}?${qs.stringify(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((res) => {
        const { data, totalCount } = res.data;
        resolve({
          data,
          totalCount,
        });
      })
      .catch((error) => {
        reject(handleError(error, "Erro ao carregar"));
      });
  });
}

export function handleLoadById(
  baseUrl,
  otherParams = {},
  nameEmpresaParam = "idEmpresa"
) {
  const { company } = handleGetSessionStorage();
  const parametros = {
    [nameEmpresaParam]: company,
    ...otherParams,
  };
  const url = `${baseUrl}?${qs.stringify(parametros)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(handleError(error, "Erro ao carregar"));
      });
  });
}

export function handlePostAndPut(data, url, method) {
  return new Promise((resolve, reject) => {
    api({ method, url, data })
      .then((res) => {
        const { data, totalCount } = res?.data;
        data
          ? resolve({
              data,
              totalCount,
            })
          : resolve(res.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
}

//gridDataSource

export const gridDataSourceFilter = {
  store: new CustomStore({
    key: ["idAcRedeCredenciada"],
    load: (loadOptions) => {
      const url = "RedeCredenciada/RedeCredenciadaTerminal";
      return handleLoad(loadOptions, url);
    },
  }),
};
