import React, { useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { Modal } from "rsuite";
import "./ModalSelect.css";

const ModalSelect = ({
  toggleModal,
  title,
  visible,
  dataSource,
  colums,
  height,
  width,
  gridHeight,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const checkToggle = () => {
    toggleModal();
  };

  const handleOption = (event) => {
    toggleModal(event.data);
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  return (
    <Modal
      open={visible}
      onClose={checkToggle}
      style={{
        minWidth: "40%",
        height,
        width: width,
        maxWidth: "100%",
      }}
    >
      <Modal.Header style={{ display: "block" }}>
        <Modal.Title>Selecione {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden" }}>
        <DataGrid
          className="datagrid-modal"
          height={gridHeight}
          dataSource={dataSource}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onRowClick={handleOption}
          onOptionChanged={handleOptionChange}
        >
          <Pager visible={true} showPageSizeSelector={true} showInfo={true} />
          <Paging defaultPageSize={10} />

          <FilterRow visible={true} />
          <HeaderFilter />

          {colums.map((item) => (
            <Column
              key={item.dataField}
              alignment={item.alignment}
              caption={item.caption}
              dataField={item.dataField}
              filterOperations={false}
              selectedFilterOperation="="
              style={{ cursor: "pointer" }}
              cssClass="columnModal"
              width={item?.width}
            ></Column>
          ))}
        </DataGrid>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSelect;
