import api from "../../services/api";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
  removerAcentos,
} from "../../services/helpers";
import qs from "qs";
import CustomStore from "devextreme/data/custom_store";

export async function handleLoad(loadOptions, url, form, otherParams) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    idAcRedeCredenciada: form ? form?.idAcRedeCredenciada : undefined,
    idAcAdminCartao: form ? form?.idAcAdminCartao : undefined,
    ...otherParams,
  };
  const newParams = loadOptions ? handleParams(params, loadOptions) : params;
  const urlComplete = `${url}${qs.stringify(newParams)}`;

  try {
    const res = await api.get(urlComplete);
    const { data, totalCount } = res.data;
    return {
      data,
      totalCount,
    };
  } catch (err) {
    return handleError(err, "Erro ao carregar");
  }
}

export function handleDelete(id, url) {
  return api
    .delete(`${url}${id}`)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

// function handleLoadByKey(key, name, url) {
//   const params = {
//     [name]: key,
//   };
//   const newUrl = `${url}${qs.stringify(params)}`;
//   return api
//     .get(newUrl)
//     .then(({ data }) => {
//       return [{ ...data }];
//     })
//     .catch((error) => handleError(error.response));
// }

function handleRemove(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    idAcRedeCredenciada: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

export function handlePostAndPut(data, url, method) {
  return new Promise((resolve, reject) => {
    api({ method, url, data })
      .then((res) => {
        if (res?.data?.data) {
          const { data, totalCount } = res?.data;
          resolve({
            data,
            totalCount,
          });
        } else {
          resolve({
            data: res?.data,
          });
        }
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
}

export function handleLoadCity(loadOptions, url, sigla) {
  const params = {
    uf: sigla,
  };
  const newParams = handleParams(params, loadOptions);
  const urlFormated = `${url}?${qs.stringify(newParams)}`;

  return api
    .get(urlFormated)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

function handleLoadById(loadOptions, url, nameId, nomeEmpresa = "empresa") {
  const { company } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;
  const params = {
    [nameId]: id ? id : 0,
    [nomeEmpresa]: company,
    skip: 0,
    take: 1,
  };
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError(err, "Erro ao carregar"));
}

export function handleLoadList(url) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    skip: 0,
    take: 1000,
  };
  // const newParams = handleParams(params, loadOptions);
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError(err, "Erro ao carregar"));
}

export function handleCepInfo({ localidade, uf }) {
  const params = {
    cidade: removerAcentos(localidade),
    uf: uf,
    skip: 0,
    take: 1,
  };
  const url = `/CrCidades/GetCrCidadeAutoComplete?${qs.stringify(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function gridDataSourceEndereco(idAcRedeCredenciada) {
  const { company, user } = handleGetSessionStorage();
  return {
    store: new CustomStore({
      key: "idAcRedeCredenciadaEnde",
      load: (loadOptions) => {
        const url = "/Endereco/GetPorEmpresa?";
        return handleLoad(loadOptions, url, { idAcRedeCredenciada });
      },
      insert: (values) => {
        const url = "/Endereco";
        const record = {
          ...values,
          empresa: company,
          idAcRedeCredenciada,
          usuario: user,
        };
        return handlePostAndPut(record, url, "POST");
      },
      update: (key, values) => {
        const { company } = handleGetSessionStorage();
        const url = "/Endereco";
        const record = {
          idAcRedeCredenciada,
          idAcRedeCredenciadaEnde: key,
          empresa: company,
          ...values,
        };
        return handlePostAndPut(record, url, "PUT");
      },
      remove: (values) => {
        const url = "/Endereco";
        return handleRemoveEndereco(values, url);
      },
    }),
  };
}

export function gridDataSourceRamoAtividade(idAcRedeCredenciada) {
  const { company, user } = handleGetSessionStorage();
  return {
    store: new CustomStore({
      key: "idAcRedeCredenciadaAtiv",
      load: (loadOptions) => {
        const url = "/RamoAtividade/GetPorEmpresa?";
        return handleLoad(loadOptions, url, { idAcRedeCredenciada });
      },
      insert: (values) => {
        const url = "/RamoAtividade";
        const record = {
          ...values,
          empresa: company,
          idAcRedeCredenciada,
          usuario: user,
        };
        return handlePostAndPut(record, url, "POST");
      },
      update: (key, values) => {
        const { company } = handleGetSessionStorage();
        const url = "/RamoAtividade";
        const record = {
          idAcRedeCredenciada,
          idAcRedeCredenciadaAtiv: key,
          empresa: company,
          ...values,
        };
        return handlePostAndPut(record, url, "PUT");
      },
      remove: (values) => {
        const url = "/RamoAtividade";
        return handleRemoveAtividade(values, url);
      },
    }),
  };
}

export function gridDataSourceTipoCartao(idAcRedeCredenciada) {
  const { company, user } = handleGetSessionStorage();
  return {
    store: new CustomStore({
      key: "idAcRedeCredenciadaTcar",
      load: (loadOptions) => {
        const url = "/TipoCartao/GetPorEmpresa?";
        return handleLoad(loadOptions, url, { idAcRedeCredenciada });
      },
      insert: (values) => {
        const url = "/TipoCartao";
        const record = {
          ...values,
          empresa: company,
          idAcRedeCredenciada,
          usuario: user,
        };
        return handlePostAndPut(record, url, "POST");
      },
      update: (key, values) => {
        const { company } = handleGetSessionStorage();
        const url = "/TipoCartao";
        const record = {
          idAcRedeCredenciada,
          idAcRedeCredenciadaTcar: key,
          empresa: company,
          ...values,
        };
        return handlePostAndPut(record, url, "PUT");
      },
      remove: (values) => {
        const url = "/TipoCartao";
        return handleRemoveTipoCartao(values, url);
      },
    }),
  };
}

export function gridDataSourceProcedimentoMedicoFornecedor(
  idAcRedeCredenciada
) {
  const { company, user } = handleGetSessionStorage();
  return {
    store: new CustomStore({
      key: "idAcAdminCartao",
      load: (loadOptions) => {
        const url = "/AcAdminCartao/DescricaoCartao?";
        return handleLoad(loadOptions, url, { idAcRedeCredenciada });
      },
      insert: (values) => {
        const url = "/AcAdminCartao/DescricaoCartao";
        const record = {
          ...values,
          empresa: company,
          idAcRedeCredenciada,
          usuario: user,
        };
        return handlePostAndPut(record, url, "POST");
      },
      update: (key, values) => {
        const { company } = handleGetSessionStorage();
        const url = "/AcAdminCartao/DescricaoCartao";
        const record = {
          idAcRedeCredenciada,
          idAcAdminCartao: key,
          empresa: company,
          ...values,
        };
        return handlePostAndPut(record, url, "PUT");
      },
      remove: (values) => {
        const url = "/AcAdminCartao/DescricaoCartao";
        return handleRemoveAdminCartao(values, url);
      },
    }),
  };
}

export function gridDetailDataSourceProcedimentoMedicoFornecedor(
  idAcRedeCredenciada,
  idAcAdminCartao
) {
  const { company, user } = handleGetSessionStorage();
  return {
    store: new CustomStore({
      key: "idAcRedeCredenciadaProMed",
      load: (loadOptions) => {
        const url = "/AcRedeCredeProMed?";
        return handleLoad(loadOptions, url, {
          idAcAdminCartao,
          idAcRedeCredenciada,
        });
      },
      insert: (values) => {
        const url = "/AcRedeCredeProMed";
        const record = {
          ...values,
          idAcRedeCredenciadaProMed: 0,
          empresa: company,
          idAcAdminCartao,
          idAcRedeCredenciada,
          usuario: user,
        };
        return handlePostAndPut(record, url, "POST");
      },
      update: (key, values) => {
        const { company } = handleGetSessionStorage();
        const url = "/AcRedeCredeProMed";
        const record = {
          idAcAdminCartao,
          idAcRedeCredenciadaProMed: key,
          empresa: company,
          ...values,
        };
        return handlePostAndPut(record, url, "PUT");
      },
      remove: (values) => {
        const url = "/AcRedeCredeProMed";
        return handleRemoveProcedimentoMedico(values, url);
      },
    }),
  };
}

export function gridDataSourceCidade(sigla) {
  return {
    store: new CustomStore({
      key: "idCrCidades",
      byKey: (loadOptions) =>
        handleLoadCity(
          loadOptions,
          "/CrCidades/GetCrCidadeAutoComplete?",
          sigla
        ),
      load: (loadOptions) =>
        handleLoadCity(
          loadOptions,
          "/CrCidades/GetCrCidadeAutoComplete?",
          sigla
        ),
    }),
  };
}
export const gridDataSourceUf = {
  store: new CustomStore({
    key: "idCrUf",
    byKey: (loadOptions) =>
      handleLoad(loadOptions, "/CrUf/GetCrUfAutoComplete?"),
    load: (loadOptions) =>
      handleLoad(loadOptions, "/CrUf/GetCrUfAutoComplete?"),
  }),
};

export const gridDataSourceTipoLogradouro = {
  store: new CustomStore({
    key: "idAcTipoLogradouro",
    byKey: (loadOptions) =>
      handleLoad(
        loadOptions,
        "/AcTipoLogradouro/GetAcTipoLogradouroAutoComplete?"
      ),
    load: (loadOptions) =>
      handleLoad(
        loadOptions,
        "/AcTipoLogradouro/GetAcTipoLogradouroAutoComplete?"
      ),
  }),
};

export function handleUpdateEndereco(params) {
  const { company, user } = handleGetSessionStorage();
  const url = "AcRedeCredenciadaEnde";

  const record = {
    ...params,
    usuario: user,
    empresa: company,
  };

  return new Promise((resolve, reject) => {
    api
      .put(url, record)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

function handleRemoveEndereco(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    idAcRedeCredenciadaEnde: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}
function handleRemoveAtividade(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    IdAcRedeCredenciadaAtiv: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

function handleRemoveTipoCartao(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    idAcRedeCredenciadaTcar: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

function handleRemoveAdminCartao(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    idAcAdminCartao: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

function handleRemoveProcedimentoMedico(id, url) {
  const { user } = handleGetSessionStorage();
  const params = {
    idAcRedeCredenciadaProMed: id,
    usuario: user,
  };
  const urlComplete = `${url}?${qs.stringify(params)}`;
  return api
    .delete(urlComplete)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

export function handleLoadFornecedor(data) {
  const { company, user } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    usuario: user,
    idAcRedeCredenciada: data,
    skip: 0,
    take: 1000,
  };
  // const newParams = handleParams(params, loadOptions);
  const urlComplete = `/RedeCredenciada/FornecedorRedeCredenciada?${qs.stringify(
    params
  )}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const data = res.data;
      return {
        data,
      };
    })
    .catch((err) => handleError(err, "Erro ao carregar"));
}

//TipoEndereco
export const dataSourceTipoEndereco = {
  store: new CustomStore({
    key: "idTipoEndereco",
    byKey: (key) =>
      handleLoadById(
        key,
        `/SysComboTipoEndereco?`,
        "idTipoEndereco",
        "idEmpresa"
      ),
    load: (loadOptions) => handleLoad(loadOptions, `/SysComboTipoEndereco?`),
  }),
};
//Tipo Cartão
export const dataSourceTipoCartao = {
  store: new CustomStore({
    key: "idAcTipoCartao",
    byKey: (key) =>
      handleLoadById(key, `/TipoCartao?`, "idAcTipoCartao", "idEmpresa"),
    load: (loadOptions) => handleLoad(loadOptions, `/TipoCartao?`),
  }),
};
//Tipo Pagamento
export const dataSourceTipoPagamento = {
  store: new CustomStore({
    key: "idAcTipoPagamento",
    byKey: (key) =>
      handleLoadById(
        key,
        `/AcTipoPagamento/TipoPagamento?`,
        "idAcTipoPagamento",
        "idEmpresa"
      ),
    load: (loadOptions) =>
      handleLoad(loadOptions, `/AcTipoPagamento/TipoPagamento?`),
  }),
};

//Admin Cartão
export const dataSourceAdminCartao = {
  store: new CustomStore({
    key: "idAcAdminCartao",
    byKey: (key) =>
      handleLoadById(
        key,
        `/AcAdminCartao/DescricaoCartao?`,
        "idAcAdminCartao",
        "idEmpresa"
      ),
    load: (loadOptions) =>
      handleLoad(loadOptions, `/AcAdminCartao/DescricaoCartao?`),
  }),
};

//Dias uteis ou corridos
export const dataSourceUteisOuCorridos = {
  store: new CustomStore({
    key: "conciliacaoDiasCalculo",
    byKey: (key) =>
      handleLoadById(
        key,
        `/SysComboItens/UteisOuCorridos?`,
        "conciliacaoDiasCalculo",
        "idEmpresa"
      ),
    load: (loadOptions) =>
      handleLoad(loadOptions, `/SysComboItens/UteisOuCorridos?`),
  }),
};

//Ramo atividade
export const dataSourceAtividade = {
  store: new CustomStore({
    key: "idAcRamoAtividade",
    byKey: (key) =>
      handleLoadById(
        key,
        `/RamoAtividade/GetPorEmpresa?`,
        "idAcRamoAtividade",
        "idEmpresa"
      ),
    load: (loadOptions) =>
      handleLoad(loadOptions, `/RamoAtividade/GetPorEmpresa?`),
  }),
};

//Rede Credenciada
export const dataSourceRedeCredenciada = {
  store: new CustomStore({
    key: "idAcRedeCredenciada",
    load: (loadOptions) => handleLoad(loadOptions, `RedeCredenciada?`),
    remove: (id) => handleRemove(id, `RedeCredenciada`),
  }),
};

//Ramo atividade
export const dataSourceProcedimentosMedicos = {
  store: new CustomStore({
    key: "idAcProcedimentoMedico",
    byKey: (key) =>
      handleLoadById(
        key,
        `/ProcedimentoMedico?`,
        "idAcProcedimentoMedico",
        "idEmpresa"
      ),

    load: (loadOptions) =>
      handleLoadProcedimento(
        loadOptions,
        `/ProcedimentoMedico?`),
  }),
  paginate: true,
  pageSize: 20,
};

export function handleLoadProcedimento(loadOptions, url) {
  const { company } = handleGetSessionStorage();

  const { searchValue } = loadOptions

  const params = {
    idEmpresa: company,
    descricao: searchValue
  };
  const newParams = loadOptions ? handleParams(params, loadOptions) : params;
  const urlComplete = `${url}${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError(err, "Erro ao carregar"));
}
