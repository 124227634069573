/* eslint-disable no-unused-vars */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import {
  handleGetSessionStorage,
  removerAcentos,
} from "../../../services/helpers";
import { handleLoadList, handlePostAndPut } from "../RedeCredenciadaService";

const RedeCredenciadaContext = createContext();

export function useRedeCredenciadaContext() {
  return useContext(RedeCredenciadaContext);
}

export function RedeCredenciadaProvider({ children }) {
  const { user, company } = handleGetSessionStorage();
  const codigoEmpresa = company;
  const nomeUsuario = user;
  const { addToast } = useToasts();
  const [activeNav, setActiveNav] = useState("redeCredenciada");
  const [activeFornecedorNav, setActiveFornecedorNav] =
    useState("ramoAtividade");
  const [load, setLoad] = useState(false);

  const InitialForm = {
    idAcRedeCredenciada: null,
    empresa: codigoEmpresa,
    codigo: 0,
    digito: 0,
    tipoPessoa: 2,
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
    inscricaoEstadual: "",
    telefone: "",
    fax: "",
    celular: "",
    email: "",
    contato: "",
    cliente: false,
    fornecedor: false,
    fornecedorIdCbBancos: 0,
    fornecedorTipoConta: 0,
    fornecedorNumeroBanco: "",
    fornecedorAgencia: "",
    fornecedorNumeroConta: "",
    fornecedorNomecc: "",
    fornecedorCpfcc: "",
    fornecedorValorDoc: 0,
    senha: "",
    usuario: nomeUsuario,
    auddatahora: "",
    auditoria: "",
    representante: false,
    idAcRedeCredenciadaRepr: null,
    nrEstabelecimentoUniversal: 0,
    aliquotaRepasseCartao: null,
    valorRepasseSeguro: 0,
    codigoCliente: 0,
    fornecedorTipoOperacao: "",
    redeMatriz: false,
    idAcRedeCredenciadaMatriz: null,
    isentoValorDoc: "",
    sairmensagemjuros: false,
    fornecedorAdministrativo: "",
    emiteNfse: "",
    reterIss: 0,
    inscricaoMunicipal: "",
    email2: "",
    fornAgrRemPg: "",
    tipoChavePix: 0,
    nomePix: "",
    bancoPix: 0,
    chavePix: "",
    observacao: "",
  };
  const InitialFormFornecedor = {
    idAcRedeCredenciada: null,
    diaPagamento: null,
    fornecedorDiaPagto2: null,
    percentualAdministracao: null,
    fornecedorSituacao: null,
    tipoFidelidade: null,
    fidelidadeExclusiva: "N",
    fornecedorPgAcordo: "N",
    idAcVendedor: null,
    fornecedorQtdDiasFech: 1100,
    fornecedorExtPeriodoOuCiclo: "",
    fornecedorPeriodoCicloAno: null,
    fornecedorAceitaTicket: true,
    fornecedorTaxaCred: null,
    fornecedorSairSite: true,
    fornecedorSairFechamentoSite: "",
    fornecedorMsgProCupL1: "",
    fornecedorMsgProCupL2: "",
    naoValidaDadosOpVenda: true,
    fornecedorLimiteCtaPagar: true,
    conciliacaoFinanceira: null,
    fornSondaTempoMaximo: null,
    fornSondaAcaoTempoExc: null,
    caminhoGerarArq: "",
    fornecedorPercIss: null,
    fornecedorDataInclusao: new Date(),
    funcionamento24Hrs: true,
    fornecedorDataExclusao: new Date(),
    logo: "",
  };

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState(InitialForm);
  const [formFornecedor, setFormFornecedor] = useState(InitialFormFornecedor);
  const [listTipoChavePix, setListTipoChavePix] = useState([]);
  const [listBanco, setListBanco] = useState([]);
  const [listTipoConta, setListTipoConta] = useState([]);
  const [listRedeMatriz, setListRedeMatriz] = useState([]);
  const [listRepresentanteResponsavel, setListRepresentanteResponsavel] =
    useState([]);
  const [listSituacaoFornecedor, setListSituacaoFornecedor] = useState([]);
  const [listTipoFidelidade, setListTipoFidelidade] = useState([]);
  const [listVendedor, setListVendedor] = useState([]);
  const [listConciliaaoFinanceira, setListConciliaaoFinanceira] = useState([]);
  const [listTempoSondaExcedido, setListTempoSondaExcedido] = useState([]);
  const [listDiasUteisOuCorridos, setListDiasUteisOuCorridos] = useState([]);
  const [listAdminCartao, setListAdminCartao] = useState([]);
  const [listTipoPagamento, setListTipoPagamento] = useState([]);

  const [listItem, setListItem] = useState([]);
  const [dataSourceItem, setDataSourceItem] = useState([]);

  const [dataSourceFinanceiro, setDataSourceFinanceiro] = useState([]);

  const listaSimNao = [
    {
      id: "S",
      descricao: "Sim",
      numero: 1,
    },
    {
      id: "N",
      descricao: "Não",
      numero: 2,
    },
  ];

  const listaPeriodo = [
    {
      id: "M",
      descricao: "Mensal",
    },
    {
      id: "S",
      descricao: "Semanal",
    },
    {
      id: "Q",
      descricao: "Quinzenal",
    },
    {
      id: "D",
      descricao: "Decendial",
    },
  ];

  const listaTipoPessoa = [
    {
      id: 1,
      descricao: "Física",
    },
    {
      id: 2,
      descricao: "Jurídica",
    },
  ];

  const listaTipoOperacao = [
    {
      id: "C",
      descricao: "Crédito em C.C.",
    },
    {
      id: "D",
      descricao: "Pag. Fornecedor",
    },
  ];
  const listaPeriodoOuCiclo = [
    {
      id: "C",
      descricao: "Ciclo",
    },
    {
      id: "P",
      descricao: "Período",
    },
  ];

  useEffect(() => {
    // handleLoadList(`/TipoDocumentoFinanceiro/GetPorEmpresa?`).then(res =>
    //   setListAdministrativo(res.data)
    // );
    handleLoadList(`/SysComboFornTipoConta?`).then((res) =>
      setListTipoConta(res.data)
    );
    handleLoadList(`SysComboItens/TipoChavePix?`).then((res) =>
      setListTipoChavePix(res.data)
    );
    handleLoadList(`/CbBancos/Bancos?`).then((res) => setListBanco(res.data));
    handleLoadList(`/RedeCredenciada/RedeMatriz?`).then((res) =>
      setListRedeMatriz(res.data)
    );
    handleLoadList(`/RedeCredenciada/RepresentanteResponsavel?`).then((res) =>
      setListRepresentanteResponsavel(res.data)
    );
    handleLoadList(`/SysComboClienteSituacao?`).then((res) =>
      setListSituacaoFornecedor(res.data)
    );
    handleLoadList(`/SysComboItens/TipoFidelidade?`).then((res) =>
      setListTipoFidelidade(res.data)
    );
    handleLoadList(`/AcVendedor?`).then((res) => setListVendedor(res.data));
    handleLoadList(`/SysComboConcFinanceira/ConciliacaoFinanceira?`).then(
      (res) => setListConciliaaoFinanceira(res.data)
    );
    handleLoadList(`/SysComboItens/SondaAcaoTempoExec?`).then((res) =>
      setListTempoSondaExcedido(res.data)
    );
    handleLoadList(`/SysComboItens/UteisOuCorridos?`).then((res) =>
      setListDiasUteisOuCorridos(res.data)
    );
    handleLoadList(`/AcAdminCartao/DescricaoCartao?`).then((res) =>
      setListAdminCartao(res.data)
    );
    handleLoadList(`/AcTipoPagamento/TipoPagamento?`).then((res) =>
      setListTipoPagamento(res.data)
    );
  }, []);

  //Pessoa
  const [caParametro, setCaParametro] = useState();

  //Itens
  const [listIcms, setListIcms] = useState([]);
  const [listCodigoFiscal, setListCodigoFiscal] = useState([]);

  function handleChange(value) {
    setForm({ ...form, ...value });
  }

  const handleValueChange = useCallback((value, input) => {
    // eslint-disable-next-line
    setForm((prevState) => ({
      ...prevState,
      [input]: Number.parseFloat(value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeFornecedor(value) {
    setFormFornecedor({ ...formFornecedor, ...value });
  }

  const handleValueChangeFornecedor = useCallback((value, input) => {
    // eslint-disable-next-line
    setFormFornecedor((prevState) => ({
      ...prevState,
      [input]: Number.parseFloat(value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successMessage = (message) => {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  function errorMessage(message) {
    addToast(message, {
      appearance: "error",
      autoDismiss: true,
    });
  }

  function handleSubmitRedeCredenciada() {
    let record = form;
    if (!record.tipoPessoa || !record.razaoSocial || !record.cnpj) {
      setErrors({
        tipoPessoa: !record.tipoPessoa ? "Tipo Pessoa é obrigatório" : null,
        razaoSocial: !record.razaoSocial
          ? "Nome / Razão Social é obrigatório"
          : null,
        cnpj: !record.cnpj ? "CPF / CNPJ é obrigatório" : null,
      });
      return addToast("Verifique campos obrigatórios!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    if (record.tipoChavePix) {
      if (!record.bancoPix || !record.nomePix || !record.chavePix) {
        setErrors((state) => ({
          ...state,
          chavePix:
            record.tipoChavePix && !record.chavePix
              ? "Chave Pix é obrigatório"
              : null,
          nomePix:
            record.tipoChavePix && !record.nomePix
              ? "Nome Pix é obrigatório"
              : null,
          bancoPix:
            record.tipoChavePix && !record.bancoPix
              ? "Banco Pix é obrigatório"
              : null,
        }));
        return addToast("Verifique campos obrigatórios!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }

    setErrors({});
    const method = form?.idAcRedeCredenciada ? "PUT" : "POST";
    handlePostAndPut(record, "RedeCredenciada", method)
      .then((res) => {
        setActiveNav("redeCredenciada");
        setForm(res.data);
        successMessage("Salvo com sucesso");
      })
      .catch((err) => {
        errorMessage(err);
      })
      .finally(() => setLoad(false));
  }

  function handleSubmitRedeCredenciadaFornecedor() {
    let record = formFornecedor;
    record.fornecedorAceitaValores = formFornecedor.aceitaValores ? "S" : "N";
    if (
      !record.diaPagamento ||
      !record.fornecedorDiaPagto2 ||
      !record.fornecedorPgAcordo ||
      !record.percentualAdministracao
    ) {
      setErrors({
        diaPagamento: !record.diaPagamento
          ? "Dia pagamento é obrigatório"
          : null,
        fornecedorDiaPagto2: !record.fornecedorDiaPagto2
          ? "Dia pagamento 2 é obrigatório"
          : null,
        fornecedorPgAcordo: !record.fornecedorPgAcordo
          ? "Pagamento acordo é obrigatório"
          : null,
        percentualAdministracao: !record.percentualAdministracao
          ? "Taxa é obrigatório"
          : null,
      });
      addToast("Verifique campos obrigatórios!", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      setErrors({});

      const method = form?.idAcRedeCredenciada ? "PUT" : "POST";
      handlePostAndPut(
        record,
        "RedeCredenciada/FornecedorRedeCredenciada",
        method
      )
        .then((res) => {
          setActiveNav("fornecedor");
          const data = res.data;
          setFormFornecedor({
            ...data,
            aceitaValores: data.fornecedorAceitaValores === "S" ? true : false,
          });
          successMessage("Salvo com sucesso");
        })
        .catch((err) => {
          errorMessage(err);
        })
        .finally(() => setLoad(false));
    }
  }

  const value = {
    load,
    setLoad,
    codigoEmpresa,
    nomeUsuario,
    errors,
    setErrors,
    activeNav,
    setActiveNav,
    activeFornecedorNav,
    setActiveFornecedorNav,
    errorMessage,
    successMessage,
    InitialForm,
    InitialFormFornecedor,
    form,
    setForm,
    formFornecedor,
    setFormFornecedor,
    dataSourceItem,
    setDataSourceItem,
    dataSourceFinanceiro,
    setDataSourceFinanceiro,
    handleChange,
    handleValueChange,
    handleChangeFornecedor,
    listItem,
    setListItem,
    caParametro,
    listIcms,
    listCodigoFiscal,
    setListCodigoFiscal,
    handleSubmitRedeCredenciada,
    handleSubmitRedeCredenciadaFornecedor,
    listBanco,
    listaSimNao,
    listaPeriodo,
    listaTipoPessoa,
    listTipoChavePix,
    listTipoConta,
    listaTipoOperacao,
    listaPeriodoOuCiclo,
    listRedeMatriz,
    listRepresentanteResponsavel,
    listSituacaoFornecedor,
    listTipoFidelidade,
    listVendedor,
    listConciliaaoFinanceira,
    listTempoSondaExcedido,
    listDiasUteisOuCorridos,
    listAdminCartao,
    listTipoPagamento,
  };

  return (
    <RedeCredenciadaContext.Provider value={value}>
      {children}
    </RedeCredenciadaContext.Provider>
  );
}
