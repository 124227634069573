import "../Page.css";
import { RedeCredenciadaProvider } from "./context/RedeCredenciadaContext";
import RedeCredenciadaFilter from "./RedeCredenciadaFilter";
import RedeCredenciadaNav from "./navs/RedeCredenciadaNav";
const RedeCredenciada = () => {
  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent w1250 withoutSearch">
          <RedeCredenciadaProvider>
            <RedeCredenciadaFilter />
            <h3>Rede Credenciada</h3>
            <RedeCredenciadaNav />
          </RedeCredenciadaProvider>
        </div>
      </div>
    </div>
  );
};

export { RedeCredenciada };
