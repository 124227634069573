/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  FilterRow,
  Pager,
  Paging,
  MasterDetail,
} from "devextreme-react/data-grid";
import { Form } from "rsuite";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import DetalheProcedimentoMedicoFornecedorGrid from "./DetalheProcedimentoMedicoFornecedorGrid";
import { gridDataSourceProcedimentoMedicoFornecedor } from "../RedeCredenciadaService";

const ProcedimentoMedicoFornecedorGrid = () => {
  const { form, successMessage, warningMessage } = useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [check, setCheck] = useState(false);
  const idAcRedeCredenciada = form?.idAcRedeCredenciada;

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleInsertError(event) {
    const { newData, oldData } = event;
    const required = typeof newData.descricao !== "string";
    const length = !required ? newData.descricao.length > 30 : false;

    event.isValid = !required && !length;

    if (required && !oldData?.descricao)
      return warningMessage("Informe a descrição");
    if (length) return warningMessage("O limite de caracteres é de 30");

    newData.consulta = newData.consulta
      ? newData.consulta
      : oldData?.consulta ?? "N";
    newData.descricao = newData.descricao ?? oldData.descricao;
  }

  function handleRemove({ event }) {
    successMessage("Procedimento médico deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  return (
    <DataGrid
      rowAlternationEnabled={true}
      dataSource={gridDataSourceProcedimentoMedicoFornecedor(
        idAcRedeCredenciada
      )}
      remoteOperations={true}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      onRowInserted={handleInsert}
      onRowRemoved={handleRemove}
      onRowUpdated={handleUpdate}
      onRowValidating={handleInsertError}
      onOptionChanged={handleOptionChange}
      onEditingStart={(e) => setCheck(e.data.consulta === "S")}
      onEditCanceled={() => setCheck(false)}
      onSaved={() => setCheck(false)}
      style={{ margin: 10 }}
    >
      <MasterDetail
        enabled={true}
        component={(data) => DetalheProcedimentoMedicoFornecedorGrid({ data })}
      />
      <Pager
        allowedPageSizes={[7, 15, 30]}
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
      />
      <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
      <Editing mode="row" />
      <FilterRow
        visible={filterOptions.showFilterRow}
        applyFilter={filterOptions.currentFilter}
      />
      <HeaderFilter />

      <Column
        dataField="descricao"
        caption="Descrição"
        filterOperations={false}
      >
        <HeaderFilter groupInterval={100} />
      </Column>
    </DataGrid>
  );
};

export default ProcedimentoMedicoFornecedorGrid;
