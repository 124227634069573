import { Form, TextField } from "@orgystem-ui/components.form";
import React from "react";
import { Button, FlexboxGrid, Loader, Schema } from "rsuite";
import { useAtendimentoContext } from "../context/AtendimentoContext";

import { handleGetSessionStorage } from "../../../services/helpers";
import { handleLoadById } from "../AtendimentoService";

const AtendimentoFilterForm = () => {
  const { auth } = handleGetSessionStorage();
  const {
    form,
    setForm,
    setActiveNav,
    errors,
    setErrors,
    handleChange,
    infoMessage,
    clearFilter,
    setLoading,
    loading,
    initialForm,
  } = useAtendimentoContext();

  const { StringType } = Schema.Types;

  const model = Schema.Model({
    numeroCartao: StringType().isRequired("Número cartão é requerido"),
  });

  const handleFilter = async () => {
    clearFilter();
    setLoading(true);
    handleLoadById(
      "Terminal/DadosTerminalAgenda",
      {
        numeroCartao: form.numeroCartao,
        idAcRedeCredenciadaRepr: auth.idAcRedeCredenciadaRepr,
      },
      "empresa"
    )
      .then((res) => {
        setActiveNav('atendimentos')
        if (res.idAcAdminCartao) {
          let consultaMedica = res?.consultaMedica.map((item) => {
            item.check = false;
            return item;
          });
          let procedimentoMedico = res?.procedimentoMedico.map((item) => {
            item.check = false;
            return item;
          });

          setForm({
            ...form,
            ...res,
            consultaMedica,
            procedimentoMedico,
            senha: "",
            valorTotal: 0,
          });
        } else {
          infoMessage("Nenhuma consulta encontrada");
          setForm({
            ...initialForm,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Form
        formValue={form}
        model={model}
        formError={errors}
        onChange={handleChange}
        onCheck={setErrors}
        onSubmit={(isValid) => {
          if (isValid) {
            handleFilter();
          }
        }}
        fluid
      >
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={8} className="flexGridInput">
            <TextField
              label="Número do cartão"
              name="numeroCartao"
              width={"100%"}
              error={errors.numeroCartao}
              disabled={loading}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={10} className="flexGridInput">
            <TextField
              label="Nome"
              name="nome"
              width={"100%"}
              disabled
              style={{ color: "#000" }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6} className="flexGridInput">
            <Button
              appearance="ghost"
              type="submit"
              style={{ marginTop: 32 }}
              disabled={loading}
              loading={loading}
            >
              🔍 Pesquisar
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form>
    </>
  );
};

export { AtendimentoFilterForm };
