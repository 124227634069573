import { useState } from "react"
import { ConfirmarAgendamentoFilter } from "./ConfirmarAgendamentoFilter"
import { ConfirmarAgendamentoForm } from "./forms/ConfirmarAgendamentoForm"

const ConfirmarAgendamento = () => {
  const [dataSource, setDataSource] = useState([])
  const [active, setActive] = useState('horario')
  const [form, setForm] = useState({
    status: 0,
    acAgendaMedicaHorario: []
  })
  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent w1250 withoutSearch">
          <ConfirmarAgendamentoFilter
            setForm={setForm}
            setDataSource={setDataSource}
            setActive={setActive}
          />
          <h4>Agendamento Médico</h4>
          <ConfirmarAgendamentoForm
            form={form}
            setForm={setForm}
            dataSource={dataSource}
            setDataSource={setDataSource}
            active={active}
            setActive={setActive}
          />
        </div>
      </div>
    </div>
  )
}

export { ConfirmarAgendamento }

