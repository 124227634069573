import {
  CheckboxInput,
  DateInput,
  Form,
  PercentInput,
  SelectInput,
  TextField,
} from "@orgystem-ui/components.form";
import React, { useRef } from "react";
import { useEffect } from "react";
import { Button, FlexboxGrid } from "rsuite";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import "../RedeCredenciada.css";

export default function FornecedorForm() {
  const {
    InitialForm,
    InitialFormFornecedor,
    form,
    setForm,
    formFornecedor,
    setFormFornecedor,
    handleChangeFornecedor,
    errors,
    setErrors,
    listaSimNao,
    listaPeriodoOuCiclo,
    listSituacaoFornecedor,
    listTipoFidelidade,
    listVendedor,
    listConciliaaoFinanceira,
    listTempoSondaExcedido,
    setActiveNav,
    load,
    handleSubmitRedeCredenciadaFornecedor,
  } = useRedeCredenciadaContext();

  // eslint-disable-next-line no-unused-vars
  let formValid = useRef();

  function handleChangeConciliacao(e, data) {
    setFormFornecedor({
      ...formFornecedor,
      conciliacaoFinanceira: data?.conciliacaoFinanceira,
      caminhoGerarArq: data?.caminhoGerarArq,
    });
    setErrors((prevState) => ({
      ...prevState,
      conciliacaoFinanceira: null,
      caminhoGerarArq: null,
    }));
  }

  function handleClearConciliacao() {
    setFormFornecedor({
      ...formFornecedor,
      conciliacaoFinanceira: null,
      caminhoGerarArq: null,
    });
  }

  useEffect(() => {
    setFormFornecedor({
      ...formFornecedor,
      idAcRedeCredenciada: form?.idAcRedeCredenciada,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form formValue={formFornecedor} onChange={handleChangeFornecedor}>
        <FlexboxGrid align="top" style={{ marginTop: "1rem" }}>
          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Dia Pagamento"
              placeholder=""
              name="diaPagamento"
              width={"100%"}
              value={formFornecedor?.diaPagamento}
              error={errors?.diaPagamento}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Dia Pagamento 2"
              placeholder=""
              name="fornecedorDiaPagto2"
              width={"100%"}
              value={formFornecedor?.fornecedorDiaPagto2}
              error={errors?.fornecedorDiaPagto2}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis">
            <PercentInput
              label="Taxa Adm"
              name="percentualAdministracao"
              width={"100%"}
              value={formFornecedor?.percentualAdministracao}
              error={errors?.percentualAdministracao}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione a situação"
              options={listSituacaoFornecedor}
              label="Situação *"
              valueKey="fornecedorSituacao"
              labelKey="descricao"
              state="fornecedorSituacao"
              name="fornecedorSituacao"
              value={formFornecedor?.fornecedorSituacao}
              form={formFornecedor}
              setForm={(newForm) => {
                setFormFornecedor(newForm);
                setErrors({ ...errors, fornecedorSituacao: null });
              }}
              width={"100%"}
              error={errors?.fornecedorSituacao}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o tipo"
              options={listTipoFidelidade}
              label="Tipo Fidelidade *"
              valueKey="tipoFidelidade"
              labelKey="descricao"
              value={formFornecedor?.tipoFidelidade}
              state="tipoFidelidade"
              name="tipoFidelidade"
              form={formFornecedor}
              setForm={(newForm) => {
                setFormFornecedor(newForm);
                setErrors({ ...errors, tipoFidelidade: null });
              }}
              width={"100%"}
              error={errors?.tipoFidelidade}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              label="Fidelidade exclusiva ?"
              valueKey="id"
              labelKey="descricao"
              value={formFornecedor?.fidelidadeExclusiva}
              state="fidelidadeExclusiva"
              name="fidelidadeExclusiva"
              form={formFornecedor}
              setForm={({ id }) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fidelidadeExclusiva: id,
                });
                setErrors((prevState) => ({
                  ...prevState,
                  fidelidadeExclusiva: null,
                }));
              }}
              width={"100%"}
              error={errors?.fidelidadeExclusiva}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              label="Pagto acordo (boleto) ?"
              valueKey="id"
              labelKey="descricao"
              state="fornecedorPgAcordo"
              name="fornecedorPgAcordo"
              value={formFornecedor?.fornecedorPgAcordo}
              form={formFornecedor}
              setForm={({ id }) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorPgAcordo: id,
                });
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorPgAcordo: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornecedorPgAcordo}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item
            colspan={8}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o vendedor"
              options={listVendedor}
              label="Vendedor"
              valueKey="idAcVendedor"
              labelKey="nomeVendedor"
              state="idAcVendedor"
              name="idAcVendedor"
              value={formFornecedor?.idAcVendedor}
              form={formFornecedor}
              setForm={(newForm) => {
                setFormFornecedor(newForm);
                setErrors({ ...errors, idAcVendedor: null });
              }}
              width={"100%"}
              error={errors?.idAcVendedor}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Quantidade de Dias"
              placeholder=""
              name="fornecedorQtdDiasFech"
              width={"100%"}
              value={
                formFornecedor?.fornecedorQtdDiasFech === null
                  ? 1100
                  : formFornecedor?.fornecedorQtdDiasFech
              }
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione periodo ou ciclo"
              options={listaPeriodoOuCiclo}
              label="Filtro Site Periodo ou Ciclo?"
              valueKey="id"
              labelKey="descricao"
              state="fornecedorExtPeriodoOuCiclo"
              name="fornecedorExtPeriodoOuCiclo"
              value={formFornecedor?.fornecedorExtPeriodoOuCiclo}
              form={formFornecedor}
              setForm={({ id }) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorExtPeriodoOuCiclo: id,
                });
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorExtPeriodoOuCiclo: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornecedorExtPeriodoOuCiclo}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="A Partir do Ano"
              placeholder=""
              name="fornecedorPeriodoCicloAno"
              width={"100%"}
              value={formFornecedor?.fornecedorPeriodoCicloAno}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem", paddingLeft: "1rem" }}
          >
            <CheckboxInput
              text1="Aceita Ticket ?"
              text2="Aceita Ticket ?"
              form={formFornecedor}
              value={formFornecedor?.fornecedorAceitaTicket}
              setForm={setFormFornecedor}
              state="fornecedorAceitaTicket"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis">
            <PercentInput
              label="Taxa Credenciamento"
              name="fornecedorTaxaCred"
              width={"100%"}
              value={formFornecedor?.fornecedorTaxaCred}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem", paddingLeft: "1rem" }}
          >
            <CheckboxInput
              text1="Sair no Site ?"
              text2="Sair no Site ?"
              form={formFornecedor}
              value={formFornecedor?.fornecedorSairSite}
              setForm={setFormFornecedor}
              state="fornecedorSairSite"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              label="Sair Fechamento Site ?"
              valueKey="id"
              labelKey="descricao"
              state="fornecedorSairFechamentoSite"
              name="fornecedorSairFechamentoSite"
              value={formFornecedor?.fornecedorSairFechamentoSite}
              form={formFornecedor}
              setForm={({ id }) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorSairFechamentoSite: id,
                });
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorSairFechamentoSite: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornecedorSairFechamentoSite}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={9} className="flexGridInput ellipsis ">
            <TextField
              label="Mensagem Promocional Comprovante de Venda - Linha 1"
              placeholder=""
              name="fornecedorMsgProCupL1"
              width={"100%"}
              value={formFornecedor?.fornecedorMsgProCupL1}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={8} className="flexGridInput ellipsis ">
            <TextField
              label="Mensagem Promocional Comprovante de Venda - Linha 2"
              placeholder=""
              name="fornecedorMsgProCupL2"
              width={"100%"}
              value={formFornecedor?.fornecedorMsgProCupL2}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item
            colspan={6}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem", paddingLeft: "1rem" }}
          >
            <CheckboxInput
              text1="Não valida dados cartão Op. Venda"
              text2="Não valida dados cartão Op. Venda"
              form={formFornecedor}
              value={formFornecedor?.naoValidaDadosOpVenda}
              setForm={setFormFornecedor}
              state="naoValidaDadosOpVenda"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Ativar Fechamento Min"
              text2="Ativar Fechamento Min"
              form={formFornecedor}
              value={formFornecedor?.fornecedorLimiteCtaPagar}
              setForm={setFormFornecedor}
              state="fornecedorLimiteCtaPagar"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione a conciliação"
              options={listConciliaaoFinanceira}
              label="Conciliação Financeira"
              valueKey="conciliacaoFinanceira"
              labelKey="descricao"
              state="conciliacaoFinanceira"
              name="conciliacaoFinanceira"
              value={formFornecedor?.conciliacaoFinanceira}
              form={formFornecedor}
              setForm={setFormFornecedor}
              // setForm={(newForm) => {
              //   setFormFornecedor(newForm);
              //   setErrors({ ...errors, conciliacaoFinanceira: null });
              // }}
              onSelect={handleChangeConciliacao}
              onClean={handleClearConciliacao}
              width={"100%"}
              error={errors?.conciliacaoFinanceira}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="TEF - Tempo máximo sonda"
              placeholder=""
              name="fornSondaTempoMaximo"
              width={"100%"}
              value={formFornecedor?.fornSondaTempoMaximo}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={6}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder=""
              options={listTempoSondaExcedido}
              label="TEF - Ação tempo máximo de sonda excedido"
              valueKey="fornSondaAcaoTempoExc"
              labelKey="descricao"
              state="fornSondaAcaoTempoExc"
              name="fornSondaAcaoTempoExc"
              value={formFornecedor?.fornSondaAcaoTempoExc}
              form={formFornecedor}
              setForm={(newForm) => {
                setFormFornecedor(newForm);
                setErrors({ ...errors, fornSondaAcaoTempoExc: null });
              }}
              width={"100%"}
              error={errors?.fornSondaAcaoTempoExc}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item colspan={9} className="flexGridInput ellipsis ">
            <TextField
              label="Pasta gerar arquivo (conciliação financeira)"
              placeholder=""
              name="caminhoGerarArq"
              width={"100%"}
              value={formFornecedor?.caminhoGerarArq}
              readOnly
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis">
            <PercentInput
              label="% ISS Forn"
              name="fornecedorPercIss"
              width={"100%"}
              value={formFornecedor?.fornecedorPercIss}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis">
            <DateInput
              state="fornecedorDataInclusao"
              name="fornecedorDataInclusao"
              label="Data Inclusão *"
              width={"100%"}
              setForm={setFormFornecedor}
              form={formFornecedor}
              value={
                formFornecedor?.fornecedorDataInclusao
                  ? new Date(formFornecedor?.fornecedorDataInclusao)
                  : null
              }
              onSelect={(e) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorDataInclusao: e,
                });
                setErrors({ ...errors, fornecedorDataInclusao: null });
              }}
              onClean={(e) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorDataInclusao: "",
                });
              }}
              error={errors?.fornecedorDataInclusao}
              readOnly
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis">
            <DateInput
              state="dataExclusao"
              label="Data Exclusão"
              width={"100%"}
              setForm={setFormFornecedor}
              form={formFornecedor}
              value={
                formFornecedor?.fornecedorDataExclusao
                  ? new Date(formFornecedor?.fornecedorDataExclusao)
                  : null
              }
              onSelect={(e) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorDataExclusao: e,
                });
                setErrors({ ...errors, fornecedorDataExclusao: null });
              }}
              onClean={(e) => {
                setFormFornecedor({
                  ...formFornecedor,
                  fornecedorDataExclusao: "",
                });
              }}
              error={errors?.dataExclusao}
              readOnly
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Funcionamento 24hrs ?"
              text2="Funcionamento 24hrs ?"
              form={formFornecedor}
              value={formFornecedor?.funcionamento24Hrs}
              setForm={setFormFornecedor}
              state="funcionamento24Hrs"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="middle">
          <FlexboxGrid.Item colspan={5} className="flexGridInput ellipsis ">
            <TextField
              label="Id - (Cod Indicação Parceiro)"
              placeholder=""
              name="idAcRedeCredenciada"
              width={"100%"}
              value={Form?.idAcRedeCredenciada}
              readOnly
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis flexCheckBox"
          >
            <CheckboxInput
              text1="Aceita outros valores?"
              text2="Aceita outros valores?"
              form={formFornecedor}
              value={formFornecedor?.aceitaValores}
              setForm={setFormFornecedor}
              state="aceitaValores"
              width={"100%"}
              style={{ marginTop: "1.5rem" }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form>
      <div className="addingButton" style={{ marginTop: "1rem" }}>
        <>
          {form?.idAcRedeCredenciada ? (
            <>
              <Button
                type="button"
                appearance="ghost"
                color="orange"
                className="pageButton"
                onClick={() => {
                  setForm(InitialForm);
                  setFormFornecedor(InitialFormFornecedor);
                  setActiveNav("redeCredenciada");
                }}
              >
                CANCELAR EDIÇÃO
              </Button>
              <Button
                type="button"
                appearance="primary"
                color="blue"
                className="pageButton"
                loading={load}
                onClick={() => {
                  handleSubmitRedeCredenciadaFornecedor();
                }}
              >
                ALTERAR
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                appearance="ghost"
                color="orange"
                className="pageButton"
                onClick={() => {
                  setForm(InitialForm);
                  setFormFornecedor(InitialFormFornecedor);
                  setActiveNav("redeCredenciada");
                }}
              >
                LIMPAR
              </Button>

              <Button
                type="button"
                appearance="primary"
                color="blue"
                className="pageButton"
                loading={load}
                onClick={() => {
                  handleSubmitRedeCredenciadaFornecedor();
                }}
              >
                SALVAR
              </Button>
            </>
          )}
        </>
      </div>
    </>
  );
}
