import { FilterModal } from "@orgystem-ui/components.filter-modal";
import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from "devextreme-react/data-grid";
import React, { useRef, useState } from "react";
import { useRedeCredenciadaContext } from "./context/RedeCredenciadaContext";
import {
  dataSourceRedeCredenciada,
  handleLoadFornecedor,
} from "./RedeCredenciadaService";

export default function RedeCredenciadaFilter() {
  const {
    setForm,
    setFormFornecedor,
    successMessage,
    setActiveNav,
    setErrors,
  } = useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [toggle, setToggle] = useState(false);

  const gridRef = useRef(null);
  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleRemove({ data }) {
    successMessage("Deletado com sucesso");
  }

  const refreshGrid = () => {
    return gridRef?.current?._instance?.beginCustomLoading();
  };

  const handleGetFornecedor = (id) => {
    handleLoadFornecedor(id).then(({ data }) => {
      setFormFornecedor({
        ...data,
        aceitaValores: data?.fornecedorAceitaValores === "S" ? true : false,
      });
    });
  };

  return (
    <FilterModal
      toggle={toggle}
      setToggle={setToggle}
      full
      style={{ paddingLeft: "30px" }}
    >
      <>
        <DataGrid
          dataSource={dataSourceRedeCredenciada}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          columnAutoWidth={true}
          onOptionChanged={handleOptionChange}
          onRowRemoved={handleRemove}
          rowAlternationEnabled={true}
          showRowLines={true}
          ref={gridRef}
        >
          <Editing mode="row" useIcons allowDeleting allowUpdating />
          <Sorting mode="single" />
          <Pager
            allowedPageSizes={[7, 15, 30]}
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
          />
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
          <FilterRow
            visible={filterOptions.showFilterRow}
            applyFilter={filterOptions.currentFilter}
          />
          <HeaderFilter />
          <Column
            dataField="codigo"
            caption="Código"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="10%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="digito"
            caption="Digito"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="5%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="razaoSocial"
            caption="Nome / Razão Social"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="35%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="cnpj"
            caption="CPF / CNPJ"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="15%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="telefone"
            caption="Telefone"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="15%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>
          <Column
            dataField="usuario"
            caption="Adicionado por"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="20%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column type="buttons" width={60}>
            <Button
              hint="Editar"
              visible
              icon="edit"
              onClick={(e) => {
                refreshGrid();
                const { data } = e?.row;
                setTimeout(() => {
                  setActiveNav("redeCredenciada");
                  setForm({
                    ...data,
                  });
                  setErrors({});
                  setToggle(!toggle);
                  handleGetFornecedor(data?.idAcRedeCredenciada);
                }, 600);
              }}
            />
            <Button name="delete" visible hint="Deletar" icon="trash" />
          </Column>
        </DataGrid>
      </>
    </FilterModal>
  );
}
