/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  FilterRow,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import { Checkbox } from "rsuite";

import { gridDataSource } from "../ProcedimentoMedicoService";

const ProcedimentoMedicoGrid = ({ successMessage, warningMessage }) => {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [check, setCheck] = useState(false);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  const handleSelectionChanged = (event) => {
    event.data.consulta = event.data.consulta === "S" ? "N" : "S";
    event.setValue(event.data.consulta);
    setCheck(!check);
  };

  const checkRender = (e) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={e.data.consulta === "S"}
          style={{ margin: -10 }}
          disabled={true}
        />
      </div>
    );
  };
  const editCellRender = (e) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={check}
          style={{ margin: -10 }}
          onCheckboxClick={() => handleSelectionChanged(e)}
        />
      </div>
    );
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleInsertError(event) {
    const { newData, oldData } = event;
    const required = typeof newData?.descricao === "string";
    const length = required ? newData?.descricao.length > 150 : false;
    const hasCod = !!newData?.codigo || !!oldData?.codigo

    event.isValid = required && !length && hasCod;

    if (!required && !oldData?.descricao)
      return warningMessage("Informe a descrição");
    if (length) return warningMessage("O limite de caracteres é de 150");
    if (!hasCod)
      return warningMessage("Informe o codigo");

    newData.consulta = newData.consulta
      ? newData?.consulta
      : oldData?.consulta ?? "N";
    newData.descricao = newData?.descricao ?? oldData?.descricao;
    newData.codigo = newData?.codigo ?? oldData?.codigo;
  }

  function handleRemove({ event }) {
    successMessage("Procedimento médico deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  return (
    <>
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={gridDataSource}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onRowInserted={handleInsert}
        onRowRemoved={handleRemove}
        onRowUpdated={handleUpdate}
        onRowValidating={handleInsertError}
        onOptionChanged={handleOptionChange}
        onEditingStart={(e) => setCheck(e.data.consulta === "S")}
        onEditCanceled={() => setCheck(false)}
        onSaved={() => setCheck(false)}
      >
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <Editing mode="row" allowAdding allowDeleting allowUpdating useIcons />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />

        <Column
          dataField="codigo"
          caption="Código"
          filterOperations={false}
          width="15%"
        >
          <HeaderFilter groupInterval={100} />
          <RequiredRule />
        </Column>
        <Column
          dataField="descricao"
          caption="Descrição"
          filterOperations={false}
          width="65%"
        >
          <HeaderFilter groupInterval={100} />
        </Column>

        <Column
          alignment="center"
          dataField="consulta"
          caption={"Consulta"}
          cellRender={checkRender}
          editCellRender={editCellRender}
          width={"20%"}
        ></Column>
      </DataGrid>
    </>
  );
};

export default ProcedimentoMedicoGrid;
