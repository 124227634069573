import api from "../../services/api";
import CustomStore from "devextreme/data/custom_store";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";
import qs from "qs";

export function handleLoadGrupo1(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    nomeUsuario: auth.nomeUsuario,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Empresa/GetGrupo1?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export function handleLoadGrupo2(loadOptions, idGrupo1) {
  const { auth } = handleGetSessionStorage();
  const params = {
    nomeUsuario: auth.nomeUsuario,
    idGrupo1: idGrupo1,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Empresa/GetGrupo2?${qs.stringify(newParams)}`;
  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export function handleLoadEmpresa(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    // idGrupo2: idGrupo2,
    nomeUsuario: auth.nomeUsuario,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Empresa/GetEmpresa?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export const grupo1Store = {
  store: new CustomStore({
    key: "idGrupo1",
    load: (loadOptions) => handleLoadGrupo1(loadOptions),
  }),
};

export function getGrupo2Store({ idGrupo1 }) {
  return {
    store: new CustomStore({
      key: "idGrupo2",
      load: (loadOptions) => handleLoadGrupo2(loadOptions, idGrupo1),
    }),
  };
}

export function getEmpresaStore() {
  return {
    store: new CustomStore({
      key: "idEmpresa",
      load: (loadOptions) => handleLoadEmpresa(loadOptions),
    }),
  };
}
