import React, { createContext, useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { handleGetSessionStorage } from "../../../services/helpers";
import { handleLoadById } from "../AtendimentoService";

const AtendimentoContext = createContext();

function useAtendimentoContext() {
  return useContext(AtendimentoContext);
}

const AtendimentoProvider = ({ children }) => {
  const { addToast } = useToasts();
  const { auth, company } = handleGetSessionStorage();
  const initialForm = {
    empresa: company,
    numeroCartao: "",
    valorTotal: 100,
    outrosValores: 0,
    numeroParcelas: 0,
    senha: "",
    idAcRedeCredenciadaRepr: auth.idAcRedeCredenciadaRepr,
    consultaMedica: [],
    procedimentoMedico: [],
    nome: "",
  };

  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [activeNav, setActiveNav] = useState("atendimentos");
  const [numeroParcelas, setNumeroParcelas] = useState(0);
  const [visible, setVisible] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [aceitaValores, setAceitaValores] = useState(false);

  function handleChange(value) {
    setForm({ ...form, ...value });
  }

  function clearFilter() {
    setDisabled(false);
    setForm(initialForm);
    setNumeroParcelas(0);
    setActiveNav("consulta");
    setErrors({});
    setTransaction(false);
    setData({});
  }

  const successMessage = (message, fn = () => {}) => {
    addToast(
      message,
      {
        appearance: "success",
        autoDismiss: true,
      },
      fn
    );
  };

  const warningMessage = (message) => {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  };

  const infoMessage = (message) => {
    addToast(message, {
      appearance: "info",
      autoDismiss: true,
    });
  };

  const errorMessage = (message) => {
    addToast(message, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    handleLoadById("RedeCredenciada/FornecedorRedeCredenciada", {
      idAcRedeCredenciada: auth.idAcRedeCredenciadaRepr,
    })
      .then((data) => {
        setAceitaValores(data?.fornecedorAceitaValores === "S" ? true : false);
      })
      .catch(() => setAceitaValores(false));
  }, [auth.idAcRedeCredenciadaRepr]);

  const value = {
    initialForm,
    form,
    setForm,
    errors,
    setErrors,
    handleChange,
    successMessage,
    warningMessage,
    errorMessage,
    activeNav,
    setActiveNav,
    infoMessage,
    numeroParcelas,
    setNumeroParcelas,
    clearFilter,
    visible,
    setVisible,
    transaction,
    setTransaction,
    loadingButton,
    setLoadingButton,
    loading,
    setLoading,
    data,
    setData,
    disabled,
    setDisabled,
    aceitaValores,
  };
  return (
    <AtendimentoContext.Provider value={value}>
      {children}
    </AtendimentoContext.Provider>
  );
};

export { useAtendimentoContext, AtendimentoProvider };
