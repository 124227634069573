/* eslint-disable no-unused-vars */
import Nav from "@rsuite/responsive-nav";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import FornecedorForm from "../forms/FornecedorForm";
import RedeCredenciadaForm from "../forms/RedeCredenciadaForm";
import EnderecoGrid from "../grids/EnderecoGrid";
import ProcedimentoMedicoFornecedorGrid from "../grids/ProcedimentoMedicoFornecedorGrid";
import "../RedeCredenciada.css";
import FornecedorNav from "./FornecedorNav";

export default function RedeCredenciadaNav() {
  const {
    form,
    activeNav,
    setActiveNav
  } = useRedeCredenciadaContext();

  const CustomNav = ({ active, onSelect, id, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="redeCredenciada">Rede Credenciada</Nav.Item>
        {form?.idAcRedeCredenciada ?
          <>
            <Nav.Item eventKey="endereco">Endereço</Nav.Item>
            <Nav.Item eventKey="fornecedor">Fornecedor</Nav.Item>
            {/* <Nav.Item eventKey="contatos">Contatos</Nav.Item> */}
            <Nav.Item eventKey="procedimentosMedicos">
              Procedimentos Médicos
            </Nav.Item>
          </>
          : null
        }
      </Nav>
    );
  };

  const SwitchNavs = () => {
    switch (activeNav) {
      case "redeCredenciada":
        return (
          <>
            <RedeCredenciadaForm />
          </>
        );
      case "endereco":
        return (
          <>
            <EnderecoGrid />
          </>
        );
      case "fornecedor":
        return (
          <>
            <FornecedorForm />
            <FornecedorNav />
          </>
        );
      // case "contatos":
      //   return (
      //     <>
      //       <h1>Aguardando...</h1>
      //     </>
      //   );
      case "procedimentosMedicos":
        return (

          <ProcedimentoMedicoFornecedorGrid />

        );
      default:
        break;
    }
  };

  return (
    <div
      className="pageContainer"
    // style={{ position: openModal ? "absolute" : "relative" }}
    >
      <CustomNav
        appearance="tabs"
        moreText={"Rede Credenciada"}
        active={activeNav}
        onSelect={activeKey => setActiveNav(activeKey)}
        id={form?.idAcRedeCredenciada}
        width="100%"
        style={{ marginTop: 15 }}
      />
      {SwitchNavs()}

    </div>
  );
}
