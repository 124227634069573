/* eslint-disable no-unused-vars */
import Nav from "@rsuite/responsive-nav";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import RamoAtividadeGrid from "../grids/RamoAtividadeGrid";
import TipoCartaoGrid from "../grids/TipoCartaoGrid";
import "../RedeCredenciada.css";

export default function FornecedorNav() {
  const {
    form,
    activeFornecedorNav,
    setActiveFornecedorNav,
  } = useRedeCredenciadaContext();

  const CustomNav = ({ active, onSelect, id, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="ramoAtividade">Ramo Atividade</Nav.Item>
        <Nav.Item eventKey="tipoCartao">Tipo Cartão</Nav.Item>

      </Nav>
    );
  };

  const SwitchNavs = () => {
    switch (activeFornecedorNav) {
      case "ramoAtividade":
        return (
          <>
            <RamoAtividadeGrid />
          </>
        );
      case "tipoCartao":
        return (
          <>
            <TipoCartaoGrid />
          </>
        );
      default:
        break;
    }
  };

  return (
    <div
      className="pageContainer"
    // style={{ position: openModal ? "absolute" : "relative" }}
    >
      <CustomNav
        appearance="tabs"
        moreText={"Ramo Atividade"}
        active={activeFornecedorNav}
        onSelect={activeKey => setActiveFornecedorNav(activeKey)}
        id={form?.idAcRedeCredenciada}
        width="100%"
        style={{ marginTop: 15 }}
      />
      {SwitchNavs()}

    </div>
  );
}
