import React, { useState } from "react";
import { Column, DataGrid, Editing } from "devextreme-react/data-grid";
import { BiPlus } from "react-icons/bi";
import { Loader } from "rsuite";
import {
  handleErrorForm,
  handleGetSessionStorage,
} from "../../../services/helpers";
import { handlePostAndPut } from "../AtendimentoService";
import { useAtendimentoContext } from "../context/AtendimentoContext";

const AtendimentosGrid = () => {
  const { form, setForm, disabled, errorMessage, successMessage, transaction } =
    useAtendimentoContext();
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    if (e.parentType === "dataRow" && e.dataField === "check") {
      e.editorOptions.onValueChanged = (args) => {
        let newForm = form.consultaMedica.map((item, i) => {
          if (e.row.rowIndex === i) {
            item.check = !item.check;
          } else {
            item.check = false;
          }
          return item;
        });
        setForm({ ...form, consultaMedica: [...newForm] });
      };
    }
  }
  function handleGenerate() {
    const { company, auth, user } = handleGetSessionStorage();
    setLoading(true);
    const record = {
      empresa: company,
      idAcUsuario: form?.idAcUsuario,
      idAcUsuarioCartao: form?.idAcUsuarioCartao,
      idAcRedeCredenciada: auth?.idAcRedeCredenciadaRepr,
      comValor: false,
      numeroCartao: form?.numeroCartao,
      usuario: user,
    };
    handlePostAndPut(record, "Terminal/GeraConsultaAgendamento", "POST")
      .then((res) => {
        let consultaMedica = res?.consultaMedica.map((item) => {
          item.check = false;
          return item;
        });
        let procedimentoMedico = res?.procedimentoMedico.map((item) => {
          item.check = false;
          return item;
        });
        setForm({
          ...form,
          ...res,
          consultaMedica,
          procedimentoMedico,
        });
        successMessage("Atendimento criado com sucesso!");
        // setVisible(false);
      })
      .catch((err) => errorMessage(handleErrorForm(err)))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div className="addingButton">
        <button
          className="adding-button-content"
          onClick={handleGenerate}
          disabled={loading || transaction}
        >
          {
            loading ?
              <Loader size="xs" style={{ padding: '2px' }} />
              :
              <BiPlus size={20} color="#333" />
          }
        </button>
      </div>
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={form.consultaMedica}
        remoteOperations={true}
        showBorders={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onEditorPreparing={handleChange}
        disabled={disabled}
      >
        <Editing mode="cell" allowUpdating={true} />

        <Column
          dataField="data"
          caption="Data"
          dataType="date"
          format="dd/MM/yyyy"
          filterOperations={true}
          allowEditing={false}
          allowSorting={false}
          alignment="center"
          width="40%"
        ></Column>

        <Column
          alignment="center"
          dataField="hora"
          caption="Hora"
          allowEditing={false}
          filterOperations={false}
          allowSorting={false}
          width="40%"
        ></Column>
        <Column
          alignment="center"
          dataField="check"
          caption={"Selecionar"}
          allowSorting={false}
          width="20%"
        ></Column>
      </DataGrid>
    </>
  );
};

export { AtendimentosGrid };

