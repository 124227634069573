import api from "../../services/api";
import apiXHR from "../../services/apiXHR";
import {
  handleError,
  handleErrorNew,
  handleGetSessionStorage,
  handleParams
} from "../../services/helpers";
import qs from "qs";
import CustomStore from "devextreme/data/custom_store";

export function handleLoad(loadOptions, url, form) {
  const { company } = handleGetSessionStorage();

  const params = {
    idEmpresa: company,
    idImportarArquivoUsuario: form ? form?.id : undefined,
    idPessoaF: form ? form?.idClientes : undefined
  };
  const newParams = loadOptions ? handleParams(params, loadOptions) : params;
  const urlComplete = `${url}${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount
      };
    })
    .catch(err => handleError(err, "Erro ao carregar"));
}

export function postImportarArquivo(form, file) {
  const { user, company } = handleGetSessionStorage();
  var data = new FormData();
  data.append(
    "import",
    JSON.stringify({
      idAcRedeCredenciada: form.idAcRedeCredenciada,
      empresa: company,
      situacaoUsuario: form?.situacaoUsuario,
      gerarCartao: form?.gerarCartao,
      situacaoUsuarioCartao: form?.situacaoUsuarioCartao,
      tipoCartao: form?.tipoCartao,
      tipoSegmento: form?.tipoSegmento,
      maximoParcelas: parseInt(form?.maximoParcelas),
      limiteParcelamento: parseFloat(form?.limiteParcelamento),
      vencimentoMes: form?.vencimentoMes?.toString(),
      vencimentoAno: form?.vencimentoAno?.toString(),
      usuario: user,
    })
  );
  data.append("arquivo", file);
  const url = "/Usuario/ImportacaoUsuarios";
  return new Promise((resolve, reject) => {
    apiXHR
      .post(url, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(handleErrorNew(err));
      });
  });
}

export function handlePostAndPut(data, url, method) {
  return new Promise((resolve, reject) => {
    api({ method, url, data })
      .then(res => {
        const { data, totalCount } = res?.data;
        resolve({
          data,
          totalCount
        });
      })
      .catch(error => {
        reject(handleError(error));
      });
  });
}

// eslint-disable-next-line no-unused-vars
function handleLoadById(loadOptions, url, nameId) {
  const { company } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;
  const params = {
    [nameId]: id ? id : 0,
    idEmpresa: company,
    skip: 0,
    take: 1
  };
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount
      };
    })
    .catch(err => handleError(err, "Erro ao carregar"));
}

export function handleLoadList(url) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    skip: 0,
    take: 1000
  };
  // const newParams = handleParams(params, loadOptions);
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount
      };
    })
    .catch(err => handleError(err, "Erro ao carregar"));
}

export function handleGet(url, otherParams = {}) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    ...otherParams
  };
  // const newParams = handleParams(params, loadOptions);
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then(res => res.data)
    .catch(err => handleError(err, "Erro ao carregar"));
}

export function handleLoadUtilizacao() {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    skip: 0,
    take: 1000
  };
  // const newParams = handleParams(params, loadOptions);
  const urlComplete = `/CoUtilizacao/GetPorEmpresa?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount
      };
    })
    .catch(err => handleError(err, "Erro ao carregar"));
}
export function handleLoadSubUtilizacao(loadOptions) {
  const { company } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;

  const params = {
    idCoUtilizacao: id,
    idEmpresa: company,
    skip: 0,
    take: 1000
  };

  const newParams = handleParams(params, loadOptions);
  const url = `/CoSubutilizacao/GetPorEmpresa?${qs.stringify(newParams)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(res => {
        /* const { data, totalCount } = res.data; */
        /* return {
        data,
        totalCount,
      }; */
        resolve(res.data);
      })
      .catch(
        error =>
          reject(error) /* (err) => handleError("Erro ao carregar", err) */
      );
  });
}

//DataSource

//Pessoa
/* export const dataSourceCaPessoa = {
  store: new CustomStore({
    key: "idCaPessoa",
    load: (loadOptions) => handleLoadCaPessoa(loadOptions),
  }),
}; */
export const dataSourceClientes = {
  store: new CustomStore({
    key: "cnpj",
    load: loadOptions => handleLoad(loadOptions, `/RedeCredenciada/Clientes?`)
  })
};

export function handleLoadClientes(loadOptions) {
  const { company } = handleGetSessionStorage();
  const params = {
    idEmpresa: company,
    skip: 0,
    take: 100,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/RedeCredenciada/Clientes?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount
      };
    })
    .catch(err => handleError(err, "Erro ao carregar"));
}

export const listaDeMeses = [
  {
    id: 1,
    descricao: "01 - Janeiro"
  },
  {
    id: 2,
    descricao: "02 - Fevereiro"
  },
  {
    id: 3,
    descricao: "03 - Março"
  },
  {
    id: 4,
    descricao: "04 - Abril"
  },
  {
    id: 5,
    descricao: "05 - Maio"
  },
  {
    id: 6,
    descricao: "06 - Junho"
  },
  {
    id: 7,
    descricao: "07 - Julho"
  },
  {
    id: 8,
    descricao: "08 - Agosto"
  },
  {
    id: 9,
    descricao: "09 - Setembro"
  },
  {
    id: 10,
    descricao: "10 - Outubro"
  },
  {
    id: 11,
    descricao: "11 - Novembro"
  },
  {
    id: 12,
    descricao: "12 - Dezembro"
  }
];
