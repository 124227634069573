import React, { useRef } from "react";
import { Modal } from "rsuite";
import { ImPrinter } from "react-icons/im";
import "./Receipt.css";
import ReactToPrint from "react-to-print";
import { formatDate } from "../../services/helpers";

const Receipt = ({ toggleModal, visible, height, width, data }) => {
  const componentRef = useRef(null);
  const checkToggle = () => {
    toggleModal();
  };

  const printContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const printTrigger = React.useCallback(() => {
    return (
      <div className="container-icon" title="Imprimir">
        <ImPrinter className="printer-icon"></ImPrinter>
      </div>
    );
  }, []);
  return (
    <Modal
      open={visible}
      onClose={checkToggle}
      style={{
        height,
        width: width,
        maxWidth: "100%",
      }}
    >
      <ReactToPrint trigger={printTrigger} content={printContent} />
      <Modal.Header style={{ display: "block" }}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body ref={componentRef} style={{ overflow: "hidden" }}>
        <table className="printer-ticket">
          <thead>
            <tr>
              <th className="title" colspan="3">
                Comprovante
              </th>
            </tr>
            <tr>
              <th colspan="3">{data?.date}</th>
            </tr>
            <tr>
              <th colspan="3">
                {data?.nome}
                <br />
                Número do cartão: {data?.numeroCartao}
                <br />
                Número de autorização: {data?.codAutorizacao ?? ''}
              </th>
            </tr>
          </thead>
          <tbody>
            <td colspan="3" style={{ textAlign: "center" }}>
              <b>PROCEDIMENTOS</b>
            </td>
            <tr className="sup ttu p--0">
              <td colspan="2">
                <b>Procedimento</b>
              </td>
              <td align="right">
                <b>Valor</b>
              </td>
            </tr>
            {data?.procedimentoMedico?.map((item) => (
              <>
                <tr className="ttu">
                  <td colspan="2">{item?.descricao}</td>
                  <td align="right">R$ {item?.valor.toFixed(2)}</td>
                </tr>
              </>
            ))}
          </tbody>
          <tbody>
            <tr className="sup ttu">
              <td colspan="2"></td>
              <td align="right" style={{ paddingTop: 30 }}>
                <b>Total</b>
              </td>
            </tr>
            <tr
              className="ttu"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              <td colspan="2"></td>

              <td align="right">
                R$ {data?.valorTotal?.toFixed(2)}
                {data?.valorTotal > 0 ?
                  (data?.numeroParcelas === 1
                    ? " - 1 Parcela"
                    : ` - ${data?.numeroParcelas} Parcelas`) : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export { Receipt };
