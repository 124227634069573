import {
  CheckboxInput,
  Form,
  MaskInput,
  PercentInput,
  SelectInput,
  TextField,
  TextFieldCurrency,
} from "@orgystem-ui/components.form";
import React, { useRef } from "react";
import { Button, FlexboxGrid } from "rsuite";
import "../RedeCredenciada.css";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";

export default function RedeCredenciadaForm() {
  const {
    form,
    setForm,
    setFormFornecedor,
    handleChange,
    handleValueChange,
    errors,
    setErrors,
    listaSimNao,
    listaTipoPessoa,
    listTipoChavePix,
    listBanco,
    listTipoConta,
    listaTipoOperacao,
    listRedeMatriz,
    listRepresentanteResponsavel,
    InitialForm,
    initialFormFornecedor,
    setActiveNav,
    handleSubmitRedeCredenciada,
    load,
  } = useRedeCredenciadaContext();

  // eslint-disable-next-line no-unused-vars
  let formValid = useRef();

  return (
    <>
      <Form formValue={form} onChange={handleChange}>
        <FlexboxGrid align="top" style={{ marginTop: "1rem" }}>
          <FlexboxGrid.Item
            colspan={2}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
          >
            <CheckboxInput
              text1="Cliente"
              text2="Cliente"
              form={form}
              value={form?.cliente}
              setForm={setForm}
              state="cliente"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={2}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Fornecedor"
              text2="Fornecedor"
              form={form}
              value={form?.fornecedor}
              setForm={setForm}
              state="fornecedor"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              valueKey="id"
              labelKey="descricao"
              label="Administrativo ?"
              value={form?.fornecedorAdministrativo}
              state="fornecedorAdministrativo"
              name="fornecedorAdministrativo"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, fornecedorAdministrativo: id });
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorAdministrativo: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornecedorAdministrativo}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Senha Internet"
              placeholder="x0x0x0"
              name="senha"
              width={"100%"}
              value={form?.senha}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Representante"
              text2="Representante"
              form={form}
              value={form?.representante}
              setForm={setForm}
              state="representante"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={2} className="flexGridInput ellipsis ">
            <TextField
              label="Codigo"
              placeholder="0000"
              name="codigo"
              width={"100%"}
              value={form?.codigo ?? ""}
              readOnly
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            colspan={2}
            className="flexGridInput ellipsis "
            style={{ marginLeft: "-1.2rem" }}
          >
            <TextField
              label="Digito"
              placeholder="00"
              name="digito"
              width={"100%"}
              value={form?.digito ?? ""}
              readOnly
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Nr. Estab. Universal"
              placeholder=""
              name="nrEstabelecimentoUniversal"
              width={"100%"}
              value={form?.nrEstabelecimentoUniversal ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Tipo"
              options={listaTipoPessoa}
              label="Tipo Pessoa *"
              valueKey="id"
              labelKey="descricao"
              state="tipoPessoa"
              name="tipoPessoa"
              value={form?.tipoPessoa}
              form={form}
              setForm={(newForm) => {
                setForm(newForm);
                setErrors((prevState) => ({
                  ...prevState,
                  tipoPessoa: null,
                }));
              }}
              width={"100%"}
              error={errors?.tipoPessoa}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item colspan={8} className="flexGridInput ellipsis ">
            <TextField
              label="Nome / Razão Social"
              placeholder=""
              name="razaoSocial"
              width={"100%"}
              value={form?.razaoSocial ?? ""}
              onChange={(value) => {
                setForm((state) => ({
                  ...state,
                  razaoSocial: value,
                }));
                setErrors((state) => ({
                  ...state,
                  razaoSocial: null,
                }));
              }}
              error={errors?.razaoSocial}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={5} className="flexGridInput ellipsis ">
            <TextField
              label="Apelido / Fantasia"
              placeholder=""
              name="nomeFantasia"
              width={"100%"}
              value={form?.nomeFantasia ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Rede Matriz ?"
              text2="Rede Matriz ?"
              form={form}
              value={form?.redeMatriz}
              setForm={setForm}
              state="redeMatriz"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={8}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            {form?.redeMatriz === true ? (
              <TextField
                label="Nome Rede Matriz"
                placeholder=""
                name="idRedeCredenciadaMatriz"
                width={"100%"}
                value={
                  form?.idAcRedeCredenciada && form?.idAcRedeCredenciadaMatriz
                    ? form?.idAcRedeCredenciadaMatriz
                    : form?.razaoSocial
                }
                readOnly={form?.redeMatriz === true}
              />
            ) : null}
            {form?.redeMatriz === false ? (
              <SelectInput
                placeholder="Selecione a Rede Matriz"
                options={listRedeMatriz}
                valueKey="idAcRedeCredenciadaMatriz"
                labelKey="nomeFantasia"
                label="Nome Rede Matriz"
                value={form?.idAcRedeCredenciadaMatriz}
                state="idAcRedeCredenciadaMatriz"
                name="idAcRedeCredenciadaMatriz"
                form={form}
                setForm={(newForm) => {
                  setForm(newForm);
                  setErrors((prevState) => ({
                    ...prevState,
                    idAcRedeCredenciadaMatriz: null,
                  }));
                }}
                width={"100%"}
                error={errors?.idAcRedeCredenciadaMatriz}
                readOnly={form?.redeMatriz === true}
              />
            ) : null}
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          {form?.tipoPessoa === 2 ? (
            <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
              <MaskInput
                form={form}
                setForm={setForm}
                label="CPF / CNPJ"
                name="cnpj"
                state="cnpj"
                value={form?.cnpj}
                mask={{
                  blocks: [2, 3, 3, 4, 2],
                  delimiters: [".", ".", "/", "-"],
                  numericOnly: true,
                }}
                error={errors?.cnpj}
                invalid={errors?.cnpj}
                width={"100%"}
              />
            </FlexboxGrid.Item>
          ) : null}

          {form?.tipoPessoa === 1 ? (
            <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
              <MaskInput
                form={form}
                setForm={setForm}
                label="CPF / CNPJ"
                name="cnpj"
                state="cnpj"
                value={form?.cnpj}
                mask={{
                  blocks: [3, 3, 3, 2],
                  delimiters: [".", ".", "-"],
                  numericOnly: true,
                }}
                error={errors?.cnpj}
                invalid={errors?.cnpj}
                width={"100%"}
              />
            </FlexboxGrid.Item>
          ) : null}

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="RG / Insc. Estadual"
              placeholder=""
              name="inscricaoEstadual"
              width={"100%"}
              value={form?.inscricaoEstadual ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="Inscrição Municipal"
              placeholder=""
              name="inscricaoMunicipal"
              width={"100%"}
              value={form?.inscricaoMunicipal ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Telefone"
              placeholder=""
              name="telefone"
              width={"100%"}
              value={form?.telefone ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Fax"
              placeholder=""
              name="fax"
              width={"100%"}
              value={form?.fax ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={6} className="flexGridInput ellipsis ">
            <TextField
              label="Contato"
              placeholder=""
              name="contato"
              width={"100%"}
              value={form?.contato ?? ""}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Celular"
              placeholder=""
              name="celular"
              width={"100%"}
              value={form?.celular ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={6} className="flexGridInput ellipsis ">
            <TextField
              label="Email"
              placeholder=""
              name="email"
              width={"100%"}
              value={form?.email ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={6} className="flexGridInput ellipsis ">
            <TextField
              label="Email 2"
              placeholder=""
              name="email2"
              width={"100%"}
              value={form?.email2 ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={6} className="flexGridInput ">
            {form?.representante === true ? (
              <TextField
                label="Representante Responsável"
                placeholder=""
                name="idAcRedeCredenciadaRepr"
                width={"100%"}
                value={
                  form?.idAcRedeCredenciada && form?.idAcRedeCredenciadaRepr
                    ? form?.idAcRedeCredenciadaRepr
                    : form?.razaoSocial
                }
                readOnly={form?.representante === true}
              />
            ) : null}
            {form?.representante === false ? (
              <SelectInput
                placeholder="Selecione o Representante Responsável"
                options={listRepresentanteResponsavel}
                valueKey="idAcRedeCredenciadaRepr"
                labelKey="nomeFantasia"
                label="Nome Representante"
                value={form?.idAcRedeCredenciadaRepr}
                state="idAcRedeCredenciadaRepr"
                name="idAcRedeCredenciadaRepr"
                form={form}
                setForm={(newForm) => {
                  setForm(newForm);
                  setErrors((prevState) => ({
                    ...prevState,
                    idAcRedeCredenciadaRepr: null,
                  }));
                }}
                width={"98%"}
                error={errors?.idAcRedeCredenciadaRepr}
                readOnly={form?.representante === true}
              />
            ) : null}
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione a Chave"
              options={listTipoChavePix}
              label="Tipo Chave Pix"
              valueKey="valor"
              labelKey="descricao"
              state="tipoChavePix"
              name="tipoChavePix"
              value={form?.tipoChavePix}
              form={form}
              setForm={(newForm) => {
                setForm(newForm);
                setErrors((prevState) => ({
                  ...prevState,
                  tipoChavePix: null,
                  nomePix: null,
                  chavePix: null,
                  bancoPix: null,
                }));
              }}
              width={"100%"}
              error={errors?.tipoChavePix}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        {form?.tipoChavePix ? (
          <FlexboxGrid align="top">
            <FlexboxGrid.Item colspan={8} className="flexGridInput ellipsis ">
              <TextField
                label="Chave Pix"
                placeholder=""
                name="chavePix"
                width={"100%"}
                value={form?.chavePix ?? ""}
                onChange={(value) => {
                  setForm((state) => ({
                    ...state,
                    chavePix: value,
                  }));
                  setErrors((state) => ({
                    ...state,
                    chavePix: null,
                  }));
                }}
                error={errors?.chavePix}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={8} className="flexGridInput ellipsis ">
              <TextField
                label="Nome Pix"
                placeholder=""
                name="nomePix"
                width={"100%"}
                value={form?.nomePix ?? ""}
                onChange={(value) => {
                  setForm((state) => ({
                    ...state,
                    nomePix: value,
                  }));
                  setErrors((state) => ({
                    ...state,
                    nomePix: null,
                  }));
                }}
                error={errors?.nomePix}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item
              colspan={8}
              className="flexGridInput ellipsis "
              style={{ paddingRight: "1.5rem" }}
            >
              <SelectInput
                placeholder="Selecione o Banco"
                options={listBanco}
                label="Banco Pix"
                valueKey="fornecedorIdCbBancos"
                labelKey="nomeBanco"
                state="bancoPix"
                name="bancoPix"
                value={form?.bancoPix}
                form={form}
                setForm={(newForm) => {
                  setForm(newForm);
                  setErrors((prevState) => ({
                    ...prevState,
                    bancoPix: null,
                  }));
                }}
                width={"100%"}
                error={errors?.bancoPix}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        ) : null}

        <FlexboxGrid align="top">
          <FlexboxGrid.Item
            colspan={6}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Banco"
              options={listBanco}
              label="Banco"
              valueKey="fornecedorIdCbBancos"
              labelKey="nomeBanco"
              state="fornecedorIdCbBancos"
              name="fornecedorIdCbBancos"
              value={form?.fornecedorIdCbBancos}
              form={form}
              setForm={(newForm) => {
                setForm(newForm);
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorIdCbBancos: null,
                }));
              }}
              // setForm={({ idCbBancos }) => {
              //   setForm({ ...form, fornecedorIdCbBancos: idCbBancos });
              //   setErrors({ ...errors, fornecedorIdCbBancos: null });
              // }}
              width={"100%"}
              error={errors?.fornecedorIdCbBancos}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={2} className="flexGridInput ellipsis ">
            <TextField
              label="Nr. Banco"
              placeholder=""
              name="fornecedorNumeroBanco"
              width={"100%"}
              value={form?.fornecedorNumeroBanco ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Agência"
              placeholder=""
              name="fornecedorAgencia"
              width={"100%"}
              value={form?.fornecedorAgencia ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextField
              label="Nr. Conta"
              placeholder=""
              name="fornecedorNumeroConta"
              width={"100%"}
              value={form?.fornecedorNumeroConta ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Tipo"
              options={listTipoConta}
              label="Tipo Conta"
              valueKey="fornecedorTipoConta"
              labelKey="descricao"
              state="fornecedorTipoConta"
              name="fornecedorTipoConta"
              value={form?.fornecedorTipoConta ?? ""}
              form={form}
              setForm={(newForm) => {
                setForm(newForm);
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorTipoConta: null,
                }));
              }}
              // setForm={({ idSysComboFornTipoConta }) => {
              //   setForm({ ...form, fornecedorTipoConta: idSysComboFornTipoConta });
              //   setErrors({ ...errors, fornecedorTipoConta: null });
              // }}
              width={"100%"}
              error={errors?.fornecedorTipoConta}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              valueKey="id"
              labelKey="descricao"
              label="Isento valor DOC ?"
              value={form?.isentoValorDoc}
              state="isentoValorDoc"
              name="isentoValorDoc"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, isentoValorDoc: id });
                setErrors((prevState) => ({
                  ...prevState,
                  isentoValorDoc: null,
                }));
              }}
              width={"100%"}
              error={errors?.isentoValorDoc}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextFieldCurrency
              label="Valor DOC"
              name="fornecedorValorDoc"
              width={"100%"}
              newValue={form?.fornecedorValorDoc}
              onValueChange={(value) => {
                handleValueChange(value, "fornecedorValorDoc");
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorValorDoc: null,
                }));
              }}
              error={errors?.fornecedorValorDoc}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="CPF ou CNPJ C.C."
              placeholder=""
              name="fornecedorCpfcc"
              width={"100%"}
              value={form?.fornecedorCpfcc ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={7} className="flexGridInput ellipsis ">
            <TextField
              label="Nome Conta Corrente"
              placeholder=""
              name="fornecedorNomecc"
              width={"100%"}
              value={form?.fornecedorNomecc ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder=""
              options={listaTipoOperacao}
              valueKey="id"
              labelKey="descricao"
              label="Tipo Operação"
              value={form?.fornecedorTipoOperacao}
              state="fornecedorTipoOperacao"
              name="fornecedorTipoOperacao"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, fornecedorTipoOperacao: id });
                setErrors((prevState) => ({
                  ...prevState,
                  fornecedorTipoOperacao: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornecedorTipoOperacao}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              valueKey="id"
              labelKey="descricao"
              label="Agrupa rem. pag ?"
              value={form?.fornAgrRemPg}
              state="fornAgrRemPg"
              name="fornAgrRemPg"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, fornAgrRemPg: id });
                setErrors((prevState) => ({
                  ...prevState,
                  fornAgrRemPg: null,
                }));
              }}
              width={"100%"}
              error={errors?.fornAgrRemPg}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis ellipsis"
          >
            <PercentInput
              label="Repasse Cartão"
              name="aliquiotaRepasseCartao"
              width={"100%"}
              value={form?.aliquotaRepasseCartao}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={3} className="flexGridInput ellipsis ">
            <TextFieldCurrency
              label="Vlr Repasse Seguro"
              name="valorRepasseSeguro"
              width={"100%"}
              newValue={form?.valorRepasseSeguro}
              onValueChange={(value) => {
                handleValueChange(value, "valorRepasseSeguro");
                setErrors((prevState) => ({
                  ...prevState,
                  valorRepasseSeguro: null,
                }));
              }}
              error={errors?.valorRepasseSeguro}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="Cod Rep C/ Cliente S/ dígito"
              placeholder=""
              name="codigoCliente"
              width={"100%"}
              value={form?.codigoCliente ?? ""}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              valueKey="id"
              labelKey="descricao"
              label="Emite NFSe ?"
              value={form?.emiteNfse}
              state="emiteNfse"
              name="emiteNfse"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, emiteNfse: id });
                setErrors((prevState) => ({
                  ...prevState,
                  emiteNfse: null,
                }));
              }}
              width={"100%"}
              error={errors?.emiteNfse}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={3}
            className="flexGridInput ellipsis"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Sim / Não"
              options={listaSimNao}
              valueKey="numero"
              labelKey="descricao"
              label="Reter Iss ?"
              value={form?.reterIss}
              state="reterIss"
              name="reterIss"
              form={form}
              setForm={({ numero }) => {
                setForm({ ...form, reterIss: numero });
                setErrors((prevState) => ({
                  ...prevState,
                  reterIss: null,
                }));
              }}
              width={"100%"}
              error={errors?.reterIss}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput ellipsis flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <CheckboxInput
              text1="Sair Mensagem Juros ?"
              text2="Sair Mensagem Juros ?"
              form={form}
              value={form?.sairmensagemjuros}
              setForm={setForm}
              state="sairmensagemjuros"
              width={"100%"}
              style={{ marginTop: "1.8rem" }}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis ">
            <TextField
              label="Usuário"
              placeholder=""
              name="usuario"
              width={"100%"}
              value={form?.usuario ?? ""}
              readOnly
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid align="top">
          <FlexboxGrid.Item className="flexGridInput ellipsis" colspan={12}>
            <TextField
              textarea={4}
              width="100%"
              name="observacao"
              value={form?.observacao ?? ""}
              label="Observação"
              style={{
                minHeight: "36px",
                height: "100px",
                maxHeight: "200px",
                width: "100%",
              }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form>
      <div className="addingButton" style={{ marginTop: "1rem" }}>
        <>
          {form?.idAcRedeCredenciada ? (
            <>
              <Button
                type="button"
                appearance="ghost"
                color="orange"
                className="pageButton"
                onClick={() => {
                  setForm(InitialForm);
                  setActiveNav("redeCredenciada");
                }}
              >
                CANCELAR EDIÇÃO
              </Button>
              <Button
                type="button"
                appearance="primary"
                color="blue"
                className="pageButton"
                loading={load}
                onClick={() => {
                  handleSubmitRedeCredenciada();
                }}
              >
                ALTERAR
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                appearance="ghost"
                color="orange"
                className="pageButton"
                onClick={() => {
                  setForm(InitialForm);
                  setFormFornecedor(initialFormFornecedor);
                  setActiveNav("redeCredenciada");
                }}
              >
                LIMPAR
              </Button>

              <Button
                type="button"
                appearance="primary"
                color="blue"
                className="pageButton"
                loading={load}
                onClick={() => {
                  handleSubmitRedeCredenciada();
                }}
              >
                SALVAR
              </Button>
            </>
          )}
        </>
      </div>
    </>
  );
}
