import { DateInput, Form, TextField, TextFieldCurrency } from "@orgystem-ui/components.form"
import { useCallback, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Button, FlexboxGrid, Modal } from "rsuite"
import { putAgendamentoMedico } from "../ConfirmarAgendamentoService"
import { AgendamentoMedicoNav } from "../navs/AgendamentoMedicoNav"
import { CancelarAgendamentoForm } from "./CancelarAgendamentoForm"

const ConfirmarAgendamentoForm = ({
  form, setForm, dataSource, setDataSource, active, setActive
}) => {
  const { addToast } = useToasts()
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleCloseModal() {
    setShow(false)
  }

  const errorMessage = message => {
    addToast(
      message,
      {
        appearance: 'error',
        autoDismiss: true
      }
    )
  }
  const successMessage = message => {
    addToast(
      message,
      {
        appearance: 'success',
        autoDismiss: true
      }
    )
  }


  function handleFormChange(value) {
    setForm(state => ({
      ...state,
      ...value
    }));
  }

  const handleValueChange = useCallback((value, input) => {
    // eslint-disable-next-line
    setForm((prevState) => ({
      ...prevState,
      [input]: Number.parseFloat(value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClear() {
    setForm({
      acAgendaMedicaHorario: [],
      acRamoAtividadeDescricao: "",
      acSituacaoConsultaMedicaDescricao: "",
      dataInclusao: "",
      dataRecebimento: "",
      idAcAgendaMedica: null,
      nome: "",
      nomeFantasia: "",
      numeroCartao: "",
      observacao: "",
      status: 0,
      valorRecebimento: 0
    })
    setDataSource([])
  }

  function handleSubmit() {
    setLoading(true)
    putAgendamentoMedico(form)
      .then(res => {
        setForm(res)
        const data = res.acAgendaMedicaHorario.map(item => {
          item.check = item.agendado === "T" ? true : false
          return item
        })
        setDataSource(data)
        successMessage('Cadastro salvo com sucesso!')
      })
      .catch(err => errorMessage(err))
      .finally(() => setLoading(false))
  }
  const disabledEdit = form.trava === 'T'
  return (
    <>
      <Modal
        open={show}
        onClose={handleCloseModal}
        className="cancel-modal"
        size='md'
      // style={{ maxWidth: "100%" }}
      >
        <Modal.Header>
          <Modal.Title>Cancelar Agendamento Médico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CancelarAgendamentoForm
            form={form}
            setForm={setForm}
            successMessage={successMessage}
            errorMessage={errorMessage}
            onClose={handleCloseModal}
          />
        </Modal.Body>
      </Modal>
      <Form
        formValue={form}
        onChange={handleFormChange}
        onSubmit={handleSubmit}
      >
        <FlexboxGrid style={{ marginTop: '1rem' }} justify="start">
          <FlexboxGrid.Item colspan={3} >
            <DateInput
              state="dataInclusao"
              name="dataInclusao"
              label="Data Inclusão"
              width={"100%"}
              setForm={setForm}
              form={form}
              value={form?.dataInclusao ? new Date(form?.dataInclusao) : null}
              placeholder="dd/mm/aaaa"
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} >
            <DateInput
              state="dataRecebimento"
              name="dataRecebimento"
              label="Data Recebimento"
              width={"100%"}
              setForm={setForm}
              form={form}
              value={form?.dataRecebimento ? new Date(form?.dataRecebimento) : null}
              placeholder="dd/mm/aaaa"
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3}>
            <TextFieldCurrency
              label="Valor Recebido"
              name="valorRecebimento"
              width={"100%"}
              newValue={form?.valorRecebimento ?? 0}
              className="disabled-text"
              onValueChange={(value) =>
                handleValueChange(value, "valorRecebimento")
              }
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <TextField
              name="nome"
              label="Nome Usuário"
              width="100%"
              value={form?.nome}
              className="disabled-text"
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={4} >
            <TextField
              label="Número Cartão"
              name="numeroCartao"
              value={form?.numeroCartao}
              width={"100%"}
              className="disabled-text"
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} style={{ paddingRight: '0.5rem' }}>
            <TextField
              name="acSituacaoConsultaMedicaDescricao"
              label="Situação Agenda"
              value={form?.acSituacaoConsultaMedicaDescricao}
              width="100%"
              className="disabled-text"
              disabled
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={12}>
            <TextField
              label="Rede Credenciada"
              name="nomeFantasia"
              value={form?.nomeFantasia}
              width="100%"
              className="disabled-text"
              disabled
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12} style={{ paddingRight: "0.5rem" }}>
            <TextField
              label="Ramo Atividade"
              name="acRamoAtividadeDescricao"
              value={form?.acRamoAtividadeDescricao}
              className="disabled-text"
              width="100%"
              disabled
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24} style={{ paddingRight: '0.5rem' }}>
            <TextField
              label="Observação"
              name="observacao"
              value={form?.observacao}
              width="100%"
              textarea={true}
              onChange={e => {
                setForm(state => ({
                  ...state,
                  observacao: e,
                  status: 2
                }))
              }}
              minHeight="5rem"
              disabled={disabledEdit || !!form.dataRecebimento}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={14}>
            <AgendamentoMedicoNav
              dataSource={dataSource}
              form={form}
              setForm={setForm}
              active={active}
              setActive={setActive}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid justify="end" align="top" >
          <FlexboxGrid.Item>
            <Button
              appearance="primary"
              color="green"
              className="pageButtonPrimary"
              onClick={() => setShow(true)}
              disabled={!form.idAcAgendaMedica || !!form.dataRecebimento || disabledEdit}
            >
              Cancelar agendamento
            </Button>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <Button
              appearance="ghost"
              color="orange"
              className="pageButtonSecondary"
              onClick={handleClear}
            >
              Limpar
            </Button>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <Button
              appearance="primary"
              color="blue"
              className="pageButtonPrimary"
              type="submit"
              loading={loading}
              disabled={disabledEdit || !form.idAcAgendaMedica || !!form.dataRecebimento}
            >
              Salvar Agendamento
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form>
    </>
  )
}

export { ConfirmarAgendamentoForm }

