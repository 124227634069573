export default function DocumentoFiscal(state = {}, action) {
  switch (action.type) {
    case "ADD_ID_NCM": {
      return {
        ...state,
        idEsNcm: action.idEsNcm,
      };
    }
    case "ADD_NCM": {
      return {
        ...state,
        ncm: action.ncm,
      };
    }

    case "ADD_CEST": {
      return {
        ...state,
        cest: action.cest,
      };
    }
    case "ADD_ID_CEST": {
      return {
        ...state,
        idEsCest: action.idEsCest,
      };
    }
    case "ADD_CODIGO_PRODUTO": {
      return {
        ...state,
        codProduto: action.codProduto,
      };
    }
    case "ADD_DESCRICAO_PRODUTO": {
      return {
        ...state,
        descricaoProduto: action.descricaoProduto,
      };
    }
    case "ADD_CODIGO_BARRAS": {
      return {
        ...state,
        codigoBarras: action.codigoBarras,
      };
    }
    case "ADD_ID_UNIDADE_DE_MEDIDA": {
      return {
        ...state,
        idCeUnidadeMedida: action.idCeUnidadeMedida,
      };
    }
    case "ADD_SIMBOLO_UNIDADE_DE_MEDIDA": {
      return {
        ...state,
        simbolo: action.simbolo,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        openModal: action.openModal,
      };
    }

    default:
      return state;
  }
}
