import api from "../../services/api";
import qs from "qs";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";
import CustomStore from "devextreme/data/custom_store";

//handleLoad

export function handleLoad(
  loadOptions,
  baseUrl,
  otherParams = {},
  nameEmpresaParam = "idEmpresa"
) {
  const { company } = handleGetSessionStorage();
  const parametros = {
    [nameEmpresaParam]: company,
    skip: 0,
    take: 1000,
    ...otherParams,
  };
  const params = handleParams(parametros, loadOptions);
  const url = `${baseUrl}?${qs.stringify(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((res) => {
        const { data, totalCount } = res.data;
        resolve({
          data,
          totalCount,
        });
      })
      .catch((error) => {
        reject(handleError(error, "Erro ao carregar"));
      });
  });
}

export function handleLoadById(
  baseUrl,
  otherParams = {},
  nameEmpresaParam = "idEmpresa"
) {
  const { company } = handleGetSessionStorage();
  const parametros = {
    [nameEmpresaParam]: company,
    ...otherParams,
  };
  const url = `${baseUrl}?${qs.stringify(parametros)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(handleError(error, "Erro ao carregar"));
      });
  });
}

export function handlePostAndPut(data, url, method) {
  return new Promise((resolve, reject) => {
    api({ method, url, data })
      .then((res) => {
        const { data, totalCount } = res?.data;
        data
          ? resolve({
            data,
            totalCount,
          })
          : resolve(res.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
}

function handleDeleteParam({ id }, baseUrl) {
  const url = `${baseUrl}${id}`;
  return new Promise((resolve, reject) => {
    api
      .delete(url)
      .then((res) => resolve(res))
      .catch((error) => {
        reject(handleError(error, "Erro ao remover"));
      });
  });
}

//gridDataSource

export const gridDataSourceConsulta = {
  store: new CustomStore({
    key: ["id"],
    load: (loadOptions) => {
      const url = "";
      return handleLoad(loadOptions, url);
    },
    byKey: (key) => {
      const url = "";
      return handleLoadById(url, { id: key });
    },
  }),
};

export const gridDataSourceProcedimentos = {
  store: new CustomStore({
    key: ["id"],
    load: (loadOptions) => {
      const url = "";
      return handleLoad(loadOptions, url, {});
    },
    insert: (values) => {
      const url = "";
      const record = {
        ...values,
      };
      return handlePostAndPut(record, url, "POST");
    },
    update: (key, values) => {
      const url = "";
      const record = {
        ...values,
      };
      return handlePostAndPut(record, url, "PUT");
    },
    remove: (values) => {
      const url = "";
      return handleDeleteParam(values, url);
    },
  }),
};
