import axios from "axios";
import Cleave from "cleave.js/react";
import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import ModalSelect from "../../../components/modalSelect/ModalSelect";
import { removerAcentos } from "../../../services/helpers";
import { useRedeCredenciadaContext } from "../context/RedeCredenciadaContext";
import {
  dataSourceTipoEndereco,
  gridDataSourceCidade,
  gridDataSourceEndereco,
  gridDataSourceUf,
  handleCepInfo,
} from "../RedeCredenciadaService";

const EnderecoGrid = () => {
  const { form, successMessage } = useRedeCredenciadaContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [toggleCidade, setToggleCidade] = useState(false);
  const [toggleEstado, setToggleEstado] = useState(false);
  const [listCidade, setListCidade] = useState([]);
  const [listEstado, setListEstado] = useState([]);
  const [listLogradouro, setListLogradouro] = useState([]);
  const [logradouro, setLogradouro] = useState();
  const [key, setKey] = useState([]);
  const [sigla, setSigla] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [descricaoCidade, setDescricaoCidade] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [descricaoEstado, setDescricaoEstado] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [allowAdding, setAllowAdding] = useState(true);
  const idAcRedeCredenciada = form?.idAcRedeCredenciada;

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    for (let i = 0; i < 9; i++) {
      if (event.fullName === `columns[${i}].filterValue`) {
        setAllowAdding(true);
      }
    }

    if (event.fullName === "editing.changes") {
      if (event.value[0]?.data) {
        event.value[0].data.descricaoCidade = descricaoCidade;
        event.value[0].data.endereco = endereco;
        event.value[0].data.bairro = bairro;
        event.value[0].data.logradouro = logradouro;
      }
    }
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleRemove({ data }) {
    successMessage("Endereço deletado com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  function editCellCidade(event) {
    const { data } = event.data;

    listCidade.map((item) => {
      const checkIfHasId = item.idAcRedeCredenciadaEnde !== undefined;
      const checkIds =
        item.idAcRedeCredenciadaEnde === data.idAcRedeCredenciadaEnde ||
        item.idAcRedeCredenciadaEnde === data.id;

      if (checkIfHasId && checkIds) {
        data.idAcRedeCredenciadaEnde =
          item.idAcRedeCredenciadaEnde ?? data.idAcRedeCredenciadaEnde;
        data.descricaoCidade = item.descricaoCidade ?? descricaoCidade;
        data.value = descricaoCidade;
        event.data.setValue(descricaoCidade, "descricaoCidade");
      }

      return item;
    });

    return handleInputModalCidade(event.data);
  }

  function editCellEstado(event) {
    const { data } = event.data;

    listEstado.map((item) => {
      const checkIfHasId = item.idAcRedeCredenciadaEnde !== undefined;
      const checkIds =
        item.idAcRedeCredenciadaEnde === data.idAcRedeCredenciadaEnde ||
        item.idAcRedeCredenciadaEnde === data.id;

      if (checkIfHasId && checkIds) {
        data.idAcRedeCredenciadaEnde =
          item.idAcRedeCredenciadaEnde ?? data.idAcRedeCredenciadaEnde;
        // data.descricaoUf = item.descricaoEstado ?? descricaoEstado;
        data.sigla = item.uf ?? sigla;

        data.value = sigla;
        // data.value = descricaoEstado;

        event.data.setValue(sigla, "estado");
        // event.data.setValue(descricaoEstado, "descricaoUf");
      }

      return item;
    });

    return handleInputModalEstado(event.data);
  }

  function toggleModalCidade(data) {
    setToggleCidade(!toggleCidade);

    if (!data) return;
    if (
      data.idAcRedeCredenciadaEnde !== undefined ||
      data.idAcRedeCredenciadaEnde !== null
    ) {
      setKey(data.idAcRedeCredenciadaEnde);
    }

    // Pega item selecionado na modal
    if (data?.idCrCidades) {
      listCidade.map((item) => {
        if (item.idAcRedeCredenciadaEnde === key) {
          item.idCrCidades = data.idCrCidades;
          item.descricaoCidade = data.cidade;
          setDescricaoCidade(data.cidade);
          setListCidade([...listCidade]);
        }

        return item;
      });

      setListCidade([...listCidade]);
    }
  }

  function toggleModalEstado(data) {
    setToggleEstado(!toggleEstado);

    if (!data) return;
    if (
      data.idAcRedeCredenciadaEnde !== undefined ||
      data.idAcRedeCredenciadaEnde !== null
    ) {
      setKey(data.idAcRedeCredenciadaEnde);
    }

    // Pega item selecionado na modal
    if (data?.idCrUf) {
      listEstado.map((item) => {
        if (item.idAcRedeCredenciadaEnde === key) {
          item.idCrUf = data.idCrUf;
          item.descricaoEstado = data.descricaoUf;
          item.siglaEstado = data.siglaUf;
          setSigla(data.siglaUf);
          setDescricaoEstado(data.descricaoUf);
          setListEstado([...listEstado]);
        }

        return item;
      });

      setListEstado([...listEstado]);
    }
  }

  function handleInputModalCidade(event) {
    return (
      <div
        className="ellipsis"
        style={{
          cursor: "pointer",
          height: 22,
          display: "flex",
        }}
      >
        <div
          onClick={() => {
            toggleModalCidade({
              idAcRedeCredenciadaEnde: event.data.idAcRedeCredenciadaEnde || 0,
            });
          }}
          style={{ width: "100%", paddingLeft: "7px" }}
        >
          {descricaoCidade ? descricaoCidade : ""}
        </div>
      </div>
    );
  }

  function handleInputModalEstado(event) {
    return (
      <div
        className="ellipsis"
        style={{
          cursor: "pointer",
          height: 22,
          display: "flex",
        }}
      >
        <div
          onClick={() => {
            toggleModalEstado({
              idAcRedeCredenciadaEnde: event.data.idAcRedeCredenciadaEnde || 0,
            });
          }}
          style={{ width: "100%", paddingLeft: "7px" }}
        >
          {sigla ? sigla : ""}
        </div>
      </div>
    );
  }

  function handleNewRow(event) {
    event.data.idAcRedeCredenciadaEnde = 0;
    listCidade.push({ idAcRedeCredenciadaEnde: 0 });
    setListCidade([...listCidade]);

    listEstado.push({ idAcRedeCredenciadaEnde: 0 });
    setListEstado([...listEstado]);

    listLogradouro.push({ idAcRedeCredenciadaEnde: 0 });
    setListLogradouro([...listLogradouro]);
  }

  function handleEditingStart(event) {
    setAllowAdding(false);

    listCidade.push({
      idAcRedeCredenciadaEnde: event.data.idAcRedeCredenciadaEnde,
      descricaoCidade: event.data.cidade,
      idCrCidades: event.data.idCrCidades,
    });
    setDescricaoCidade(event.data.cidade);
    setListCidade([...listCidade]);

    listEstado.push({
      idAcRedeCredenciadaEnde: event.data.idAcRedeCredenciadaEnde,
      descricaoEstado: event.data.sigla,
      idCrUf: event.data.idCrUf,
      sigla: event.data.estado,
    });
    setDescricaoEstado(event.data.estado);
    setSigla(event.data.estado);

    setListEstado([...listEstado]);

    setEndereco(event.data.endereco);
    setBairro(event.data.bairro);
    setLogradouro(event.data.logradouro);
  }

  async function handleInsertError(event) {
    const { newData, oldData = false } = event;

    newData.idTipoEndereco = newData?.idTipoEndereco ?? oldData.idTipoEndereco;
    newData.telefone = newData?.telefone ?? oldData.telefone;
    newData.idCrCidades = newData?.idCrCidades ?? oldData.idCrCidades;
    newData.descricaoCidade =
      newData?.descricaoCidade ?? oldData.descricaoCidade;
    newData.idCrUf = newData?.idCrUf ?? oldData.idCrUf;
    newData.idAcTipoLogradouro =
      newData?.idAcTipoLogradouro ?? oldData.idAcTipoLogradouro;
    newData.cep = newData?.cep ?? oldData?.cep;
    newData.sigla = newData?.sigla ?? oldData?.sigla;
    newData.estado = newData?.estado ?? oldData?.estado;
    newData.numero = newData?.numero ?? oldData?.numero;
    newData.complemento = newData?.complemento ?? oldData?.complemento;
    newData.bairro = newData?.bairro ?? oldData?.bairro;
    newData.logradouro = newData?.logradouro ?? oldData?.logradouro;
    newData.endereco = newData?.endereco ?? oldData?.endereco;
    newData.dataInclusao = oldData.dataInclusao;
    newData.usuario = oldData.usuario ?? "";
  }

  async function handleCep(rowData, e) {
    const location = await axios
      .get(`https://viacep.com.br/ws/${e.target.rawValue}/json`)
      .then((res) => res.data);
    if (location.cep) {
      const cepInfo = await handleCepInfo(location);

      if (cepInfo.data[0]) {
        setSigla(cepInfo.data[0]?.uf);
        setDescricaoCidade(removerAcentos(cepInfo.data[0]?.cidade));
        setDescricaoEstado(removerAcentos(cepInfo.data[0]?.descricaoEstado));
        setLogradouro(location.logradouro.substr(0, 3).toUpperCase());
      }
      setEndereco(removerAcentos(location.logradouro));
      setBairro(removerAcentos(location.bairro));
      setLogradouro(location.logradouro.substr(0, 3).toUpperCase());
    } else {
      setSigla("");
      setDescricaoCidade("");
      setDescricaoEstado("");
      setEndereco("");
      setBairro("");
    }
  }

  function clearDadosGrid() {
    setAllowAdding(true);
    setSigla("");
    setEndereco("");
    setBairro("");
    setDescricaoCidade("");
    setDescricaoEstado("");
  }

  return (
    <>
      <ModalSelect
        dataSource={gridDataSourceCidade(sigla)}
        colums={[
          {
            dataField: "cidade",
            caption: "Cidade",
            width: "70%",
          },
          {
            dataField: "uf",
            caption: "UF",
            width: "10%",
          },
          {
            dataField: "codigo",
            caption: "Código IBGE",
            alignment: "right",
          },
        ]}
        title={"a Cidade"}
        visible={toggleCidade}
        toggleModal={toggleModalCidade}
        width="1200px"
      />
      <ModalSelect
        dataSource={gridDataSourceUf}
        colums={[
          {
            caption: "Estado",
            dataField: "descricaoUf",
          },
        ]}
        title={"o Estado"}
        visible={toggleEstado}
        toggleModal={toggleModalEstado}
        width="1200px"
      />
      <DataGrid
        dataSource={gridDataSourceEndereco(idAcRedeCredenciada)}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onInitNewRow={handleNewRow}
        onEditingStart={handleEditingStart}
        onRowInserted={handleInsert}
        onRowRemoved={handleRemove}
        onRowValidating={handleInsertError}
        onSaved={() => clearDadosGrid()}
        onEditCanceled={() => clearDadosGrid()}
        onRowUpdated={handleUpdate}
        onOptionChanged={handleOptionChange}
        // loadPanel={true}
        style={{ marginTop: "0.5rem", paddingLeft: "0.5rem" }}
      >
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <Editing
          /* mode="row" */ useIcons
          allowAdding
          allowDeleting
          allowUpdating
        />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />

        <Column
          dataField="idTipoEndereco"
          caption="Tipo Endereço"
          filterOperations={false}
          alignment="left"
          width={"10%"}
        >
          <Lookup
            dataSource={dataSourceTipoEndereco}
            displayExpr="descricao"
            valueExpr="idTipoEndereco"
          />
          <RequiredRule message="Tipo endereço é obrigatório" />
        </Column>

        <Column
          dataField="telefone"
          caption="Telefone"
          filterOperations={false}
          alignment="left"
          width="10%"
          editCellComponent={(rowData) => (
            <Cleave
              className="gridInputMask"
              options={{
                numericOnly: true,
                phone: true,
                phoneRegionCode: "BR",
              }}
              onChange={(e) =>
                rowData.data.setValue(e.target.value, "telefone")
              }
              value={rowData.data.value}
            ></Cleave>
          )}
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="cep"
          caption="CEP"
          filterOperations={false}
          alignment="left"
          width={"10%"}
          editCellComponent={(rowData) => (
            <Cleave
              className="gridInputMask "
              options={{
                blocks: [5, 3],
                delimiters: ["-", ""],
                numericOnly: true,
              }}
              onChange={(e) => {
                rowData.data.setValue(e.target.value, "cep");
                if (rowData.data.value.length === 0) {
                  setSigla("");
                  setEndereco("");
                  setBairro("");
                  setDescricaoCidade("");
                  setDescricaoEstado("");
                }
                if (rowData.data.value.length === 9) {
                  handleCep(rowData, e);
                }
              }}
              value={rowData.data.value}
            />
          )}
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="logradouro"
          caption="Logradouro"
          filterOperations={false}
          width={"5%"}
          alignment={"left"}
        ></Column>

        <Column
          dataField="endereco"
          caption="Endereço"
          filterOperations={false}
          width={"20%"}
          editCellComponent={(rowData) => (
            <Cleave
              className="gridInputMask"
              options={{ blocks: [99999], delimiter: "" }}
              onBlur={(e) => {
                setEndereco(e.target.value);
              }}
              onChange={(e) => {
                rowData.data.setValue(e.target.rawValue, "endereco");
              }}
              value={endereco}
            />
          )}
        >
          <RequiredRule />
        </Column>

        <Column
          width={"5%"}
          dataField="numero"
          caption="Número"
          filterOperations={false}
          alignment="left"
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="complemento"
          caption="Complemento"
          filterOperations={false}
          alignment="left"
          width={"10%"}
        ></Column>

        <Column
          dataField="bairro"
          caption="Bairro"
          filterOperations={false}
          width={"15%"}
          editCellComponent={(rowData) => (
            <Cleave
              className="gridInputMask"
              options={{ blocks: [99999], delimiter: "" }}
              onBlur={(e) => {
                setBairro(e.target.value);
              }}
              onChange={(e) => {
                rowData.data.setValue(e.target.rawValue, "bairro");
              }}
              value={bairro}
            />
          )}
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="estado"
          caption="Estado"
          filterOperations={false}
          width={"5%"}
          editCellComponent={editCellEstado}
          alignment={"left"}
        >
          <RequiredRule />
        </Column>

        <Column
          dataField="cidade"
          caption="Cidade"
          filterOperations={false}
          alignment="left"
          width={"15%"}
          editCellComponent={editCellCidade}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
    </>
  );
};

export default EnderecoGrid;
