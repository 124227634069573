import { FilterModal } from "@orgystem-ui/components.filter-modal";
import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Sorting,
} from "devextreme-react/data-grid";
import React, { useRef, useState } from "react";
import { useTerminalContext } from "./context/TerminalAdministrativoContext";
import {
  gridDataSourceFilter,
  handleLoadById,
} from "./TerminalAdministrativoService";

export function TerminalFilter() {
  const { setForm, initialForm, setErrors, setAceitaValores } =
    useTerminalContext();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [toggle, setToggle] = useState(false);

  const gridRef = useRef(null);
  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleEditData({ idAcRedeCredenciada, razaoSocial }) {
    handleLoadById("RedeCredenciada/FornecedorRedeCredenciada", {
      idAcRedeCredenciada,
    })
      .then((data) => {
        setAceitaValores(data?.fornecedorAceitaValores === "S" ? true : false);
      })
      .catch(() => setAceitaValores(false));
    setForm({
      ...initialForm,
      idAcRedeCredenciada,
      razaoSocial,
    });
    setToggle(!toggle);
    setErrors({});
  }

  return (
    <FilterModal
      toggle={toggle}
      setToggle={setToggle}
      full
      style={{ paddingLeft: "30px" }}
    >
      <>
        <DataGrid
          dataSource={gridDataSourceFilter}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          columnAutoWidth={true}
          onOptionChanged={handleOptionChange}
          rowAlternationEnabled={true}
          showRowLines={true}
          ref={gridRef}
        >
          <Editing mode="row" useIcons />
          <Sorting mode="single" />
          <Pager
            allowedPageSizes={[7, 15, 30]}
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
          />
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
          <FilterRow
            visible={filterOptions.showFilterRow}
            applyFilter={filterOptions.currentFilter}
          />
          <HeaderFilter />

          <Column
            dataField="razaoSocial"
            caption="Nome / Razão Social"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="35%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="nomeFantasia"
            caption="Nome Fantasia"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="35%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column
            dataField="cnpj"
            caption="CNPJ"
            filterOperations={false}
            allowEditing={false}
            alignment="left"
            width="15%"
          >
            <HeaderFilter groupInterval={70} />
          </Column>

          <Column type="buttons" width="10%">
            <Button
              hint="Editar"
              visible
              icon="edit"
              onClick={(e) => {
                const { data } = e?.row;
                handleEditData(data);
              }}
            />
          </Column>
        </DataGrid>
      </>
    </FilterModal>
  );
}
