/* eslint-disable react-hooks/exhaustive-deps */
import { Form, TextField } from "@orgystem-ui/components.form";
import React from "react";
import { Button, FlexboxGrid, Modal, Schema } from "rsuite";
import { useTerminalContext } from "../context/TerminalAdministrativoContext";

const ModalAtendimentoComValor = ({
  showModal = false,
  handleCloseModal = () => {},
  handleSubmit = () => {},
}) => {
  const { form, errors, setErrors, handleChange, loadingButton } =
    useTerminalContext();

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    senha: StringType().isRequired("Senha é requerido"),
  });

  return (
    <Modal open={showModal} onClose={handleCloseModal} size="xs">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bold" }}>
          Atendimento com valor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          formValue={form}
          model={model}
          formError={errors}
          onChange={handleChange}
          onCheck={setErrors}
          onSubmit={(isValid) => {
            if (isValid) {
              handleSubmit();
            }
          }}
          fluid
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
              <TextField
                label="Senha do Cartão"
                name="senha"
                width={"95%"}
                type="password"
                error={errors?.senha}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <FlexboxGrid justify="end">
            <Button
              appearance="ghost"
              color="orange"
              onClick={handleCloseModal}
              className="pageButtonSecondary"
            >
              CANCELAR
            </Button>
            <Button
              appearance="primary"
              color="blue"
              className="pageButton"
              type="submit"
              disabled={loadingButton}
              loading={loadingButton}
            >
              FINALIZAR
            </Button>
          </FlexboxGrid>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { ModalAtendimentoComValor };
