import React from "react";
import { Form } from "rsuite";
import { useToasts } from "react-toast-notifications";

import "../Page.css";

import ProcedimentoMedicoGrid from "./grids/ProcedimentoMedicoGrid";

const ProcedimentoMedico = () => {
  const { addToast } = useToasts();

  function successMessage(message) {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch w850">
          <h4>Procedimentos Médicos</h4>
          <Form className="pageForm">
            <Form.Group
              className="pageFormContainer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ProcedimentoMedicoGrid
                successMessage={successMessage}
                warningMessage={warningMessage}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { ProcedimentoMedico };
