/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as CompanyActions from "../../store/modules/company/action";
import { Link } from "react-router-dom";
import { HomeCard } from "@orgystem-ui/components.home-card";
import { handleGetSessionStorage } from "../../services/helpers";

const Home = () => {
  const dispatch = useDispatch();
  const { user, company } = handleGetSessionStorage();
  const tabs = useSelector((state) => state.Header.tabs);

  return (
    <div className="homeContainer">
      <HomeCard
        dispatch={dispatch}
        user={user}
        company={company}
        idModulo={process.env.REACT_APP_ID_MODULE}
        CompanyActions={CompanyActions}
        Link={Link}
        tabs={tabs}
      />
    </div>
  );
};

export default Home;
