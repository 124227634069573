/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Modal } from "rsuite";

const ModalAtendimentoSemValor = ({
  showModal = false,
  handleCloseModal = () => {},
  handleSubmit = () => {},
}) => {
  return (
    <Modal open={showModal} onClose={handleCloseModal} size="xs">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bold" }}>
          Atendimento sem valor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: "1.2rem", marginBlock: "2rem" }}>
          Confirma atendimento sem valor?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="ghost"
          color="orange"
          onClick={handleCloseModal}
          className="pageButtonSecondary"
        >
          CANCELAR
        </Button>
        <Button
          type="button"
          appearance="primary"
          color="blue"
          onClick={handleSubmit}
          className="pageButtonPrimary"
        >
          FINALIZAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalAtendimentoSemValor };
