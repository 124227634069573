import api from "../../services/api";
import {
  handleErrorNew,
  handleGetSessionStorage,
  handleParams
} from "../../services/helpers";
import qs from "qs";
import CustomStore from "devextreme/data/custom_store";

function handleLoad(loadOptions) {
  const { company } = handleGetSessionStorage();
  const params = {
    empresa: company
  };
  const newParams = handleParams(params, loadOptions);
  const url = `AcAgendamentoMedico?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then(res => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount
      };
    })
    .catch(err => handleErrorNew("Erro ao carregar", err));
}

function handleLoadById(loadOptions, url, nameId, nomeEmpresa = "empresa") {
  const { company } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;
  const params = {
    [nameId]: id ? id : 0,
    [nomeEmpresa]: company,
    skip: 0,
    take: 1
  };
  const urlComplete = `${url}${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then(res => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount
      };
    })
    .catch(err => handleErrorNew(err, "Erro ao carregar"));
}

export const agendamentoMedicoStore = {
  store: new CustomStore({
    key: 'idAcAgendaMedica',
    load: loadOptions => handleLoad(loadOptions),
    byKey: key => handleLoadById(key, 'AcAgendamentoMedico', 'idAcAgendaMedica')
  })
}

export function putAgendamentoMedico(form) {
  const { company } = handleGetSessionStorage();

  const body = {
    ...form,
    empresa: company
  }

  return new Promise((resolve, reject) => {
    api.put('AcAgendamentoMedico', body)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(handleErrorNew(err)))
  })
}

export function cancelAgendamentoMedico(form, observacao) {
  const { company, user } = handleGetSessionStorage();
  const body = {
    idAcAgendaMedica: form.idAcAgendaMedica,
    empresa: company,
    observacao,
    usuario: user
  }

  return new Promise((resolve, reject) => {
    api.put('AcAgendamentoMedico/CancelaAgendamento', body)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(handleErrorNew(err)))
  })
}

export function getProcedimentos(idAcAgendaMedica) {
  const { company } = handleGetSessionStorage();
  const params = {
    empresa: company,
    idAcAgendaMedica
  };
  const url = `AcAgendamentoMedico/TransacaoAgendaMedicaRecebido?${qs.stringify(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(res => {
        const { data, totalCount } = res.data;
        return resolve({
          data,
          totalCount
        });
      })
      .catch(err => reject(handleErrorNew("Erro ao carregar", err)));
  })
}