import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import "../Page.css";
import ImportacaoDeArquivoForm from "./forms/ImportacaoDeArquivoForm";

const initialForm = {
  idAcRedeCredenciada: null,
  cnpj: "",
  razaoSocial: "",
  nomeFantasia: "",
  situacaoUsuario: false,
  gerarCartao: false,
  situacaoUsuarioCartao: false,
  tipoCartao: null,
  tipoSegmento: null,
  maximoParcelas: null,
  limiteParcelamento: null,
  vencimentoMes: "",
  vencimentoAno: "",
};

const ImportacaoDeArquivo = () => {
  const { addToast } = useToasts();
  // eslint-disable-next-line no-unused-vars
  const [toggle, setToggle] = useState(false);
  const [form, setForm] = useState(initialForm);
  function successMessage(message) {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: "error",
      autoDismiss: false,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent w1250 withoutSearch">
          <h3>Importar arquivo de Usuários </h3>
          <ImportacaoDeArquivoForm
            form={form}
            setForm={setForm}
            errorMessage={errorMessage}
            successMessage={successMessage}
            warningMessage={warningMessage}
            initialForm={initialForm}
          />
        </div>
      </div>
    </div>
  );
};

export { ImportacaoDeArquivo };

