import React from "react";
import { Nav } from "rsuite";
import { Receipt } from "../../../components/receipt/Receipt";
import { useTerminalContext } from "../context/TerminalAdministrativoContext";
import { ConsultaGrid } from "../grids/ConsultasGrid";
import { ProcedimentosGrid } from "../grids/ProcedimentosGrid";

const TerminalNav = () => {
  const {
    form,
    activeNav,
    setActiveNav,
    visible,
    setVisible,
    data,
    setDisabled,
  } = useTerminalContext();

  const CustomNav = ({ active, onSelect, ...props }) => {
    const temAtendimento = form?.consultaMedica.some(item => item.check)
    return (
      <Nav
        {...props}
        activeKey={active}
        onSelect={onSelect}
        style={{ marginBottom: 10 }}
      >
        <Nav.Item eventKey="consulta">Atendimentos</Nav.Item>
        <Nav.Item eventKey="procedimentos" disabled={!temAtendimento}>Procedimentos</Nav.Item>
      </Nav>
    );
  };

  const Navs = () => {
    switch (activeNav) {
      case "consulta":
        return <ConsultaGrid />;
      case "procedimentos":
        return <ProcedimentosGrid />;
      default:
        break;
    }
  };

  return form?.idAcAdminCartao ? (
    <div className="pageContainer">
      {data && (
        <Receipt
          toggleModal={() => {
            setVisible(!visible);
            setDisabled(true);
          }}
          visible={visible}
          data={data}
        ></Receipt>
      )}

      <CustomNav
        appearance="tabs"
        active={activeNav}
        onSelect={(activeKey) => setActiveNav(activeKey)}
        style={{ marginTop: 15 }}
      />
      {Navs()}
    </div>
  ) : null;
};

export { TerminalNav };
