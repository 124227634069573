import { combineReducers } from "redux";
import Company from "./company/reducer";
import Header from "./header/reducer";
import Home from "./home/reducer";
import Login from "./login/reducer";
import Sidebar from "./sidebar/reducer";
import DocumentoFiscal from "./documentoFiscal/reducer";

export default combineReducers({
  Home,
  Login,
  Header,
  Sidebar,
  Company,
  DocumentoFiscal
});
