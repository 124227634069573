import { Column, DataGrid } from "devextreme-react/data-grid"
import { useEffect, useState } from "react"
import { getProcedimentos } from "../ConfirmarAgendamentoService";

const AgendamentoMedicoProcedimentosGrid = ({ form }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    getProcedimentos(form.idAcAgendaMedica)
      .then(res => setData(res.data))
  }, [form.idAcAgendaMedica])

  return (
    <DataGrid
      dataSource={data}
      rowAlternationEnabled
      showBorders
      showRowLines
      showColumnLines
      style={{ marginTop: '1.5rem' }}
    >
      <Column
        dataField="acAgendaMedicaDescricao"
        caption="Procedimento"
      />
      <Column
        dataField="valorRecebimento"
        caption="Valor"
        format={{
          type: "currency",
          currency: "BRL",
          minimumSignificantDigits: 2,
          precision: 2,
        }}
        editorOptions={{
          format: {
            type: "currency",
            currency: "BRL",
            precision: 2,
            minimumSignificantDigits: 2,
            text: "R$ #,##0.00",
          },
          showClearButton: true,
        }}
        width="30%"
      />
    </DataGrid>
  )
}

export { AgendamentoMedicoProcedimentosGrid }