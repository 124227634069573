export function logout(history) {
  return (dispatch) => {
    for (let property in localStorage) {
      if (!property.includes("menus")) {
        localStorage.removeItem(property);
      }
    }
    sessionStorage.clear();
    history.replace("/login");
    dispatch({
      type: "LOGOUT",
    });
  };
}
