import { Nav } from "rsuite"
import { AgendamentoMedicoProcedimentosGrid } from "../grids/AgendamentoMedicoProcedimentosGrid"
import { ConfirmarAgendamentoGrid } from "../grids/ConfirmarAgendamentoGrid"

const AgendamentoMedicoNav = ({ form, setForm, dataSource, active, setActive }) => {

  const CustomNav = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="horario">
          Horários
        </Nav.Item>
        <Nav.Item eventKey="procedimentos" disabled={!form.dataRecebimento}>
          Procedimentos
        </Nav.Item>
      </Nav>
    )
  }

  const SwitchNavs = () => {
    switch (active) {
      case "horario":
        return <ConfirmarAgendamentoGrid
          form={form}
          setForm={setForm}
          dataSource={dataSource}
        />;
      case "procedimentos":
        return <AgendamentoMedicoProcedimentosGrid form={form} />;
      default:
        break;
    }
  }

  return (
    <div className="pageContainer">
      <CustomNav
        appearance="subtle"
        active={active}
        onSelect={(activeKey) => setActive(activeKey)}
      />
      {SwitchNavs()}
    </div>
  )
}

export { AgendamentoMedicoNav }