/* eslint-disable no-unused-vars */
import {
  Form,
  SelectDataPicker,
  TextField
} from "@orgystem-ui/components.form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Button, FlexboxGrid, Modal, Schema } from "rsuite";
import { Company } from "../../config/Storage";
import { handleGetSessionStorage, handleSetSessionStorage, useDebounce } from "../../services/helpers";
import { addCompany, toggleModal } from "../../store/modules/company/action";
import {
  getEmpresaStore,
  getGrupo2Store,
  grupo1Store,
  handleLoadEmpresa,
  handleLoadGrupo1,
  handleLoadGrupo2
} from "./ModalCompanyService";

export default function ModalCompany() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { isOpen } = useSelector(state => ({
    isOpen: state.Company.isOpenModal
  }));

  const [form, setForm] = useState({
    nomeGrupo1: "",
    nomeGrupo2: "",
    nomeEmpresa: "",

    idGrupo1: "",
    idGrupo2: "",
    idEmpresa: ""
  });
  const [formCompanyStorage, setCompanyStorage] = useState({
    nomeGrupo1: "",
    nomeGrupo2: "",
    nomeEmpresa: "",

    idGrupo1: "",
    idGrupo2: "",
    idEmpresa: ""
  });

  const { NumberType } = Schema.Types;

  const model = Schema.Model({
    idGrupo1: NumberType(),
    idGrupo2: NumberType(),
    idEmpresa: NumberType()
  });

  useEffect(() => {
    dispatch(
      addCompany({
        ...form,
        codigoEmpresa: form.idEmpresa
      })
    );
  }, [form, dispatch]);

  useEffect(() => {
    if (isOpen) {
      const companyStorage = sessionStorage.getItem(Company)
        ? {
          ...JSON.parse(sessionStorage.getItem(Company)),
          idEmpresa: JSON.parse(sessionStorage.getItem(Company)).idEmpresa
        }
        : {
          nomeGrupo1: "",
          nomeGrupo2: "",
          nomeEmpresa: "",

          idGrupo1: "",
          idGrupo2: "",
          idEmpresa: ""
        };
      setForm(companyStorage);
      setCompanyStorage(companyStorage);
    }
  }, [isOpen]);

  function checkToggle() {
    if (!form.idEmpresa || !formCompanyStorage.idEmpresa) {
      return errorMessage("Selecione a Empresa, por favor");
    }

    dispatch(toggleModal(false));
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: "error",
      autoDismiss: true
    });
  }

  function handleConfirm() {
    if (!form.idEmpresa) return checkToggle();
    dispatch(toggleModal(false));
    const { auth } = handleGetSessionStorage()
    const companyString = JSON.stringify({
      ...form,
      codigoEmpresa: form.idEmpresa,
      fantasiaEmpresa: auth.fantasiaEmpresa
    });

    handleSetSessionStorage("company", companyString);
    dispatch(
      addCompany({
        ...form,
        codigoEmpresa: form.idEmpresa,
        fantasiaEmpresa: auth.fantasiaEmpresa
      })
    );
    window.location.reload();
  }

  const handleFormChange = value => {
    setForm({ ...form, ...value });
  };

  function handleGrupo1(event) {
    if (!event?.idGrupo1) return;
    setForm(form => ({
      ...form,
      idGrupo1: event.idGrupo1,
      nomeGrupo1: event.nomeGrupo1
    }));
  }

  async function handleGrupo1Value(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idGrupo1", "=", value],
      skip: 0,
      take: 1
    };
    const { data } = await handleLoadGrupo1(params);
    if (data?.length > 0) {
      handleGrupo1(data[0]);
    }
    return value;
  }

  function handleGrupo2(event) {
    if (!event?.idGrupo2) return;
    setForm(form => ({
      ...form,
      idGrupo2: event.idGrupo2,
      nomeGrupo2: event.nomeGrupo2
    }));
  }

  async function handleGrupo2Value(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idGrupo2", "=", value],
      skip: 0,
      take: 1
    };
    const { data } = await handleLoadGrupo2(params, form.idGrupo1);
    if (data?.length > 0) {
      handleGrupo2(data[0]);
    }
    return value;
  }

  function handleEmpresa(event) {
    if (!event?.idEmpresa) return;
    setForm(form => ({
      ...form,
      idEmpresa: event.idEmpresa,
      nomeEmpresa: event.nomeEmpresa
    }));
  }

  async function handleEmpresaValue(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idEmpresa", "=", value],
      skip: 0,
      take: 1
    };
    const { data } = await handleLoadEmpresa(params, form.idGrupo2);
    if (data?.length > 0) {
      handleEmpresa(data[0]);
    }
    return value;
  }

  return (
    <Modal open={isOpen} onClose={checkToggle}>
      <Modal.Header>
        <Modal.Title>Selecione o Grupo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="marginBottom">
        <Form formValue={form} model={model} onChange={handleFormChange}>
          {/* <FlexboxGrid>
            <FlexboxGrid.Item colspan={6} style={{ padding: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idGrupo1"
                label="Código Grupo 1"
                onChange={useDebounce(handleGrupo1Value, 500)}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={17}>
              <SelectDataPicker
                dataSource={grupo1Store}
                label="Grupo 1*"
                displayExpr="nomeGrupo1"
                value={form.idGrupo1}
                editValue={form.nomeGrupo1}
                columns={[
                  { dataField: "idGrupo1", caption: "Código" },
                  { dataField: "nomeGrupo1", caption: "Descrição" }
                ]}
                onChange={event => {
                  if (event?.idGrupo1) {
                    setForm({
                      ...form,
                      nomeGrupo1: event?.nomeGrupo1,
                      idGrupo1: event?.idGrupo1,
                      idGrupo2: "",
                      nomeGrupo2: "",
                      idEmpresa: "",
                      nomeEmpresa: ""
                    });
                  }
                }}
                width="100%"
              />
            </FlexboxGrid.Item>
          </FlexboxGrid> */}

          {/* <FlexboxGrid>
            <FlexboxGrid.Item colspan={6} style={{ padding: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idGrupo2"
                label="Código Grupo 2"
                onChange={useDebounce(handleGrupo2Value, 500)}
                disabled={!form.idGrupo1}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={17}>
              <SelectDataPicker
                dataSource={getGrupo2Store(form)}
                label="Grupo 2*"
                displayExpr="nomeGrupo2"
                value={form.idGrupo2}
                editValue={form.nomeGrupo2}
                columns={[
                  { dataField: "idGrupo2", caption: "Código" },
                  { dataField: "nomeGrupo2", caption: "Descrição" }
                ]}
                disabled={!form.idGrupo1}
                onChange={event => {
                  if (event?.idGrupo2) {
                    setForm({
                      ...form,
                      nomeGrupo2: event?.nomeGrupo2,
                      idGrupo2: event?.idGrupo2,
                      idEmpresa: "",
                      nomeEmpresa: ""
                    });
                  }
                }}
                width="100%"
              />
            </FlexboxGrid.Item>
          </FlexboxGrid> */}

          <FlexboxGrid /* style={{ marginTop: "16px" }} */>
            <FlexboxGrid.Item colspan={6} style={{ padding: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idEmpresa"
                label="Código Empresa"
                onChange={useDebounce(handleEmpresaValue, 500)}
                disabled={!form.idGrupo2}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={17}>
              <SelectDataPicker
                dataSource={getEmpresaStore(form)}
                label="Empresa*"
                displayExpr="nomeEmpresa"
                value={form.idEmpresa}
                editValue={form.nomeEmpresa}
                columns={[
                  { dataField: "idEmpresa", caption: "Código" },
                  { dataField: "nomeEmpresa", caption: "Descrição" }
                ]}
                // disabled={!form.idGrupo2}
                onChange={event => {
                  if (event?.idEmpresa) {
                    setForm({
                      ...form,
                      nomeEmpresa: event?.nomeEmpresa,
                      idEmpresa: event?.idEmpresa
                    });
                  }
                }}
                width="100%"
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          appearance="primary"
          color="green"
          disabled={!form.idEmpresa}
          onClick={handleConfirm}
          block
          style={{ marginTop: "16px", padding: "8px" }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
