/* eslint-disable no-unused-vars */
import React from "react";
import "../Page.css";
import { AtendimentoProvider } from "./context/AtendimentoContext";
import { AtendimentoFilterForm } from "./forms/AtendimentoFilterForm";
import { AtendimentoForm } from "./forms/AtendimentoForm";
import { AtendimentoNav } from "./navs/AtendimentoNav";

const Atendimento = () => {
  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch w1050">
          <h4>Atendimento</h4>
          <AtendimentoProvider>
            <AtendimentoFilterForm />
            <AtendimentoNav />
            <AtendimentoForm />
          </AtendimentoProvider>
        </div>
      </div>
    </div>
  );
};

export { Atendimento };

