/* eslint-disable no-unused-vars */
import React from "react";
import "../Page.css";
import { TerminalProvider } from "./context/TerminalAdministrativoContext";
import { TerminalFilterForm } from "./forms/TerminalAdministrativoFilterForm";
import { TerminalForm } from "./forms/TerminalAdministrativoForm";
import { TerminalNav } from "./navs/TerminalAdministrativoNav";
import { TerminalFilter } from "./TerminalAdministrativoFilter";

const TerminalWebAdministrativo = () => {
  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch w1050">
          <TerminalProvider>
            <TerminalFilter />
            <TerminalFilterForm />
            <TerminalNav />
            <TerminalForm />
          </TerminalProvider>
        </div>
      </div>
    </div>
  );
};

export { TerminalWebAdministrativo };
