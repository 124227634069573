import {
  Column,
  DataGrid,
  Editing,
  RequiredRule,
} from "devextreme-react/data-grid";
import { useRef } from "react";
import { Checkbox, MaskedInput } from "rsuite";

const ConfirmarAgendamentoGrid = ({ form, setForm, dataSource }) => {
  const gridRef = useRef();

  function handleSelection(event) {
    event.data.check = !event.data.check;
    event.data.agendado = event.data.check ? "T" : "F";
    event.data.status = 2;
    dataSource.map((item, index) => {
      if (index !== event.rowIndex) {
        item.agendado = "F";
        item.check = false;
        item.status = 2;
      }
      return item;
    });
    setForm((state) => ({
      ...state,
      acAgendaMedicaHorario: dataSource,
    }));
    // setDataSource(dataSource)
    gridRef.current._instance.refresh();
  }

  const CheckRender = (e) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={e.value}
          style={{ margin: -10 }}
          onChange={() => {
            handleSelection(e);
          }}
          disabled={form.trava === "T" || !!form.dataRecebimento}
        />
      </div>
    );
  };

  const onEditorPreparing = (e) => {
    if (e.dataField === "data" && e.parentType === "dataRow") {
      e.editorOptions.disabled =
        e.row.data && e.row.data.idAcAgendaMedicaHorario;
    }
    if (e.dataField === "hora" && e.parentType === "dataRow") {
      e.editorOptions.disabled =
        e.row.data && e.row.data.idAcAgendaMedicaHorario;
    }
  };

  const onInitNewRow = (e) => {
    e.data.agendado = "F";
    e.data.check = false;
    e.data.status = 1;
    e.data.idAcAgendaMedicaHorario = 0;
  };

  const onRowInserting = (e) => {
    setForm((state) => ({
      ...state,
      acAgendaMedicaHorario: [...state.acAgendaMedicaHorario, e.data],
    }));
  };

  const allowUpdatingDeleting = (e) => {
    const canEdit = !e.row.data.idAcAgendaMedicaHorario;
    return canEdit;
  };

  return (
    <DataGrid
      style={{ marginTop: "1rem" }}
      dataSource={dataSource}
      remoteOperations={true}
      allowColumnReordering
      allowColumnResizing
      showBorders
      showRowLines
      // rowAlternationEnabled
      onEditorPreparing={onEditorPreparing}
      onRowPrepared={(e) => {
        if (e.data?.error) {
          e.rowElement.style.backgroundColor = "#f75959";
        }
        if (e.data?.check) {
          e.rowElement.style.backgroundColor = "#f2f9f2";
        }
      }}
      onInitNewRow={onInitNewRow}
      onRowInserting={onRowInserting}
      ref={gridRef}
    >
      <Editing
        mode="batch"
        useIcons
        allowAdding={!form.dataRecebimento && form.trava === "F"}
        allowUpdating={allowUpdatingDeleting}
      />
      <Column
        dataField="data"
        caption="Data Solicitada"
        dataType="date"
        format="dd/MM/yyyy"
      >
        <RequiredRule />
      </Column>
      <Column
        dataField="hora"
        caption="Hora Solicitada"
        editCellComponent={(rowData) => {
          return (
            <MaskedInput
              value={rowData.data.value}
              mask={[/[0-3]/, /\d/, ":", /[0-5]/, /[0-9]/]}
              onChange={rowData.data.setValue}
              style={{
                border: "none",
                color: rowData.data.data.idAcAgendaMedicaHorario && "#BDBDBD",
              }}
              readOnly={!!rowData.data.data.idAcAgendaMedicaHorario}
            />
          );
        }}
      >
        <RequiredRule />
      </Column>

      <Column
        dataField="check"
        caption="Agendado"
        allowEditing={false}
        cellRender={CheckRender}
        width="20%"
        alignment="center"
      />
    </DataGrid>
  );
};

export { ConfirmarAgendamentoGrid };
