import {
  CheckboxInput,
  Form,
  PercentInput,
  SelectDataPicker,
  SelectInput,
  TextField,
} from "@orgystem-ui/components.form";
import React, { useEffect, useRef, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { Button, FlexboxGrid, Schema } from "rsuite";
import "../ImportacaoDeArquivo.css";
import {
  dataSourceClientes,
  handleLoadList,
  postImportarArquivo,
  listaDeMeses,
} from "../ImportacaoDeArquivoService";

export default function ImportacaoDeArquivoForm({
  form,
  setForm,
  initialForm,
}) {
  // eslint-disable-next-line no-unused-vars
  const [dataSourceArquivo, setDataSourceArquivo] = useState([]); //não está sendo usado no momento
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [listCliente, setListCliente] = useState([]); //não está sendo usado no momento
  const [listTipoDeCartao, setListTipoDeCartao] = useState([]);
  const [listTipoDeSegmento, setListTipoDeSegmento] = useState([]);
  const [file, setFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const { addToast } = useToasts();
  const [listaDeAnos, setListaDeAnos] = useState([]);

  const successMessage = (message, fn = () => {}) => {
    addToast(
      message,
      {
        appearance: "success",
        autoDismiss: true,
      },
      fn
    );
  };

  const warningMessage = (message) => {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  };

  const errorMessage = (message) => {
    addToast(message, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  function handleChangeClientes(e) {
    setForm({
      ...form,
      idAcRedeCredenciada: e?.idAcRedeCredenciada,
      cnpj: e?.cnpj,
      razaoSocial: e?.razaoSocial,
      nomeFantasia: e?.nomeFantasia,
    });

    setErrors({
      ...errors,
      idClientes: null,
    });
  }

  function handleClearClientes() {
    setForm({
      ...form,
      idAcRedeCredenciada: null,
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
    });
  }

  function handleFormChange(value) {
    setForm({ ...form, ...value });
  }
  // eslint-disable-next-line no-unused-vars
  let formValid = useRef();

  function handleSubmit() {
    setLoading(true);
    postImportarArquivo(form, file)
      .then(({ data }) => {
        successMessage(data);
        setDisableEdit(true);
      })
      .catch((err) => errorMessage(err))
      .finally(() => setLoading(false));
  }

  function handleClean() {
    setFile(null);
    setNomeArquivo("");
    setLoading(false);
    setDisableEdit(false);
    setForm(initialForm);
  }

  function handleFile(event) {
    var firstFile = event.target.files[0];
    if (firstFile) {
      setNomeArquivo(firstFile?.name);
      setFile(firstFile);
    } else warningMessage("Erro ao ler arquivo");
  }

  const { StringType, NumberType, BooleanType } = Schema.Types;

  const model = Schema.Model({
    idAcRedeCredenciada: NumberType().isRequired("Cliente é requerido"),
    situacaoUsuario: BooleanType().isRequired(
      "Check box Situação do usuário é requerida"
    ),
    gerarCartao: BooleanType().isRequired("Check box Gerar Cartão é requerida"),
    situacaoUsuarioCartao: BooleanType().isRequired(
      "Check box Situação do usuário cartão é requerida"
    ),
    tipoCartao: NumberType().isRequired("Tipo Cartão é requerido"),
    tipoSegmento: NumberType().isRequired("Tipo Seguimento é requerido"),
    maximoParcelas: StringType().isRequired("Maximo de parcelas é requerido"),
    limiteParcelamento: StringType().isRequired(
      "Limite de parcelas é requerido"
    ),
    vencimentoMes: NumberType().isRequired("O mês é requerido"),
    vencimentoAno: NumberType()
      .addRule((value) => {
        return value < 2022 || value > 2030;
      }, "Selecione um ano válido para o cartão")
      .isRequired("Ano é requerido"),
  });

  useEffect(() => {
    handleLoadList(`/TipoCartao?`).then((res) => setListTipoDeCartao(res.data));
    handleLoadList(`/TipoSegmento?`).then((res) =>
      setListTipoDeSegmento(res.data)
    );
    //adicionar a lista de anos
    const anoAtual = new Date().getFullYear();
    let lista = [];
    for (let i = 0; i <= 10; i++) {
      lista.push({
        idAno: anoAtual + i,
      });
    }
    setListaDeAnos(lista);
  }, []);

  return (
    <>
      <Form
        formValue={form}
        onChange={handleFormChange}
        onSubmit={(e) => handleSubmit(e)}
        formError={errors}
        onCheck={setErrors}
        ref={(ref) => (formValid = ref)}
        model={model}
        fluid
        style={{ width: "100%" }}
      >
        <FlexboxGrid align="top" style={{ marginTop: "1rem" }}>
          <FlexboxGrid.Item colspan={10} className="flexGridInput">
            <SelectDataPicker
              dataSource={dataSourceClientes}
              width="100%"
              label="Cliente *"
              displayExpr={"razaoSocial"}
              editValue={form.razaoSocial}
              valueExpr={"idAcRedeCredenciada"}
              error={errors?.idAcRedeCredenciada}
              columns={[
                { dataField: "cnpj", caption: "CPF/CNPJ", width: "20%" },
                {
                  dataField: "nomeFantasia",
                  caption: "Nome Fantasia",
                  width: "40%",
                },
                {
                  dataField: "razaoSocial",
                  caption: "Razão Social",
                  width: "40%",
                },
              ]}
              onChange={handleChangeClientes}
              onClean={handleClearClientes}
              placeholder="Clique para selecionar o Cliente"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} className="flexGridInput flexCheckBox">
            <div style={{ paddingTop: "9px" }}>
              <p className="ellipsis" style={{ marginBottom: -5 }}>
                Situação do usuário
              </p>
              <CheckboxInput
                text1="Ativo"
                text2="Ativo"
                form={form}
                value={form.situacaoUsuario}
                setForm={() => {
                  setForm({
                    ...form,
                    situacaoUsuario: true,
                  });
                }}
                state="situacaoUsuario"
                width={"100%"}
              />
              <CheckboxInput
                text1="Bloqueado"
                text2="Bloqueado"
                form={form}
                value={!form.situacaoUsuario}
                setForm={() => {
                  setForm({
                    ...form,
                    situacaoUsuario: false,
                  });
                }}
                state="situacaoUsuario"
                width={"100%"}
                style={{ margin: "-7px 0 0 0" }}
              />
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            colspan={2}
            className="flexGridInput flexCheckBox"
            style={{ marginTop: "0.5rem" }}
          >
            <p className="ellipsis">Gerar cartão</p>
            <CheckboxInput
              text1="Sim"
              text2="Não"
              form={form}
              value={form.gerarCartao}
              setForm={setForm}
              state="gerarCartao"
              width={"100%"}
              style={{ marginTop: "0.5rem" }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} className="flexGridInput flexCheckBox">
            <div style={{ paddingTop: "9px" }}>
              <p className="ellipsis" style={{ marginBottom: -5 }}>
                Situação usuário cartão
              </p>
              <CheckboxInput
                text1="Ativo"
                text2="Ativo"
                form={form}
                value={form.situacaoUsuarioCartao}
                setForm={() => {
                  setForm({
                    ...form,
                    situacaoUsuarioCartao: true,
                  });
                }}
                state="situacaoUsuarioCartao"
                width={"100%"}
              />
              <CheckboxInput
                text1="Bloqueado"
                text2="Bloqueado"
                form={form}
                value={!form.situacaoUsuarioCartao}
                setForm={() => {
                  setForm({
                    ...form,
                    situacaoUsuarioCartao: false,
                  });
                }}
                state="situacaoUsuarioCartao"
                width={"100%"}
                style={{ margin: "-7px 0 0 0" }}
              />
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            colspan={6}
            className="flexGridInput"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Cartão"
              options={listTipoDeCartao}
              valueKey="idAcTipoCartao"
              labelKey="descricao"
              label="Tipo de Cartão *"
              value={form?.idAcTipoCartao}
              state="idAcTipoCartao"
              form={form}
              setForm={({ idAcTipoCartao }) => {
                setForm({ ...form, tipoCartao: idAcTipoCartao });
                setErrors({ ...errors, tipoCartao: null });
              }}
              width={"100%"}
              error={errors?.tipoCartao}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid align="top" style={{ marginTop: "1rem" }}>
          <FlexboxGrid.Item
            colspan={8}
            className="flexGridInput "
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Segmento"
              options={listTipoDeSegmento}
              label="Tipo de Segmento *"
              valueKey="valor"
              labelKey="descricao"
              state="valor"
              value={form?.valor}
              form={form}
              setForm={({ valor }) => {
                setForm({ ...form, tipoSegmento: valor });
                setErrors({ ...errors, tipoSegmento: null });
              }}
              width={"100%"}
              error={errors?.tipoSegmento}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item colspan={4} className="flexGridInput ">
            <TextField
              label="Maximo Parcelas"
              name="maximoParcelas"
              width={"100%"}
              value={form?.maximoParcelas}
              maxLength={2}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={4} className="flexGridInput ellipsis">
            <PercentInput
              label="Limite de parcelamento"
              name="limiteParcelamento"
              width={"100%"}
              value={form?.limiteParcelamento}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Mês"
              options={listaDeMeses}
              valueKey="id"
              labelKey="descricao"
              label="Mês Vencimento *"
              value={form?.id}
              state="id"
              form={form}
              setForm={({ id }) => {
                setForm({ ...form, vencimentoMes: id });
                setErrors({ ...errors, vencimentoMes: null });
              }}
              width={"100%"}
              error={errors?.vencimentoMes}
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item
            colspan={4}
            className="flexGridInput"
            style={{ paddingRight: "1.5rem" }}
          >
            <SelectInput
              placeholder="Selecione o Ano"
              options={listaDeAnos}
              valueKey="idAno"
              labelKey="idAno"
              label="Ano Vencimento *"
              value={form?.vencimentoAno}
              state="idAno"
              form={form}
              setForm={({ idAno }) => {
                setForm({ ...form, vencimentoAno: idAno });
                setErrors({ ...errors, vencimentoAno: null });
              }}
              width={"100%"}
              error={errors?.vencimentoAno}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid align="top" style={{ marginTop: "1rem" }}>
          <FlexboxGrid.Item colspan={5} className="flexGridInput">
            <div className="rs-form-group" style={{ margin: 8 }}>
              <span className="label">Arquivo em Excel</span>
              <div class="rs-form-control-wrapper">
                <label
                  className={
                    loading || disableEdit
                      ? "label-retorno-disabled"
                      : "label-retorno"
                  }
                  htmlFor="selecao-arquivo"
                >
                  <FaFileAlt size={16} style={{ paddingBottom: 2 }} /> LOCALIZAR
                  ARQUIVO
                </label>

                <input
                  className="input-file"
                  id="selecao-arquivo"
                  type="file"
                  name="arquivo"
                  onChange={(event) => handleFile(event)}
                  disabled={loading || disableEdit}
                />
              </div>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={15} className="flexGridInput">
            <TextField
              label="Nome do arquivo"
              name="arquivo"
              value={nomeArquivo}
              width={"100%"}
              readOnly={true}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            type="button"
            appearance="ghost"
            className="pageButtonSecondary"
            color={"orange"}
            onClick={handleClean}
            disabled={loading}
          >
            LIMPAR
          </Button>
          <Button
            type="button"
            appearance="primary"
            className="pageButtonPrimary"
            color={"blue"}
            onClick={handleSubmit}
            disabled={loading || disableEdit || !file}
          >
            {loading ? "CARREGANDO..." : "IMPORTAR"}
          </Button>
        </div>
      </Form>
    </>
  );
}
