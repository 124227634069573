import { Form, TextField } from "@orgystem-ui/components.form";
import React from "react";
import { Button, FlexboxGrid, Schema } from "rsuite";
import { useTerminalContext } from "../context/TerminalAdministrativoContext";

import { handleLoadById } from "../TerminalAdministrativoService";

const TerminalFilterForm = () => {
  const {
    form,
    setForm,
    errors,
    setErrors,
    handleChange,
    infoMessage,
    setActiveNav,
    setTransaction,
    setNumeroParcelas,
    setLoading,
    setData,
    loading,
    initialForm,
  } = useTerminalContext();

  const { StringType } = Schema.Types;

  const model = Schema.Model({
    numeroCartao: StringType().isRequired("Número cartão é requerido"),
  });

  const handleFilter = async () => {
    setLoading(true);
    setForm({
      ...form,
      consultaMedica: [],
      procedimentoMedico: [],
      senha: "",
      valorTotal: 0,
    });
    setNumeroParcelas(0);
    setActiveNav("consulta");
    setErrors({});
    setTransaction(false);
    setData({});
    handleLoadById(
      "Terminal/DadosTerminalAgenda",
      {
        numeroCartao: form.numeroCartao,
        idAcRedeCredenciadaRepr: form.idAcRedeCredenciada,
      },
      "empresa"
    )
      .then((res) => {
        if (res.idAcAdminCartao) {
          let consultaMedica = res?.consultaMedica.map((item) => {
            item.check = false;
            return item;
          });
          let procedimentoMedico = res?.procedimentoMedico.map((item) => {
            item.check = false;
            return item;
          });

          setForm({
            ...form,
            ...res,
            consultaMedica,
            procedimentoMedico,
            senha: "",
            valorTotal: 0,
          });
        } else {
          setForm({
            ...initialForm,
            idAcRedeCredenciada: form?.idAcRedeCredenciada,
            razaoSocial: form?.razaoSocial,
          });
          infoMessage("Nenhuma consulta encontrada");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <h4>Terminal Web Administrativo</h4>
        {form.idAcRedeCredenciada && (
          <div style={{ fontWeight: "bold", margin: 8 }}>
            -<span style={{ color: "orange" }}> Rede Credenciada: </span>
            {form?.razaoSocial}
          </div>
        )}
      </div>
      {form.idAcRedeCredenciada ? (
        <Form
          formValue={form}
          model={model}
          formError={errors}
          onChange={handleChange}
          onCheck={setErrors}
          onSubmit={(isValid) => {
            if (isValid) {
              handleFilter();
            }
          }}
          fluid
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={8} className="flexGridInput">
              <TextField
                label="Número do cartão"
                name="numeroCartao"
                width={"100%"}
                error={errors.numeroCartao}
                disabled={loading}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={10} className="flexGridInput">
              <TextField
                label="Nome"
                name="nome"
                width={"100%"}
                disabled
                style={{ color: "#000" }}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6} className="flexGridInput">
              <Button
                appearance="ghost"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loading}
                loading={loading}
              >
                🔍 Pesquisar
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form>
      ) : (
        <div style={{ margin: 20, color: "orange", fontWeight: "bold" }}>
          Selecione uma rede credenciada!
        </div>
      )}
    </>
  );
};

export { TerminalFilterForm };

