import React from "react";
import image from "../../assets/images/pageNotFound.svg";

import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="notFoundContainer">
      <h2>Página não encontrada</h2>

      <img src={image} alt="not found" width={300} />
    </div>
  );
};

export default NotFound;
